/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from "react";
import logoDucati from "../../assets/images/logo.svg";
import logoScrambler from "../../assets/images/Scrambler_Ducati_logo.svg";
import { LanguageContext, Search } from "../../context/LanguageContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import style from "./Header.module.scss";
import ArrowLeft from "../../assets/images/ArrowLeft.svg";
import { InitContext } from "../../context/InitContext";
import { NavbarConfiguratorContext } from "../../context/NavbarConfiguratorContext";
import { pushEvent, eventType } from "../../utils/EventListener";
import { retrieveNavItemList } from "../../assets/resources/DucatiResources";
import { removeColorItem, isEmptyObject } from "../../utils/Utils";
import NavbarConfigurator from "../../components/navbar-configurator/NavbarConfigurator";
import { NavbarStickyContext } from "../../context/NavbarStickyContext";
import { HumburgerMenuContext } from "../../context/HumburgerMenuContext";
import { isDesktopView } from "../../utils/Sizing";
import { cn } from "../../utils/cn";
import { useMobile } from "../../hooks/useMobile";
import { RENDERING_SYSTEM, useCarousel } from "../../context/CarouselContext";
import { useMTOContext } from "../../context/MTOContext";
import { useError } from "../../context/ErrorContext";
import { useFamilyMTOComplete } from "../../context/FamilyMTOContext";

import { useScrambler } from "../../hooks/useScrambler";

function useUrl() {
  const { family, supermodel, version } = useParams();
  return {
    familyCode: family,
    superModelCode: supermodel,
    versionCode: version,
  };
}

function Header() {
  const isMobile = useMobile();
  const { navbarContent, setNavbarContent } = useContext(
    NavbarConfiguratorContext
  );
  const languageContext = useContext(LanguageContext);
  const navigate = useNavigate();
  const [backLabel, setBackLabel] = useState("");
  const [backPathname, setBackPathname] = useState("");
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [navItemList, setNavItemList] = useState([]);
  const { initSessionContent } = useContext(InitContext);
  const { navbarStickyContent, setNavbarStickyContent } =
    useContext(NavbarStickyContext);
  const [isLandscape, setIsLandscape] = useState(false);
  const [searchParams] = useSearchParams();
  const [backToHome, setBackToHome] = useState(false);
  const { menuIsShown, setMenuIsShown } = useContext(HumburgerMenuContext);
  let headerB2BB2C;
  let headerB2BB2CMOBILE;
  const [isHiddenHeader, setIsHiddenHeader] = useState(false);
  const { state: carouselState, ...setters } = useCarousel();
  const { state: mtoContent } = useMTOContext();
  const {
    state: { isViewMode },
  } = useMTOContext();
  const { onError } = useError();
  const { searchVariants } = useFamilyMTOComplete();
  const { familyCode, superModelCode } = useUrl();
  const { scramblerStyle } = useScrambler();

  

  useEffect(() => {
    if (isMobile && menuIsShown?.hideHeader) {
      setIsHiddenHeader(true);
    } else {
      setIsHiddenHeader(false);
    }
  }, [isMobile, menuIsShown?.hideHeader]);

  useEffect(() => {
    if (
      isDesktopView() &&
      languageContext?.language?.language &&
      (initSessionContent?.configuration?.preconfiguration?.id !==
        navbarContent?.id ||
        mtoContent.isMTOMHP)
    ) {
      if (mtoContent.isMTOMHP) {
        if (navbarContent?.id !== mtoContent.CID) {
          setNavItemList(mtoContent.headerMTO);
          if (mtoContent.headerMTO.length > 0) {
            const initPosition = { ...mtoContent.headerMTO[0] };
            initPosition.scollToRefIndex = 0;
            setNavbarContent({
              navList: mtoContent.headerMTO,
              currentNavItem: initPosition,
              id: mtoContent.CID,
            });
          }
        }
      } else if (initSessionContent && !isEmptyObject(initSessionContent)) {
        retrieveNavItemList(languageContext.language.language.toLowerCase())
          .then((list) => {
            let navList = [];
            
            
            navList = removeColorItem(list.default.default);
            

            setNavItemList(navList);
            if (navList.length > 0) {
              const initPosition = { ...navList[0] };
              initPosition.scollToRefIndex = 0;
              setNavbarContent({
                navList,
                currentNavItem: initPosition,
                id: initSessionContent.configuration.preconfiguration.id,
              });
            }
          })
          .catch((error) => {
            onError({
              httpStatusCode: error.message,
              consoleError: error,
              caller: "Header - retrieveNavItemList",
            });
          });
      } else {
        setNavItemList([]);
      }
    }
  }, [
    languageContext,
    initSessionContent,
    setNavbarContent,
    navbarContent,
    mtoContent?.isMTOMHP,
    mtoContent?.headerMTO,
    mtoContent?.CID,
  ]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: landscape)");

    const handleOrientationChange = (event) => {
      setIsLandscape(event.matches);
    };

    setIsLandscape(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  useEffect(() => {}, [initSessionContent, mtoContent]);

  let isBikePage;
  if (mtoContent.isMTOMHP) {
    isBikePage = !window.location.pathname.includes("recap");
  } else {
    isBikePage = /^\/bikes\/.*\/\d+(?!\/recap)$/.test(window.location.pathname);
  }

  useEffect(() => {
    const count =
      window.location.pathname.split(process.env.REACT_APP_SEPARATOR).length -
      1;
    setIsShowBtn(true);

    
    resolveBackPathnameB2B(count);
    

    
  }, [window.location.pathname]);

  function resolveBackPathnameB2B(count) {
    switch (count) {
      case 7:
        const familyName = getBackPathname(window.location.pathname);
        const bikeName = getModelId(window.location.pathname);
        const bikeId = getBikeId(window.location.pathname);

        setBackLabel(
          languageContext.language.labels["configurator.view.configuration"]
        );
        setBackPathname({
          path:
            "/bikes/" +
            languageContext.language.country.toLowerCase() +
            "/" +
            languageContext.language.language.toLowerCase() +
            "/" +
            familyName +
            "/" +
            bikeName +
            "/" +
            bikeId,
          state: {
            variant: initSessionContent?.configuration?.preconfiguration,
          },
        });
        break;
      default:
        setIsShowBtn(false);
        break;
    }
  }

  function resolveBackPathnameB2C(count) {
    switch (count) {
      case 4:
        setBackLabel(
          languageContext.language.labels["recap.exchange_bike.family"]
        );
        setBackPathname({
          path:
            "/bikes/" +
            languageContext.language.country.toLowerCase() +
            "/" +
            languageContext.language.language.toLowerCase(),
        });
        break;
      case 5:
        setBackLabel(
          languageContext.language.labels["recap.exchange_bike.model"]
        );
        const model = getBackPathname(window.location.pathname);
        setBackPathname({
          path:
            "/bikes/" +
            languageContext.language.country.toLowerCase() +
            "/" +
            languageContext.language.language.toLowerCase() +
            "/" +
            model,
        });
        break;
      case 6:
        const modelId = getModelId(window.location.pathname);
        const modelName = getBackPathname(window.location.pathname);
        if (checkPreconfiguration(modelId)) {
          setBackLabel(
            languageContext.language.labels["recap.exchange_bike.model"]
          );

          const newBackPathname =
            "/bikes/" +
            languageContext.language.country.toLowerCase() +
            "/" +
            languageContext.language.language.toLowerCase() +
            "/" +
            modelName;

          setBackPathname({ path: newBackPathname });
        } else {
          setBackLabel(
            languageContext.language.labels["recap.equipment.title"]
          );

          const newBackPathname = {
            path:
              "/bikes/" +
              languageContext.language.country.toLowerCase() +
              "/" +
              languageContext.language.language.toLowerCase() +
              "/" +
              modelName +
              "/" +
              modelId,
            state: {
              variant: initSessionContent?.configuration?.preconfiguration,
            },
          };

          setBackPathname(newBackPathname);
        }
        break;
      case 7:
        const familyName = getBackPathname(window.location.pathname);
        const bikeName = getModelId(window.location.pathname);
        const bikeId = getBikeId(window.location.pathname);

        setBackLabel(
          languageContext.language.labels["configurator.view.configuration"]
        );
        setBackPathname({
          path:
            "/bikes/" +
            languageContext.language.country.toLowerCase() +
            "/" +
            languageContext.language.language.toLowerCase() +
            "/" +
            familyName +
            "/" +
            bikeName +
            "/" +
            bikeId,
          state: {
            variant: initSessionContent?.configuration?.preconfiguration,
          },
        });
        break;

      case 8:
        const familyName2 = getBackPathname(window.location.pathname);
        const bikeName2 = getModelId(window.location.pathname);
        const bikeId2 = getBikeId(window.location.pathname);
        const cid = initSessionContent?.session?.cid || mtoContent?.CID;
        setBackLabel("riepilogo");
        setBackPathname({
          path:
            "/bikes/" +
            languageContext.language.country.toLowerCase() +
            "/" +
            languageContext.language.language.toLowerCase() +
            "/" +
            familyName2 +
            "/" +
            bikeName2 +
            "/" +
            bikeId2 +
            "/recap".concat("?cid=" + cid),
          state: {
            variant: initSessionContent?.configuration?.preconfiguration,
          },
        });
        break;

      default:
        setIsShowBtn(false);
        pushEvent(eventType.clearfeRedirectKeyHomePageEvent, {
          clear: true,
        });
        break;
    }
  }

  function getBackPathname(path) {
    const pathSplitted = path.split(process.env.REACT_APP_SEPARATOR);
    return pathSplitted[4];
  }

  function getModelId(path) {
    const pathSplitted = path.split(process.env.REACT_APP_SEPARATOR);
    return pathSplitted[5];
  }

  function getBikeId(path) {
    let pathSplitted;
    if (path) pathSplitted = path.split(process.env.REACT_APP_SEPARATOR);
    return pathSplitted[6];
  }

  function checkPreconfiguration(model) {
    const version = searchVariants(familyCode, superModelCode);
    if (version?.variants?.size > 0) {
      return false;
    }
    return true;
  }

  function handlerSubmit(pathname) {
    if (isMobile) {
      setNavbarStickyContent({ isSticky: false });
      window.scrollTo(0, 0);
    }
    let newPath = "";

    const count =
      pathname.path.split(process.env.REACT_APP_SEPARATOR).length - 1;
    if (count === 4 || count === 5 || count === 6) {
      if (navbarContent?.navList && navbarContent.navList.length > 0) {
        const init = { ...navbarContent.navList[0] };
        init.scollToRefIndex = 0;
        const newNavbarContent = { ...navbarContent };
        newNavbarContent.currentNavItem = init;
        setNavbarContent(newNavbarContent);
      }
    }
    const cid = initSessionContent?.session?.cid || mtoContent?.CID;
    if (pathname.state) {
      newPath = pathname.path;
      if (count === 6) {
        newPath = newPath.concat("?cid=" + cid);
      } else if (count === 7 && cid) {
        newPath = newPath.replace("undefined", cid);
      }
      navigate(newPath, {
        state: {
          baseConfiguration: pathname.state.baseConfiguration,
          variant: pathname.state.variant,
          headerDescription: pathname.state.headerDescription,
        },
      });
    } else {
      newPath = pathname.path;
      const count =
        pathname.path.split(process.env.REACT_APP_SEPARATOR).length - 1;
      if (count === 7) {
        newPath = newPath.concat("?cid=" + cid);
      }
      
      navigate(newPath);
    }
  }

  

  
  headerB2BB2C = (
    <div className="mt-1">
      <img
        style={{ height: "55px", width: scramblerStyle ? "120px" : "50px" }}
        alt="Ducati"
        src={scramblerStyle ? logoScrambler : logoDucati}
      />
    </div>
  );

  headerB2BB2CMOBILE = "";
  

  function headerNoMobile() {
    return (
      <>
        <div className={`d-inline-block ${style.font}`}>{headerB2BB2C}</div>
        <div
          className={`d-inline-block align-items-center ${style.margin_return} `}
        >
          {isShowBtn && !initSessionContent?.viewMode && !isViewMode ? (
            <div className="d-flex">
              <button
                type="button"
                className="btn text-dark text-decoration-none btn-small"
                onClick={() => {
                  handlerSubmit(backPathname);
                  const footer = document.getElementById("footer");
                  footer.style.display != "block"
                    ? (footer.style.display = "block")
                    : "";
                }}
              >
                <img
                  src={ArrowLeft}
                  alt="ArrowLeft"
                  className={`me-3 ${style["margin-back"]}`}
                />
                <h5 tabIndex={0} className="text-capitalize d-inline">
                  <Search did="header_menu" tid="back" />
                </h5>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  function homeRedirectHandler() {
    let homePathname;
    if (searchParams?.get("cid")) {
      const modal = document.getElementById("changeConfig");
      modal.style.display = "block";
      modal.classList.add("show");
      if (isMobile) {
        setMenuIsShown({
          hideNav: true,
          hideHeader: false,
          hideRecapBar: true,
        });
        if (carouselState?.rendering === RENDERING_SYSTEM.mhp)
          setters.setShowArrow(false);
      }
      setBackToHome(true);
    } else {
      homePathname =
        "/bikes/" +
        languageContext.language.country.toLowerCase() +
        "/" +
        languageContext.language.language.toLowerCase();
      navigate(homePathname);
    }
  }

  function headerMobile() {
    return (
      <div className={"row d-flex ".concat("justify-content-center")}>
        <div
          className={cn(
            {
              "col-4": scramblerStyle,
              "col-5": !scramblerStyle,
              "order-2": initSessionContent?.viewMode || isViewMode,
            },
            "d-flex align-items-center"
          )}
        >
          {isShowBtn && !initSessionContent?.viewMode && !isViewMode && (
            <div className="d-flex">
              <button
                type="button"
                className="btn text-dark text-decoration-none btn-small"
                onClick={() => {
                  handlerSubmit(backPathname);
                  const footer = document.getElementById("footer");
                  footer.style.display != "block"
                    ? (footer.style.display = "block")
                    : "";
                }}
              >
                <img src={ArrowLeft} alt="ArrowLeft" className="me-3 mb-1" />
                <h5 tabIndex={0} className="text-capitalize d-inline">
                  <Search did="header_menu" tid="back" />
                </h5>
              </button>
            </div>
          )}
        </div>

        <div className={scramblerStyle ? "col-6" : "col-5"}>
          {!initSessionContent?.viewMode && !isViewMode && (
            <picture>
              <img
                style={{
                  height: "55px",
                  width: scramblerStyle ? "120px" : "50px",
                }}
                alt="Ducati"
                src={scramblerStyle ? logoScrambler : logoDucati}
                onClick={homeRedirectHandler}
                className={style["header-logo"]}
              />
            </picture>
          )}
        </div>

        <div
          className={`col-2 justify-content-end d-flex ${
            initSessionContent?.viewMode || isViewMode ? "order-1" : ""
          }`}
        >
          {headerB2BB2CMOBILE}
        </div>
      </div>
    );
  }

  function headerStyle() {
    return ` ${
      navbarStickyContent?.isSticky
        ? `${style.headerPosition} border-0`
        : `border-bottom border-1 border-gray-700`
    } container-fluid  pb-2 pt-2 ${
      scramblerStyle ? style["back-btn--scrambler"] : style["header-container"]
    } ${isLandscape && isMobile ? "d-none" : ""} `;
  }

  return (
    <div
      data-component="wbc-header"
      className={` ${isHiddenHeader ? "d-none" : headerStyle()} `}
    >
      <div className="d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
        <div className={isMobile ? "row" : ""}>
          <div
            className={
              !isMobile
                ? "d-inline-block"
                : "col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12 mt-1"
            }
          >
            {isMobile ? headerMobile() : headerNoMobile()}
          </div>

          <div className={cn("d-none d-md-inline-block", style.float)}>
            <div
              className={`w-100 top-0 ${
                initSessionContent?.disableNav ? "d-none" : ""
              }`}
            >
              {navItemList && isBikePage && (
                <NavbarConfigurator itemList={navItemList} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
