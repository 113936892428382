// StickyContext.js
import React, { createContext, useState } from "react";

export const StickyContext = createContext();

export function StickyProvider({ children }) {
  const [stickyContent, setStickyContent] = useState({});

  return (
    <StickyContext.Provider value={{ stickyContent, setStickyContent }}>
      {children}
    </StickyContext.Provider>
  );
}
