import React, { useEffect, useState } from "react";
import { findApparelByChildId } from "../../../../../../utils/Apparels";
import { useDucatiServiceMTO } from "../../../../../../services/useDucatiServiceMTO";
import {
  Search,
  Text,
  useLanguageContent,
} from "../../../../../../context/LanguageContext";
import { useApparelsContent } from "../../../../../../context/ApparelsContext";
import ProgressBarSlider from "../../../../../../components/carousel/carousel-configurator/ProgressBarSlider";
import style from "./RemoveApparel.module.scss";
import {
  messageType,
  useServiceModal,
} from "../../../../../../components/modal/service-modal/ServiceModal";
import { useApparelsMTO } from "../../../../../../hooks/useApparelsMTO";
import { useMTOContext } from "../../../../../../context/MTOContext";
import { useError } from "../../../../../../context/ErrorContext";
import { cn } from "../../../../../../utils/cn";

export function useRemoveItem() {
  const { language } = useLanguageContent();
  const { showServiceModal } = useServiceModal();
  const { apparelsContent } = useApparelsContent();
  const { getChildFromCart } = useApparelsMTO();
  const { state, removeItem, updateTotalPrice, updateQuotes } = useMTOContext();
  const { echoError } = useError();
  const { fetchRemoveApparelMTO } = useDucatiServiceMTO();

  function singleRemove({ itemId = "", onSuccess, updateProgressBar }) {
    if (language?.country && language?.language) {
      fetchRemoveApparelMTO(
        language.country.toLowerCase(),
        language.language.toLowerCase(),
        state.CID,
        itemId
      )
        .then((backendResponse) => {
          if (backendResponse) {
            const apparel = getChildFromCart(itemId);
            if (apparel) {
              const itemKey = `${apparel.attributeKey}-${apparel.domainValueKey}`;
              removeItem(apparel.attributeName, itemKey);
              updateTotalPrice({
                bikePriceFormatted:
                  backendResponse.bikeSection.bikePriceFormatted,
                optionPriceFormatted:
                  backendResponse.bikeSection.optionPriceFormatted,
                accessoryPriceFormatted:
                  backendResponse.accessorySection.accessoryPriceFormatted,
                apparelPriceFormatted:
                  backendResponse.apparelSection.apparelPriceFormatted,
                subTotalPriceFormatted:
                  backendResponse.overallSection.subTotalPriceFormatted,
              });
              updateQuotes(backendResponse);
            }
            if (onSuccess && apparelsContent?.allApparels) {
              const accessory = findApparelByChildId(
                apparelsContent.allApparels,
                itemId
              );
              onSuccess(accessory);
            }
          }
          if (updateProgressBar) {
            updateProgressBar();
          }
        })
        .catch((error) => {
          if (updateProgressBar) {
            updateProgressBar();
          }
          echoError({
            error,
            caller: "RemoveApparel - singleRemove - fetchRemoveApparelMTO",
          });
          showServiceModal(messageType.changingFail);
        });
    }
  }

  function multipleRemove({
    firstAccessoryId,
    accessoryList,
    onSuccess,
    updateProgressBar,
  }) {
    if (accessoryList.length > 0) {
      fetchRemoveApparelMTO(
        language.country.toLowerCase(),
        language.language.toLowerCase(),
        state.CID,
        accessoryList[0].domainValueKey
      )
        .then((backendResponse) => {
          if (backendResponse) {
            const apparel = getChildFromCart(accessoryList[0].domainValueKey);
            if (apparel) {
              const itemKey = `${apparel.attributeKey}-${apparel.domainValueKey}`;
              removeItem(apparel.attributeName, itemKey);
              updateTotalPrice({
                bikePriceFormatted:
                  backendResponse.bikeSection.bikePriceFormatted,
                optionPriceFormatted:
                  backendResponse.bikeSection.optionPriceFormatted,
                accessoryPriceFormatted:
                  backendResponse.accessorySection.accessoryPriceFormatted,
                apparelPriceFormatted:
                  backendResponse.apparelSection.apparelPriceFormatted,
                subTotalPriceFormatted:
                  backendResponse.overallSection.subTotalPriceFormatted,
              });
              updateQuotes(backendResponse);
            }
            accessoryList.splice(0, 1);
            multipleRemove({
              firstAccessoryId,
              accessoryList,
              onSuccess,
              updateProgressBar,
            });
          }
        })
        .catch((error) => {
          if (updateProgressBar) {
            updateProgressBar();
          }
          echoError({
            error,
            caller: "RemoveApparel - multipleRemove - fetchRimuoviAftermarket",
          });
          showServiceModal(messageType.changingFail);
        });
    } else {
      if (onSuccess && apparelsContent?.allApparels) {
        const accessory = findApparelByChildId(
          apparelsContent.allApparels,
          firstAccessoryId
        );
        onSuccess(accessory);
      }
      if (updateProgressBar) {
        updateProgressBar();
      }
    }
  }

  return {
    singleRemove,
    multipleRemove,
  };
}

export function RemoveApparel({
  id = "",
  did = "",
  tid = "",
  style1 = "",
  style2 = "",
  firstAccessoryId,
  accessoryList,
  onSuccess,
  isPopup = false,
  isGray = false,
}) {
  const { singleRemove, multipleRemove } = useRemoveItem();
  const [dictionaryId, setDictionaryId] = useState(did);
  const [translationId, setTranslationId] = useState(tid);
  const [loading, setLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(false);

  useEffect(() => {
    setDictionaryId(did);
    setTranslationId(tid);
  }, [did, tid]);

  function updateProgressBar() {
    setLoading(false);
    setProgressBar(false);
  }

  return !progressBar ? (
    <div className={cn("px-0", { "pe-none": loading })}>
      <button
        id={id}
        type="button"
        className={` ${style1} ${
          isGray ? style["btn-gray"] : style["btn-red"]
        } ${style[style2]}`}
        onClick={() => {
          if (firstAccessoryId && accessoryList?.length > 0) {
            if (isPopup) {
              setLoading(true);
              setProgressBar(true);
              multipleRemove({
                firstAccessoryId,
                accessoryList,
                onSuccess,
                updateProgressBar,
              });
            } else {
              setLoading(true);
              singleRemove({
                itemId: accessoryList[0].id,
                onSuccess,
                updateProgressBar,
              });
            }
          }
        }}
      >
        {dictionaryId !== "" ? (
          <Search did={dictionaryId} tid={translationId} />
        ) : (
          <Text tid={translationId} />
        )}
      </button>
    </div>
  ) : (
    <div
      className={`d-flex justify-content-center align-items-center h-100 ${style["progressbar-width"]}`}
    >
      <ProgressBarSlider />
    </div>
  );
}
