import React, { useEffect, useRef } from "react";
import style from "../Modal.module.scss";
import { Search } from "../../../context/LanguageContext";
import { Modal } from "bootstrap";
import { useModalContent } from "../../../context/ModalContext";

const ServiceModalId = "service-modal-id";

export const messageType = {
  changingFail: {
    title: "modal.config_changes.messages.fail.title",
    description: "modal.config_changes.messages.fail.subtitle",
  },
  configIncomplete: {
    title: "modal.config_incomplete.messages.fail.title",
    description: "modal.config_incomplete.messages.fail.subtitle",
  },
  exceededMaxRate: {
    title: "modal.config_changes.messages.fail.title",
    description: "modal.loan.max_rate",
  },
};

export function useServiceModal() {
  const { setServiceModal } = useModalContent();

  const showServiceModal = (message) => {
    setServiceModal({
      showSericeModal: true,
      title: message.title,
      description: message.description,
    });
  };

  const hideServiceModal = () => {
    setServiceModal({ showSericeModal: false });
  };

  return { showServiceModal, hideServiceModal };
}

export function ServiceModal() {
  const modalRef = useRef(null);
  const { serviceModal } = useModalContent();

  useEffect(() => {
    if (!modalRef.current) return;
    const modalChild = new Modal(modalRef?.current);
    if (modalChild) {
      if (serviceModal?.showSericeModal) {
        modalChild.show();
      } else {
        modalChild.hide();
      }
    }
  }, [serviceModal]);

  return (
    <div
      ref={modalRef}
      className={`modal fade ${style.background} ${style.mandatory}`}
      id={ServiceModalId}
      tabIndex="-1"
      aria-labelledby={ServiceModalId}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered justify-content-center modal-fullscreen-lg-down">
        <div className={`modal-content ${style.content}`}>
          <div className={`modal-body p-0 ps-lg-0 ${style["max-height"]} `}>
            <ServiceModalBody
              tidTitle={serviceModal.title}
              tidDesc={serviceModal.description}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ServiceModalBody({ tidTitle = "", tidDesc = "" }) {
  const { hideServiceModal } = useServiceModal();

  return (
    <div
      className={` d-flex justify-content-center align-items-center flex-column h-100 w-100`}
    >
      <div
        className={` d-flex justify-content-center align-items-center flex-column h-50`}
      >
        <h3 className={`pt-5 ${style.modalObject_title}`}>
          <Search did="modal_labels" tid={tidTitle} />
        </h3>
        <p className={`${style.modalObject_subtitle} px-4`}>
          <Search did="modal_labels" tid={tidDesc} />
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center h-50">
        <button
          type="button"
          className={`p-3 text-uppercase m-5 ${style["btn-red"]} ${style["button-style"]}`}
          data-bs-dismiss="modal"
          onClick={() => {
            hideServiceModal();
          }}
        >
          <Search did="configurator_information" tid="recapbar.messages.next" />
        </button>
      </div>
    </div>
  );
}
