import React, { useRef, useState } from "react";
import style from "./DiscountSection.module.scss";
import styleForm from "../forms/Form.module.scss";
import CloseButtonModal from "../../../common/assets/images/IconModal/closeX.svg";
import { dsStatusType, useDiscountForm } from "./DiscountForm";
import ProgressBarSlider from "../../../common/components/carousel/carousel-configurator/ProgressBarSlider";
import { cn } from "../../../common/utils/cn";
import { Text } from "../../../common/context/LanguageContext";
import { isDesktopView } from "../../../common/utils/Sizing";

function WriteMobile({
  language,
  title = "",
  type = "",
  updateState,
  setProgressContent,
  readDiscountValue,
  hadleSubmit,
  formRef,
  currencySymbol,
  isLoading,
}) {
  return (
    <div
      data-component="write-mobile"
      className={`container-fluid vw-100 vh-100 ${style["write-mobile-container"]}`}
    >
      <button
        className={cn("btn w-100 text-end mx-0 border-0 px-0")}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setProgressContent({ isLoading: false });
          const value = readDiscountValue(type);
          if (value !== "") {
            updateState(dsStatusType.read);
          } else {
            updateState(dsStatusType.insert);
          }
        }}
      >
        <img
          src={CloseButtonModal}
          alt="CloseButtonModal"
          className={style.imghover}
        />
      </button>
      <p className={`w-100 p-0 ${style.discounttitle}`}>{title}</p>
      <p className={`w-100 p-0 ${style.discountlabel}`}>
        {language?.b2b_recap_information["voices_content.discount.label"]}
      </p>
      <form
        data-component="write-form"
        className="d-flex flex-column justify-content-center w-100 mt-6 mx-0 px-5"
        onSubmit={(e) => {
          e.preventDefault();
          hadleSubmit();
        }}
        ref={formRef}
      >
        <div className="d-flex mx-0 mb-4 px-0">
          <input
            type="number"
            min="1"
            step="0.01"
            data-component="discount-input"
            name="discount-input"
            className={`w-100 me-3 p-3 ${style["ds-form-input"]}`}
            placeholder={readDiscountValue(type)}
          />
          <select
            data-component="discount-select"
            name="discount-select"
            className={cn(
              "form-select form-select-sm p-3",
              styleForm["custom-selector"],
              style["ds-form-selector"]
            )}
          >
            <option value="PERCENTAGE">%</option>
            <option value="NUMERIC">{currencySymbol}</option>
          </select>
        </div>
        {isLoading ? (
          <div className="d-flex w-100 justify-content-center align-items-center">
            <ProgressBarSlider />
          </div>
        ) : (
          <button
            className={cn("btn w-100 px-4 py-3", style["btn-gray"])}
            type="submit"
            value="submit"
          >
            <Text tid="configurator.change_model.btn_confirm" />
          </button>
        )}
      </form>
    </div>
  );
}

function Write({ title = "", type = "", updateState }) {
  const {
    language,
    currencySymbol,
    confirmDiscount,
    readDiscountValue,
    setProgressContent,
  } = useDiscountForm();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  function hadleSubmit() {
    const form = formRef?.current;
    const select = form["discount-select"];
    const dsValue = form["discount-input"]?.value || undefined;
    const dsType = select
      ? select.options[select.selectedIndex]?.value
      : undefined;
    if (dsValue && dsType) {
      setIsLoading(true);
      confirmDiscount({ type, dsValue, dsType }, updateState, setIsLoading);
    }
  }

  function renderWrite() {
    if (isDesktopView()) {
      return (
        <div
          data-component="write"
          className="row w-100 mx-0 px-3 py-3 flex-column"
        >
          <div className="d-flex w-100 mx-0 px-0 mb-3 justify-content-between align-items-center">
            <p className={`w-auto m-0 p-0 ${style.discountlabel}`}>
              {language?.b2b_recap_information["voices_content.discount.label"]}
            </p>
            <button
              className={cn("btn mx-0 border-0 px-0")}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setProgressContent({ isLoading: false });
                const value = readDiscountValue(type);
                if (value !== "") {
                  updateState(dsStatusType.read);
                } else {
                  updateState(dsStatusType.insert);
                }
              }}
            >
              <img
                src={CloseButtonModal}
                alt="CloseButtonModal"
                className={style.imghover}
              />
            </button>
          </div>
          <form
            data-component="write-form"
            className="d-flex w-100 mx-0 px-0"
            onSubmit={(e) => {
              e.preventDefault();
              hadleSubmit();
            }}
            ref={formRef}
          >
            <input
              type="number"
              step="0.01"
              min="1"
              data-component="discount-input"
              name="discount-input"
              className={`me-3 p-3 ${style["ds-form-input"]}`}
              placeholder={readDiscountValue(type)}
            />
            <select
              data-component="discount-select"
              name="discount-select"
              className={cn(
                "form-select form-select-sm me-3 p-3",
                styleForm["custom-selector"],
                style["ds-form-selector"]
              )}
            >
              <option value="PERCENTAGE">%</option>
              <option value="NUMERIC">{currencySymbol}</option>
            </select>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <ProgressBarSlider />
              </div>
            ) : (
              <button
                className={cn("btn px-4 py-3", style["btn-gray"])}
                type="submit"
                value="submit"
              >
                <Text tid="configurator.change_model.btn_confirm" />
              </button>
            )}
          </form>
        </div>
      );
    }
    return (
      <WriteMobile
        language={language}
        title={title}
        type={type}
        updateState={updateState}
        setProgressContent={setProgressContent}
        readDiscountValue={readDiscountValue}
        hadleSubmit={hadleSubmit}
        formRef={formRef}
        currencySymbol={currencySymbol}
        isLoading={isLoading}
      />
    );
  }

  return renderWrite();
}

export default Write;
