import React, { useState, createContext, useEffect, useContext } from "react";
import {
  updateFinancialContent,
  retrieveFinancialContent,
} from "../utils/IndexedDB";

export const FinancialContext = createContext({
  financialContent: {},
});

export function FinancialProvider({ children }) {
  const [financialContent, setFinancialContent] = useState();

  // useEffect(() => {
  //   retrieveFinancialContent(setFinancialContent);
  // }, []);

  const provider = {
    financialContent,
    setFinancialContent: (selected) => {
      const newFinancialContent = selected;
      updateFinancialContent(newFinancialContent);
      setFinancialContent(newFinancialContent);
    },
  };

  return (
    <FinancialContext.Provider value={provider}>
      {children}
    </FinancialContext.Provider>
  );
}

export function useFinancial() {
  return useContext(FinancialContext);
}
