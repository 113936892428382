import { useState, useEffect } from "react";
import { checkMobile } from "../utils/Utils";

export function useMobile(init = false) {
  const [isMobile, setIsMobile] = useState(init);

  useEffect(() => {
    setIsMobile(checkMobile());
  }, [window.innerWidth]);

  return isMobile;
}
