import React, { useState, createContext, useEffect, useContext } from "react";

export const WebViewModeContext = createContext({
  webViewMode: {},
});

const localStateWebViewMode = JSON.parse(localStorage.getItem("webviewmode"));

export function WebViewModeProvider({ children, props }) {
  const [webViewMode, setWebViewMode] = useState(
    props || localStateWebViewMode
  );

  useEffect(() => {
    localStorage.setItem("webviewmode", JSON.stringify(webViewMode));
  }, [webViewMode]);

  const provider = {
    webViewMode,
    setWebViewMode: (selected) => {
      setWebViewMode(selected);
    },
  };

  return (
    <WebViewModeContext.Provider value={provider}>
      {children}
    </WebViewModeContext.Provider>
  );
}

export function useWebViewMode() {
  return useContext(WebViewModeContext);
}
