import React, { useState, createContext, useCallback, useContext } from "react";
import { updateApparelsContent } from "../utils/IndexedDB";
import { isDesktopView } from "../utils/Sizing";
import { HumburgerMenuContext } from "./HumburgerMenuContext";
import { LanguageContext } from "./LanguageContext";

export const DETAILS_DEFAULT_STATE = {
  id: "",
  gender: undefined,
  isShowDetails: false,
  apparel: {},
  isConfigurator: false,
  showLeftbar: false,
  apparelList: [],
  slideList: [],
};

export const ApparelsContext = createContext({
  apparelsContent: {},
});

export function ApparelsProvider({ children }) {
  const { language } = useContext(LanguageContext);
  const { setMenuIsShown } = useContext(HumburgerMenuContext);
  const [apparelsContent, setApparelsContent] = useState();
  const [showGallery, setShowGallery] = useState(false);
  const [detailsState, setDetailsState] = useState(DETAILS_DEFAULT_STATE);

  const setStuff = (key) => {
    return (value) => {
      if (detailsState[key] === value) return;
      setDetailsState((prev) => ({ ...prev, [key]: value }));
    };
  };

  const updateApparels = useCallback((selected) => {
    updateApparelsContent(selected);
    setApparelsContent(selected);
  }, []);

  function closeGallery() {
    setShowGallery(false);
    setStuff("gender")(
      language.labels["configuration.apparel_gender.all"].toLowerCase()
    );
    document.body.style.overflow = "auto";
    if (!isDesktopView()) {
      setMenuIsShown({
        hideNav: false,
        hideHeader: false,
        hideRecapBar: false,
      });
    }
  }

  function closeApparelDetails() {
    setStuff("isShowDetails")(false);
    if (!showGallery) document.body.style.overflow = "auto";
  }

  const provider = {
    apparelsContent,
    setApparelsContent: updateApparels,
    showGallery,
    setShowGallery,
    closeGallery,
    detailsState,
    setGender: setStuff("gender"),
    setIsShowDetails: setStuff("isShowDetails"),
    setApparelSelected: setStuff("apparel"),
    closeApparelDetails,
    setIsConfigurator: setStuff("isConfigurator"),
    setApparelList: setStuff("apparelList"),
    setSlideList: setStuff("slideList"),
  };

  return (
    <ApparelsContext.Provider value={provider}>
      {children}
    </ApparelsContext.Provider>
  );
}

export function useApparelsContent() {
  return useContext(ApparelsContext);
}
