import React, { createContext, useState } from "react";

export const HumburgerMenuContext = createContext();

export function HumburgerMenuProvider({ children }) {
  const [menuIsShown, setMenuIsShown] = useState(false);

  return (
    <HumburgerMenuContext.Provider value={{ menuIsShown, setMenuIsShown }}>
      {children}
    </HumburgerMenuContext.Provider>
  );
}
