import React, { useState, createContext, useContext } from "react";
import { createInitSessionContent } from "../utils/IndexedDB";

export const InitContext = createContext({
  initSessionContent: {},
});

export function InitProvider({ children }) {
  const [initSessionContent, setInitSessionContent] = useState();

  const provider = {
    initSessionContent,
    setInitSessionContent: (selected) => {
      const newInitSessionContent = selected;
      createInitSessionContent(newInitSessionContent);
      setInitSessionContent(newInitSessionContent);
    },
  };

  return (
    <InitContext.Provider value={provider}>{children}</InitContext.Provider>
  );
}

export function useInit() {
  return useContext(InitContext);
}
