import React, { useState, createContext, useContext } from "react";
import { useLanguageContent } from "./LanguageContext";

export const NavbarConfiguratorContext = createContext({
  navbarContent: {},
});

export function NavbarConfiguratorProvider({ children }) {
  const [navbarContent, setNavbarContent] = useState();
  const { language } = useLanguageContent();

  const getTabI8N = (groupName) => {
    if (groupName.includes("COLOR")) {
      return language.steps_information["steps_information.name.maincolor"];
    }
    if (groupName.includes("FRAME")) {
      return language.steps_information["steps_information.name.frame"];
    }
    if (groupName.includes("RIMS")) {
      return language.steps_information["steps_information.name.rims"];
    }
    if (groupName.includes("SUSPENSION")) {
      return language.steps_information["steps_information.name.suspension"];
    }
    if (groupName.includes("FRONT BRAKE")) {
      return language.steps_information["steps_information.name.frontbrake"];
    }
    if (groupName.includes("PILOT SEAT")) {
      return language.steps_information["steps_information.name.seats"];
    }
    if (groupName.includes("BUNDLE") || groupName.includes("OPTIONAL")) {
      return language.steps_information["steps_information.name.bundle"];
    }
    if (groupName.includes("OTHER")) {
      return language.steps_information["steps_information.name.other"];
    }
    if (groupName.includes("SEQUENCE")) {
      return language.steps_information["steps_information.name.sequence"];
    }
    if (groupName.includes("TECHNICAL FEATURES")) {
      return language.steps_information["steps_information.name.techfeatures"];
    }
    return "";
  };

  const provider = {
    navbarContent,
    setNavbarContent: (selected) => {
      const newNavbarContent = selected;
      setNavbarContent(newNavbarContent);
    },
    getTabI8N,
  };

  return (
    <NavbarConfiguratorContext.Provider value={provider}>
      {children}
    </NavbarConfiguratorContext.Provider>
  );
}

export function useNavbarConfigurator() {
  return useContext(NavbarConfiguratorContext);
}
