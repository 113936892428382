import { useApparelsContent } from "../context/ApparelsContext";
import { useLanguageContent } from "../context/LanguageContext";
import { useMTOContext } from "../context/MTOContext";
import { APPARELS_GROUP, findApparelByChildId } from "../utils/Apparels";

export function useApparelsMTO() {
  const { state: mtoContent, synchronizeCart } = useMTOContext();
  const { language } = useLanguageContent();
  const { apparelsContent } = useApparelsContent();

  function hasFatherId(apparelMap, fatherId, qtySelected = 1) {
    if (apparelMap) {
      const keys = apparelMap.keys();
      for (const key of keys) {
        if (key && key.includes(fatherId)) {
          const value = apparelMap.get(key);
          return qtySelected > 1 ? qtySelected === value.quontity : true;
        }
      }
    }
    return false;
  }

  function isThereInCart(fatherId, qtySelected = 1) {
    if (mtoContent?.cart?.groupedItemMap?.size > 0) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const value = cartMap.get(APPARELS_GROUP);
      if (value) {
        return hasFatherId(value, fatherId, qtySelected);
      }
    }
    return false;
  }

  function buildList(apparelMap, fatherId) {
    const list = [];
    if (apparelMap) {
      const keys = apparelMap.keys();
      for (const key of keys) {
        if (key && key.includes(fatherId)) {
          list.push(apparelMap.get(key));
        }
      }
    }
    return list;
  }

  function buildChildrenList(fatherId) {
    if (mtoContent?.cart?.groupedItemMap?.size > 0) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const value = cartMap.get(APPARELS_GROUP);
      if (value) {
        return buildList(value, fatherId);
      }
    }
    return [];
  }

  function find(apparelMap, apparelid) {
    if (apparelMap) {
      const keys = apparelMap.keys();
      for (const key of keys) {
        if (key && key.includes(apparelid)) {
          return apparelMap.get(key);
        }
      }
    }
    return undefined;
  }

  function getChildFromCart(apparelid) {
    if (mtoContent?.cart?.groupedItemMap?.size > 0) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const value = cartMap.get(APPARELS_GROUP);
      if (value) {
        return find(value, apparelid);
      }
    }
    return undefined;
  }

  function buildCartApparelObj(backendObj, qtySelected) {
    const description = [
      {
        titleDesc:
          backendObj?.gender && backendObj?.description
            ? backendObj.description.concat(" | ").concat(backendObj.gender)
            : "",
        value: "",
      },
      {
        titleDesc: language.steps_information["cart_status.apparel.title.size"],
        value: ` ${backendObj?.size || ""}`,
      },
      {
        titleDesc:
          language.steps_information["cart_status.apparel.title.color"],
        value: ` ${backendObj?.color?.name || ""}`,
      },
    ];
    const cartObj = {
      groupId: `${APPARELS_GROUP}-${backendObj.subgroup_name}`,
      attributeKey: `${APPARELS_GROUP}-${backendObj.subgroup_name}-${backendObj.father_id}`,
      domainValueKey: backendObj.id,
      fatherId: backendObj.father_id,
      name: backendObj.name,
      attributeName: APPARELS_GROUP,
      subgroupName: backendObj.subgroup_name,
      price: {
        value: backendObj?.price?.unformatted_amount || backendObj.price,
        formattedValue:
          backendObj?.price?.formatted_amount || backendObj.formattedPrice,
        currency: backendObj?.price?.currency || backendObj.currency,
      },
      description,
      quontity: qtySelected,
      image: backendObj.preview_image,
    };
    return cartObj;
  }

  function syncCartApparels(apparelItemTypeList = [], allApparels) {
    if (apparelItemTypeList.length > 0) {
      apparelItemTypeList.forEach((backendApparel) => {
        const findedApparel = findApparelByChildId(
          allApparels,
          backendApparel.sku
        );
        const apparel = findedApparel?.father
          ? { ...findedApparel?.father }
          : { ...findedApparel };
        if (apparel) {
          backendApparel.id = apparel.id;
          backendApparel.father_id = apparel.father_id;
          backendApparel.subgroup_name = apparel.subgroup_name;
          backendApparel.gender = apparel.gender;
          backendApparel.description = apparel.description;
          backendApparel.size = apparel.size;
          backendApparel.preview_image = apparel.preview_image;
          backendApparel.color = {};
          backendApparel.color.name = apparel?.color?.name;
          const cartObj = buildCartApparelObj(
            backendApparel,
            backendApparel.quantity
          );
          synchronizeCart(true, cartObj);
        }
      });
    }
  }

  function findApparelBySKU(sku = "") {
    let apparel;
    if (apparelsContent?.allApparels && sku) {
      const findedApparel = findApparelByChildId(
        apparelsContent.allApparels,
        sku
      );
      apparel = findedApparel?.father || findedApparel;
    }
    return apparel;
  }

  return {
    familyCode: mtoContent.familyCode,
    isThereInCart,
    buildChildrenList,
    getChildFromCart,
    buildCartApparelObj,
    syncCartApparels,
    findApparelBySKU,
  };
}
