import React, { createContext, useContext, useReducer, useState } from "react";

export const NOT_FOUND_CODE = "404";
export const INTERNAL_SERVER_ERROR_CODE = "500";

export const DEFAULT_ERROR_STATE = {
  code: undefined,
  hasError: false,
};

export const DEFAULT_ERROR_DETAILS = {
  not_found_title: "",
  not_found_description: "",
  not_found_btn_label: "",
  internal_server_title: "",
  internal_server_description: "",
  internal_server_btn_label: "",
};

export const ErrorContext = createContext(null);

export function ErrorProvider({ children }) {
  const [errorState, dispatch] = useReducer(errorReducer, DEFAULT_ERROR_STATE);

  function echoError({ error = undefined, caller = "" }) {
    const message = `Error: from ${caller}`;
    if (error) {
      console.error(message, error);
    } else {
      console.error(message);
    }
  }

  /**
   * Handle error to display a service page related to http status error code.
   * @param {string} httpStatusCode http status code
   * @param {Object} consoleError error object returned from catch statement
   * @param {string} caller name of function where the error is gets up
   */
  const onError = ({
    httpStatusCode = "",
    consoleError = undefined,
    caller = "",
  }) => {
    if (httpStatusCode !== "" && consoleError && caller !== "") {
      echoError({ error: consoleError, caller });
      const errorState = { code: httpStatusCode };
      dispatch(errorState);
    } else {
      echoError("ErrorProvider - onError - action is invalid!");
    }
  };

  const provider = {
    errorState,
    errorDispatch: dispatch,
    echoError,
    onError,
  };

  return (
    <ErrorContext.Provider value={provider}>{children}</ErrorContext.Provider>
  );
}

function errorReducer(errorState, action) {
  if (action?.code) {
    const newState = { ...errorState };
    newState.code = action.code;
    newState.hasError = true;
    return newState;
  }
  return DEFAULT_ERROR_STATE;
}

export function useError() {
  return useContext(ErrorContext);
}
