import React, { useState, createContext, useEffect } from "react";

export const ModelContext = createContext({
  modelContext: {
    type: 0,
    name: "",
  },
});

const localStateModel = JSON.parse(localStorage.getItem("modelcontent"));

export function ModelProvider({ children, props }) {
  const [modelContext, setModelContext] = useState(props || localStateModel);

  useEffect(() => {
    localStorage.setItem("modelcontent", JSON.stringify(modelContext));
  }, [modelContext]);

  const provider = {
    modelContext,
    setModelContext: (selected) => {
      let newModelContext = selected;
      setModelContext(newModelContext);
    },
  };

  return (
    <ModelContext.Provider value={provider}>{children}</ModelContext.Provider>
  );
}
