import React, { useState, createContext } from "react";

export const RightSideHeaderContext = createContext();

export function RightSideHeaderProvider({ children }) {
  const [showNote, setShowNote] = useState(false);

  return (
    <RightSideHeaderContext.Provider value={{ showNote, setShowNote }}>
      {children}
    </RightSideHeaderContext.Provider>
  );
}
