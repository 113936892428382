import React, { useState, createContext, useContext } from "react";
import { formatPrice, isEmptyObject } from "../utils/Utils";
import { pushWBCRemoveStepEvent } from "./data-layer/pusher/WBCRemoveStepPusher";
import { steps, useDataLayerDispatch } from "./data-layer/DataLayerContext";

export const CarrelloContext = createContext({
  carrelloContent: {},
});

export function CarrelloProvider({ children, props }) {
  const [carrelloContent, setCarrelloContent] = useState(
    props || JSON.parse(localStorage.getItem("carrellocontent"))
  );
  const { dispatch } = useDataLayerDispatch();

  function checkBackendCart(backendResponse) {
    return backendResponse?.cart?.length > 0;
  }

  function checkListElement() {
    return carrelloContent.listElementCarrello.length > 0;
  }

  function hasGroup(groupName) {
    const group = carrelloContent.listElementCarrello.find(
      (element) => element.gruppo === groupName
    );
    if (group && group?.list) {
      return true;
    }
    return false;
  }

  function removeItem(itemId, itemStep, group) {
    const equipmentGroup = carrelloContent?.listElementCarrello.find(
      (item) => item.gruppo === "Allestimento"
    );
    const equipmentName = equipmentGroup?.preconfigurationName || "";

    const newGroup = group;
    for (const subGroup in newGroup.list) {
      if (subGroup) {
        for (const item in newGroup.list[subGroup]) {
          if (item === itemId) {
            pushWBCRemoveStepEvent(
              {
                itemId,
                itemName: newGroup.list[subGroup][item]?.defaultName || "",
                itemPrice:
                  newGroup.list[subGroup][item]?.price?.unformatted_amount ||
                  "",
                itemStep,
                quantity: newGroup.list[subGroup][item]?.quontity || "",
                equipmentName,
                currency: carrelloContent?.dettagli?.currency?.name,
              },
              dispatch
            );
            delete newGroup.list[subGroup][item];
          }
        }
      }
    }
    for (const subGroup in newGroup.list) {
      if (subGroup && isEmptyObject(newGroup.list[subGroup])) {
        delete newGroup.list[subGroup];
      }
    }
    return newGroup;
  }

  function updatePrice(newCarrelloContent, quotationResponse, backendResponse) {
    const tempCart = { ...newCarrelloContent };
    
    if (quotationResponse) {
      tempCart.dettagli.unformattedTotalAccessoriesPrice =
        quotationResponse.unformatted_accessories_amount || "";
      tempCart.dettagli.formattedTotalAccessoriesPrice =
        quotationResponse.formatted_accessories_amount || "";
      tempCart.dettagli.unformattedTotalApparelsPrice =
        quotationResponse.unformatted_apparels_amount || "";
      tempCart.dettagli.formattedTotalApparelsPrice =
        quotationResponse.formatted_apparels_amount || "";

      if (carrelloContent?.dettagli?.prezzoCostoMoto) {
        let totalBikePrice = carrelloContent.dettagli.prezzoCostoMoto;
        // eslint-disable-next-line no-unused-expressions
        tempCart?.dettagli?.unformattedTotalAccessoriesPrice
          ? (totalBikePrice +=
              tempCart.dettagli.unformattedTotalAccessoriesPrice)
          : "";
        // eslint-disable-next-line no-unused-expressions
        tempCart?.dettagli?.unformattedTotalApparelsPrice
          ? (totalBikePrice += tempCart.dettagli.unformattedTotalApparelsPrice)
          : "";

        tempCart.dettagli.prezzoTotaleMoto = totalBikePrice;
        tempCart.dettagli.motorBikeFormattedTotalPrice = formatPrice(
          totalBikePrice,
          carrelloContent?.dettagli?.currency,
          false
        );
      }
    }
    

    

    return tempCart;
  }

  function updateCartOnRemove({
    backendResponse = undefined,
    quotationResponse = undefined,
    groupName = "",
    itemId = "",
    itemStep = "",
  }) {
    if (checkListElement() && hasGroup(groupName)) {
      let newCarrelloContent = { ...carrelloContent };
      const group = newCarrelloContent.listElementCarrello.find(
        (element) => element.gruppo === groupName
      );
      const groupIndex = newCarrelloContent.listElementCarrello.findIndex(
        (element) => element.gruppo === groupName
      );
      const newGroup = removeItem(itemId, itemStep, group);
      newCarrelloContent.listElementCarrello.splice(groupIndex, 1, newGroup);
      newCarrelloContent = updatePrice(
        newCarrelloContent,
        quotationResponse,
        backendResponse
      );
      updateCartContent(newCarrelloContent);
    }
  }

  function buildCartItem(childSelected, qtySelected) {
    const item = {
      ...childSelected,
      quontity: qtySelected,
      modifica: false,
      rimuovi: true,
    };
    item.defaultName = item.default_name;
    delete item.default_name;
    return item;
  }

  function initCartGroup(childSelected, qtySelected, groupName) {
    const typology = childSelected.subgroup_name;
    const item = {};
    item[childSelected.id] = buildCartItem(childSelected, qtySelected);
    const list = {};
    list[typology] = item;
    const group = {
      gruppo: groupName,
      list,
      modifica: false,
      rimuovi: true,
    };
    return group;
  }

  function addApparel2Group({
    newCarrelloContent,
    groupName,
    childSelected,
    qtySelected,
    backendResponse,
  }) {
    const tempCart = { ...newCarrelloContent };
    const group = tempCart.listElementCarrello.find(
      (element) => element.gruppo === groupName
    );
    const groupIndex = tempCart.listElementCarrello.findIndex(
      (element) => element.gruppo === groupName
    );

    if (group) {
      const typology = childSelected.subgroup_name;
      if (group.list?.[typology]) {
        const apparelId = childSelected.id;
        if (group.list[typology]?.[apparelId]) {
          const responseItem = backendResponse.cart.find(
            (element) => element.id === apparelId
          );
          group.list[typology][apparelId].price = responseItem.price;
          group.list[typology][apparelId].quontity = responseItem.qty;
        } else {
          group.list[typology][apparelId] = buildCartItem(
            childSelected,
            qtySelected
          );
          const responseItem = backendResponse.cart.find(
            (element) => element.id === childSelected.id
          );
          group.list[typology][childSelected.id].price = responseItem.price;
        }
      } else {
        const item = {};
        item[childSelected.id] = buildCartItem(childSelected, qtySelected);
        group.list[typology] = item;
        const responseItem = backendResponse.cart.find(
          (element) => element.id === childSelected.id
        );
        group.list[typology][childSelected.id].price = responseItem.price;
      }

      tempCart.listElementCarrello.splice(groupIndex, 1, group);
    }

    return tempCart;
  }

  function updateCartOnAdd({
    childSelected = undefined,
    qtySelected,
    groupName = "",
    backendResponse = undefined,
    quotationResponse = undefined,
  }) {
    if (checkBackendCart(backendResponse) && checkListElement()) {
      let newCarrelloContent = { ...carrelloContent };
      if (hasGroup(groupName)) {
        newCarrelloContent = addApparel2Group({
          newCarrelloContent,
          groupName,
          childSelected,
          qtySelected,
          backendResponse,
        });
      } else {
        const newGroup = initCartGroup(childSelected, qtySelected, groupName);
        newCarrelloContent.listElementCarrello = [
          ...newCarrelloContent.listElementCarrello,
          newGroup,
        ];
      }
      newCarrelloContent = updatePrice(
        newCarrelloContent,
        quotationResponse,
        backendResponse
      );
      updateCartContent(newCarrelloContent);
    }
  }

  const updateCartContent = (selected) => {
    const newCarrelloContent = selected;
    localStorage.removeItem("carrellocontent");
    localStorage.setItem("carrellocontent", JSON.stringify(newCarrelloContent));
    setCarrelloContent(newCarrelloContent);
  };

  const provider = {
    carrelloContent,
    setCarrelloContent: updateCartContent,
    updateCartOnRemove,
    updateCartOnAdd,
  };

  return (
    <CarrelloContext.Provider value={provider}>
      {children}
    </CarrelloContext.Provider>
  );
}

export function useCart() {
  return useContext(CarrelloContext);
}
