import { useEffect, useState, useRef, useCallback } from "react";

// Taken by https://github.com/robinjonsson/react-use-sticky/blob/master/src/index.ts
export function useViewport() {
  const stickyRef = useRef(null);
  const [inViewport, setInViewport] = useState(true);

  const handleScroll = useCallback(() => {
    if (!stickyRef.current) return;
    const rect = stickyRef.current.getBoundingClientRect();
    const inViewport =
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight);

    setInViewport(inViewport);
  }, [stickyRef]);

  useEffect(() => {
    const configuratorContentContainerDiv = document.getElementById(
      "configurator-content-container"
    );
    if (configuratorContentContainerDiv) {
      configuratorContentContainerDiv.addEventListener("scroll", handleScroll);

      return () =>
        configuratorContentContainerDiv.removeEventListener(
          "scroll",
          handleScroll
        );
    }
  }, [handleScroll]);

  return [stickyRef, inViewport];
}
