"use client";
import React, { useEffect, useState } from "react";
import ProgressBarSlider from "../../components/carousel/carousel-configurator/ProgressBarSlider";
import style from "./LoadingPage.module.scss";
import logo from "../../assets/images/logo.svg";
import logoScrambler from "../../assets/images/Scrambler_Ducati_logo.svg";
import { retrieveAdditionalInfoResources } from "../../assets/resources/DucatiResources";
import { getLanguage } from "../../utils/Localize";
import { useError } from "../../context/ErrorContext";
import { useScrambler } from "../../hooks/useScrambler";

function LoadingPage() {
  const language = getLanguage();
  const [title, setTitle] = useState("");
  const { echoError } = useError();

  useEffect(() => {
    retrieveAdditionalInfoResources(language)
      .then((data) => {
        if (data?.configurator_information) {
          setTitle(data?.configurator_information["loading_page.title"]);
        }
      })
      .catch((error) => {
        echoError({
          error,
          caller: "LoadingPage - retrieveAdditionalInfoResources",
        });
      });
  }, []);

  return <LoadingPageComponent title={title} />;
}

export default LoadingPage;

function LogoImage({ scramblerStyle }) {
  return (
    <img
      height={64}
      width={scramblerStyle ? 120 : 62}
      src={scramblerStyle ? logoScrambler : logo}
      alt="Ducati Logo"
    />
  );
}

export function LoadingPageComponent({ title, children, progress }) {
  const { scramblerStyle } = useScrambler();

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div
        className={` ${style.logobackground} align-items-center d-flex justify-content-center flex-column vw-100 h-100`}
        role="status"
      >
        {children || <LogoImage scramblerStyle={scramblerStyle} />}
        <p className={`${style.logotitle} `}>{title}</p>
        <div
          className={`d-flex justify-content-center align-items-center ${style.logoprogressbar}`}
        >
          <ProgressBarSlider progress={progress} />
        </div>
      </div>
    </div>
  );
}
