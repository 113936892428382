import React from "react";
import { useAuth } from "react-oauth2-pkce";
import RouteDUCATI from "../../layouts/RouteDUCATI";
import { useDataLayerDispatch } from "../../../common/context/data-layer/DataLayerContext";
import { buildUserInfoEvent } from "../../../common/context/data-layer/builder/UserInfoBuilder";
import CustomServicesPage from "../../../common/pages/services-page/CustomServicePage";

function DealerAuthorization() {
  const { authService } = useAuth();
  const { dispatch } = useDataLayerDispatch();

  const login = async () => {
    await authService.authorize();
  };

  const logout = async () => {
    await authService.logout(true);
  };

  if (authService.isPending()) {
    return (
      <div className="p-0">
        <CustomServicesPage
          customTitle="Pending connection..."
          customDescription="Motorbike Configurator"
          customBtnLabel="Reset"
          customHandler={() => {
            logout();
            login();
          }}
        />
      </div>
    );
  }

  if (!authService.isAuthenticated()) {
    return login();
  }

  const token = authService.getAuthTokens();

  function pushUserInfoEvent() {
    const userInfoEvent = buildUserInfoEvent(authService);
    dispatch(userInfoEvent);
  }

  pushUserInfoEvent();

  return <RouteDUCATI />;
}

export default DealerAuthorization;
