export function pushEvent(type, data, data1) {
  let event;
  if (data1 !== undefined) {
    const newData = { ...data };
    newData.data1 = data1;
    event = new CustomEvent(type, { detail: newData });
  } else event = new CustomEvent(type, { detail: data });
  document.dispatchEvent(event);
}

export const eventType = {
  itemContentDisplayEvent: "item-content-display-event",
  reloadAfterGetAuthTokensEvent: "reload-after-getauthtokens-event",
  isLoggedEvent: "is-logged-event",
  cardItemAccordionEvent: "card-item-accordion-event",
  cardItemAccordionPreviousEvent: "card-item-accordion-previous-event",
  priceModalItemEvent: "price-modal-item-event",
  cardDefaultValueEvent: "card-default-value-event",
  priceModalItemAccessoriesEvent: "price-modal-item-accessories-event",
  setStatusCarrelloEvent: "set-status-carrello-event",
  closeVoiceForm: "close-voice-form",
  modalIncompatiblityEvent: "set-modal-incompatibility-event",
  closeVoiceCard: "close-voice-card",
  confirmMobileVoice: "confirm-mobile-voice",
  changeAccessoryColor: "change-accessory-color",
  apparelsGalleryLeftbarShowEvent: "apparels-gallery-leftbar-show-event",
  clearfeRedirectKeyHomePageEvent: "clear-fe-redirect-key-home-page-event",
  navItemListEvent: "nav-item-list-event",
  navItemSelected: "nav-item-selected",
};
