import React, { useContext, useState } from "react";
import navbarStyle from "./Navbar.module.scss";
import { LanguageContext } from "../../../context/LanguageContext";
import { endpointResolver } from "../../../utils/EndpointResolver";
import Hamburger from "../hamburger/Hamburger";
import HamburgerMobile from "../hamburger/HamburgerMobile";
import { useMobile } from "../../../hooks/useMobile";

function Navbar({ backToHome, setBackToHome }) {
  const isMobile = useMobile();
  const { language } = useContext(LanguageContext);
  const ducatiShopUrl = endpointResolver(
    process.env.REACT_APP_URL_DUCATI_SHOP,
    language.country.toLowerCase(),
    language.language.toLowerCase()
  );

  return (
    <div className="mt-1">
      <ul
        className={"nav  align-items-center  ".concat(
          isMobile
            ? navbarStyle["header-navbar-mobile"]
            : navbarStyle["header-navbar"]
        )}
      >
        {isMobile ? (
          <HamburgerMobile
            backToHome={backToHome}
            setBackToHome={setBackToHome}
          />
        ) : (
          <Hamburger />
        )}
      </ul>
    </div>
  );
}

export default Navbar;
