import React, { useState, useEffect, useContext } from "react";
import Authorization from "../../../../components/authorization/Authorization";
import ArrowDownBlack from "../../../../assets/images/ArrowDownBlack.svg";
import ArrowUp from "../../../../assets/images/ArrowUp.svg";
import { LanguageContext, Search } from "../../../../context/LanguageContext";
import { endpointResolver } from "../../../../utils/EndpointResolver";
import style from "./MyDucatiMenuMobile.module.scss";
import styleHamburger from "../../hamburger/Hamburger.module.scss";
import { sessionStorageKey, string2Bool } from "../../../../utils/Utils";

import DealerLoggedButton from "../../../../../b2b/components/my-ducati-dealer/DealerLoggedButton";



function MyDucatiMenuMobile(props) {
  const [registerUrl, setRegisterUrl] = useState("");
  const [myProfileUrl, setMyProfileUrl] = useState("");
  const [myConfigurationsUrl, setMyConfigurationsUrl] = useState("");
  const [isShowUpBlack, setIsShowUpBlack] = useState(false);
  const isLogged = string2Bool(
    sessionStorage.getItem(sessionStorageKey.isAuthorizedKey)
  );
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    setRegisterUrl(
      endpointResolver(
        process.env.REACT_APP_URL_REGISTER,
        language.country.toLowerCase(),
        language.language.toLowerCase()
      )
    );
    setMyProfileUrl(
      endpointResolver(
        process.env.REACT_APP_MY_DUCATI_PROFILE,
        language.country.toLowerCase(),
        language.language.toLowerCase()
      )
    );
    setMyConfigurationsUrl(
      endpointResolver(
        process.env.REACT_APP_MY_DUCATI_CONFIGURATIONS,
        language.country.toLowerCase(),
        language.language.toLowerCase()
      )
    );
  }, [language, language.country, language.language]);

  function linkElement(url, label) {
    return (
      <a
        className={"text-decoration-none"}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <span className={"p-2 ".concat(style.font2)}>
          {label ? label.toUpperCase() : "test"}
        </span>
      </a>
    );
  }

  function handleBtnClick(e) {
    e.preventDefault();
    if (isShowUpBlack) {
      setIsShowUpBlack(false);
    } else {
      setIsShowUpBlack(true);
    }
  }

  function dropdownButton() {
    return (
      <>
        {isLogged ? (
          dropdownLoggedButton()
        ) : (
          <span className={`${style.font2} `}>
            {language.labels["auth.my_ducati"].toUpperCase()}
          </span>
        )}
      </>
    );
  }

  function dropdownLoggedButton() {
    let button = "";
    
    button = <DealerLoggedButton />;
    
    
    return button;
  }

  function dropdownList() {
    return (
      <div className={`${style.margin_top3} ${style.cursor} ps-3`}>
        <div
          onClick={() => {
            props.setModalBody();
            const pathname =
              "/bikes/" +
              language.country.toLowerCase() +
              "/" +
              language.language.toLowerCase();
            navigate(pathname);
          }}
        >
          <picture>
            <img src={arrow} alt={""} />
          </picture>
          <span
            className={`${styleHamburger.font3} ${styleHamburger.text_trasform} ms-3`}
          >
            <Search did="header_menu" tid="login" />
          </span>
        </div>

        <div className={"mt-4"}>
          <Authorization logInlabel={language.labels["auth.login"]} />

          <div className={`${style.margin_top3} ${style.cursor}`}>
            {linkElement(registerUrl, language.labels["auth.register"])}
          </div>
        </div>
      </div>
    );
  }

  function dropdownListLogged() {
    return (
      <div className={`ps-3`}>
        <div
          onClick={() => {
            props.setModalBody();
            const pathname =
              "/bikes/" +
              language.country.toLowerCase() +
              "/" +
              language.language.toLowerCase();
            navigate(pathname);
          }}
        >
          <picture>
            <img src={arrow} alt={""} />
          </picture>
          <span
            className={`${styleHamburger.font3} ${styleHamburger.text_trasform} ms-3`}
          >
            {language.labels["auth.my_ducati"].toUpperCase()}
          </span>
        </div>

        <div className={`${style.margin_top3} ${style.cursor}`}>
          {linkElement(
            myConfigurationsUrl,
            language.labels["auth.my_configuration"]
          )}
        </div>
        <div className={`${style.margin_top3} ${style.cursor}`}>
          {linkElement(myProfileUrl, language.labels["auth.my_profile"])}
        </div>
        <div className={`${style.margin_top3} ${style.cursor}`}>
          <Authorization logOutlabel={language.labels["auth.logout"]} />
        </div>
      </div>
    );
  }

  function headerDropdown() {
    return (
      <>
        {props.openMenu
          ? isLogged
            ? dropdownListLogged()
            : dropdownList()
          : dropdownButton()}
      </>
    );
  }

  function renderMyDucatiMenu() {
    return headerDropdown();
  }

  return renderMyDucatiMenu();
}

export default MyDucatiMenuMobile;
