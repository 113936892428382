import MountainRoadDayImage from "../../../assets/images/carousel/Mountain_Road_Day.png";
import MountainRoadNightImage from "../../../assets/images/carousel/Mountain_Road_Night.png";
import StudioDayImage from "../../../assets/images/carousel/Studio_Day.png";
import StudioNightImage from "../../../assets/images/carousel/Studio_Night.png";
import OffroadDayImage from "../../../assets/images/carousel/Offroad_Day.png";
import OffroadNightImage from "../../../assets/images/carousel/Offroad_Night.png";
import PiazzaDayImage from "../../../assets/images/carousel/Piazza_Day.png";
import PiazzaNightImage from "../../../assets/images/carousel/Piazza_Night.png";
import RacetrackDaytImage from "../../../assets/images/carousel/Racetrack_Day.png";
import RacetrackNightImage from "../../../assets/images/carousel/Racetrack_Night.png";

export const DEFAULT_ENV_STATE = [
  {
    id: "env_studio",
    DAY: StudioDayImage,
    NIGHT: StudioNightImage,
  },
];

export const DEFAULT_ENV_MAP = new Map([
  ["MSV4RI", "env_offroad"],
  ["MSV4RS", "env_racetrack"],
  ["MSV4S", "env_mountainroad"],
  ["MSV4PP", "env_racetrack"],
  ["MSV4", "env_mountainroad"],
]);

export const ENV_VERSION_MAP = new Map([
  [
    "MSV4RS",
    [
      {
        id: "env_studio",
        DAY: StudioDayImage,
        NIGHT: StudioNightImage,
      },
      {
        id: "env_mountainroad",
        DAY: MountainRoadDayImage,
        NIGHT: MountainRoadNightImage,
      },
      {
        id: "env_racetrack",
        DAY: RacetrackDaytImage,
        NIGHT: RacetrackNightImage,
      },
    ],
  ],
  [
    "MSV4RI",
    [
      {
        id: "env_studio",
        DAY: StudioDayImage,
        NIGHT: StudioNightImage,
      },
      {
        id: "env_mountainroad",
        DAY: MountainRoadDayImage,
        NIGHT: MountainRoadNightImage,
      },
      {
        id: "env_urban",
        DAY: PiazzaDayImage,
        NIGHT: PiazzaNightImage,
      },
      {
        id: "env_offroad",
        DAY: OffroadDayImage,
        NIGHT: OffroadNightImage,
      },
    ],
  ],
  [
    "MSV4S",
    [
      {
        id: "env_studio",
        DAY: StudioDayImage,
        NIGHT: StudioNightImage,
      },
      {
        id: "env_mountainroad",
        DAY: MountainRoadDayImage,
        NIGHT: MountainRoadNightImage,
      },
      {
        id: "env_urban",
        DAY: PiazzaDayImage,
        NIGHT: PiazzaNightImage,
      },
      {
        id: "env_offroad",
        DAY: OffroadDayImage,
        NIGHT: OffroadNightImage,
      },
    ],
  ],
  [
    "MSV4PP",
    [
      {
        id: "env_studio",
        DAY: StudioDayImage,
        NIGHT: StudioNightImage,
      },
      {
        id: "env_mountainroad",
        DAY: MountainRoadDayImage,
        NIGHT: MountainRoadNightImage,
      },
      {
        id: "env_urban",
        DAY: PiazzaDayImage,
        NIGHT: PiazzaNightImage,
      },
      {
        id: "env_racetrack",
        DAY: RacetrackDaytImage,
        NIGHT: RacetrackNightImage,
      },
    ],
  ],
  [
    "MSV4",
    [
      {
        id: "env_studio",
        DAY: StudioDayImage,
        NIGHT: StudioNightImage,
      },
      {
        id: "env_mountainroad",
        DAY: MountainRoadDayImage,
        NIGHT: MountainRoadNightImage,
      },
      {
        id: "env_urban",
        DAY: PiazzaDayImage,
        NIGHT: PiazzaNightImage,
      },
      {
        id: "env_offroad",
        DAY: OffroadDayImage,
        NIGHT: OffroadNightImage,
      },
    ],
  ],
]);
