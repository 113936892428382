import { useCallback } from "react";
import { headerLabelStyler } from "../../../utils/Utils";
import { useDucatiServiceMTO } from "../../../services/useDucatiServiceMTO";
import { startedFromType, useMTOContext } from "../../../context/MTOContext";
import { useLanguageContent } from "../../../context/LanguageContext";
import { useApparelsContent } from "../../../context/ApparelsContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  APPARELS_GROUP,
  buildApparelsContent,
  buildHighlightedOutfits,
} from "../../../utils/Apparels";
import {
  conflictTypes,
  isThereConflict,
  useConflictData,
} from "../../modal/conflict-modal-mto/ConflictContainer";
import { useError } from "../../../context/ErrorContext";
import { useApparelsMTO } from "../../../hooks/useApparelsMTO";
import {
  useBikeEnrichment,
  useFamilyMTOComplete,
} from "../../../context/FamilyMTOContext";
import {
  AFTERMARKET_GROUP,
  useAftermarketMTO,
} from "../../../hooks/useAftermarketMTO";
import defaultImg from "../../../assets/images/DefaultImage.png";
import MSV4RS_COLOR_RS from "../../../assets/images/colours/MS5G-MSV4RS-24-DSMG-color.png";
import {
  messageType,
  useServiceModal,
} from "../../modal/service-modal/ServiceModal";
import { useSaveModal } from "../../modal/save-modal/SaveMyDucatiModal";
import { useSaveRecapLogin } from "../../../context/SaveRecapLoginContext";
import { useNavbarConfigurator } from "../../../context/NavbarConfiguratorContext";

const CID_MTO_PREFIX = "MTO-";

export function useConfiguratorMTO() {
  const { getSuperModelInfo, findSpecificVersion, getFamilyInfo } =
    useFamilyMTOComplete();
  const { initializedContext, state, ...setters } = useMTOContext();
  const { language } = useLanguageContent();
  const { getTabI8N } = useNavbarConfigurator();
  const { setApparelsContent } = useApparelsContent();
  const [searchParams, setSearchParams] = useSearchParams();
  const { family, supermodel, version } = useParams();
  const { onError, echoError } = useError();
  const navigate = useNavigate();
  const { syncCartApparels } = useApparelsMTO();
  const {
    fetchEnrichmentMTO,
    fetchApparelsMTO,
    fetchInitMTO,
    fetchRetrieveConfigMTO,
    fetchAccessoriesMTO,
    fetchAccessoriesEnrichmentMTO,
    fetchSaveOnMyDucatiMTO,
  } = useDucatiServiceMTO();
  const { setAccessoryContent } = useAftermarketMTO();
  const { updateBikeEnrichment } = useBikeEnrichment();
  const { showConflictsModal } = useConflictData();
  const { showSaveModal } = useSaveModal();
  const { showServiceModal } = useServiceModal();
  const { saveConfig, setSaveConfig } = useSaveRecapLogin();

  const initBikeEnrichment = async (productCode) => {
    const bikeEnrichment = await fetchEnrichmentMTO(
      language.country.toLowerCase(),
      language.language.toLowerCase(),
      productCode
    )
      .then((response) => {
        return updateBikeEnrichment(response);
      })
      .catch((error) => {
        onError({
          httpStatusCode: error.message,
          consoleError: error,
          caller: "initBikeEnrichment - fetchEnrichmentMTO",
        });
      });
    return bikeEnrichment;
  };

  const updateMTOContext = useCallback(
    ({
      backendResponse,
      familyCode,
      productCode,
      isMake = false,
      isTake = false,
      resAccessories,
      resApparels,
      bikeEnrichment,
    }) => {
      /* INIT MTO */
      const MTOContext = [];
      const navList = [];
      setters.setCID(backendResponse.cid);
      setters.setVID(backendResponse.vid);
      setters.setConfigId(backendResponse.bikeConfigurationId);
      setters.setFamilyName(family);
      setters.setFamilyCode(familyCode);
      setters.setProductLine(backendResponse.modelData.productLine);
      setters.setModelName(supermodel);
      setters.setModelId(backendResponse?.modelData?.model || "");
      setters.setModelYear(backendResponse?.modelData?.modelYear || "");
      setters.setProductCode(productCode);
      setters.setSuperModelCode(backendResponse?.modelData?.superModel || "");
      const dealerURL = backendResponse?.urlB2C || "";
      const dealerURLVid =
        dealerURL?.length > 0 ? dealerURL.replace("?cid=", "?vid=") : "";
      setters.setUrlB2B(dealerURLVid);
      const notes =
        backendResponse?.quotation?.description !== "Quotation #"
          ? backendResponse?.quotation?.description
          : "";
      setters.setNotes(notes);
      if (isThereConflict(backendResponse.bikeConfiguration)) {
        showConflictsModal(backendResponse.bikeConfiguration);
      }
      // TODO MTO enable only when backend returns complete true
      // setters.setComplete(backendResponse.bikeConfiguration.complete);
      const filteredGroups = backendResponse.bikeConfiguration.groups.filter(
        (item) => !item.groupType.includes(conflictTypes.conflict)
      );
      if (filteredGroups[0]?.attributes?.length > 0) {
        const first = filteredGroups[0].attributes[0];
        const firstVersion = first.domainValues.find(
          (value) => value.name === first.value
        );
        if (firstVersion) {
          setters.setBikeName(firstVersion.langDepName);
          const currencyIso =
            backendResponse?.currencyIsoCode ||
            firstVersion?.price?.currencyIso;
          setters.setCurrencyIso(currencyIso);
        }
      }
      filteredGroups.forEach((group, index) => {
        const MTOContextGroup = [];
        if (index > 0 && index < 8) {
          if (
            !group.name.includes("PILOT SEAT") &&
            !group.name.includes("BUNDLE") &&
            !group.name.includes("BIT")
          ) {
            if (!isTake) {
              const tabName = getTabI8N(group.name);
              navList.push({
                step:
                  tabName?.length > 0 ? tabName : headerLabelStyler(group.name),
                position: index,
                group: headerLabelStyler(group.name),
              });
            }
            group.attributes.forEach((attribute) => {
              const MTOContextAttribute = {
                chaKey: attribute.name,
                valueList: [],
              };
              attribute.domainValues.forEach((domainValue) => {
                const MTOContextElement = {};
                MTOContextElement.domainValueKey = domainValue.key;
                MTOContextElement.attributeKey = attribute.key;
                MTOContextElement.groupId = group.id;
                const image =
                  group.name.includes("COLOR") && version === "MSV4RS"
                    ? MSV4RS_COLOR_RS
                    : domainValue?.images || defaultImg;
                MTOContextElement.images = image;
                MTOContextElement.langDepName = domainValue.langDepName;
                MTOContextElement.price = {
                  value: domainValue.price.value,
                  formattedValue: domainValue.price.formattedValue,
                  currency: domainValue.price.currencyIso,
                };
                MTOContextElement.selected = domainValue.selected;
                MTOContextElement.attributeName = headerLabelStyler(group.name);
                MTOContextAttribute.valueList.push(MTOContextElement);
                setters.synchronizeCart(domainValue.selected, {
                  groupId: group.id,
                  attributeKey: attribute.key,
                  domainValueKey: domainValue.key,
                  name: domainValue.langDepName,
                  attributeName: headerLabelStyler(group.name),
                  price: {
                    value: domainValue.price.value,
                    formattedValue: domainValue.price.formattedValue,
                    currency: domainValue.price.currencyIso,
                  },
                  image,
                  description: domainValue.description,
                  selected: domainValue.selected,
                });
              });
              MTOContextGroup.push(MTOContextAttribute);
            });
            MTOContext.push(MTOContextGroup);
          } else if (group.name.includes("PILOT SEAT")) {
            // handle pilot seat group
            if (!isTake) {
              navList.push({
                step: language.steps_information[
                  "steps_information.name.seats"
                ],
                position: index,
                group: headerLabelStyler(group.name),
              });
            }
            group.attributes.forEach((attribute) => {
              const MTOContextAttribute = {
                chaKey: attribute.name,
                valueList: [],
              };
              attribute.domainValues.forEach((domainValue) => {
                const MTOContextElement = {};
                MTOContextElement.domainValueKey = domainValue.key;
                MTOContextElement.attributeKey = attribute.key;
                MTOContextElement.groupId = group.id;
                MTOContextElement.images = domainValue?.images || defaultImg;
                MTOContextElement.langDepName = domainValue.langDepName;
                MTOContextElement.price = {
                  value: domainValue.price.value,
                  formattedValue: domainValue.price.formattedValue,
                  currency: domainValue.price.currencyIso,
                };
                MTOContextElement.selected = domainValue.selected;
                MTOContextElement.attributeName = headerLabelStyler(group.name);
                MTOContextAttribute.valueList.push(MTOContextElement);
                setters.synchronizeCart(domainValue.selected, {
                  groupId: group.id,
                  attributeKey: attribute.key,
                  domainValueKey: domainValue.key,
                  name: domainValue.langDepName,
                  attributeName: headerLabelStyler(group.name),
                  price: {
                    value: domainValue.price.value,
                    formattedValue: domainValue.price.formattedValue,
                    currency: domainValue.price.currencyIso,
                  },
                  image: domainValue?.image || defaultImg,
                  description: domainValue.description,
                  selected: domainValue.selected,
                });
              });
              const MTOContextElement = {};
              MTOContextElement.langDepName = attribute.langDepName;
              MTOContextElement.isGroupName = true;
              MTOContextAttribute.valueList.push(MTOContextElement);
              MTOContextGroup.push(MTOContextAttribute);
            });
            MTOContext.push(MTOContextGroup);
          } else if (group.name.includes("BUNDLE")) {
            // handle bundle group
            if (!isTake) {
              navList.push({
                step: language.steps_information[
                  "steps_information.name.bundle"
                ],
                position: index,
                group: headerLabelStyler(group.name),
              });
            }
            group.attributes.forEach((attribute) => {
              const MTOContextAttribute = {
                chaKey: attribute.name,
                valueList: [],
              };
              const MTOContextElement = {};
              MTOContextElement.selected = attribute.domainValues[0].selected;
              MTOContextElement.price = {
                value: attribute.domainValues[0].price.value,
                formattedValue: attribute.domainValues[0].price.formattedValue,
                currency: attribute.domainValues[0].price.currencyIso,
              };
              MTOContextElement.domainValueKey = attribute.name;
              MTOContextElement.url = attribute.url;
              MTOContextElement.attributeKey = attribute.key;
              MTOContextElement.groupId = group.id;
              MTOContextElement.images = attribute?.images || defaultImg;
              MTOContextElement.langDepName = attribute.langDepName;
              MTOContextElement.description = attribute.description;
              MTOContextElement.attributeName = headerLabelStyler(group.name);
              MTOContextAttribute.valueList.push(MTOContextElement);
              MTOContextGroup.push(MTOContextAttribute);
              setters.synchronizeCart(attribute.domainValues[0].selected, {
                groupId: group.id,
                attributeKey: attribute.key,
                domainValueKey: attribute.name,
                name: attribute.langDepName,
                attributeName: headerLabelStyler(group.name),
                price: {
                  value: attribute.domainValues[0].price.value,
                  formattedValue:
                    attribute.domainValues[0].price.formattedValue,
                  currency: attribute.domainValues[0].price.currencyIso,
                },
                image: attribute?.image || defaultImg,
                description: attribute.description,
                selected: attribute.domainValues[0].selected,
              });
            });
            MTOContext.push(MTOContextGroup);
          }
        }
      });
      if (
        backendResponse?.bikeSection &&
        backendResponse?.accessorySection &&
        backendResponse?.apparelSection &&
        backendResponse?.overallSection
      ) {
        setters.updateTotalPrice({
          bikePriceFormatted: backendResponse.bikeSection.bikePriceFormatted,
          optionPriceFormatted:
            backendResponse.bikeSection.optionPriceFormatted,
          accessoryPriceFormatted:
            backendResponse.accessorySection.accessoryPriceFormatted,
          apparelPriceFormatted:
            backendResponse.apparelSection.apparelPriceFormatted,
          subTotalPriceFormatted:
            backendResponse.overallSection.subTotalPriceFormatted,
        });
        setters.updateQuotes(backendResponse);
      }

      if (!isMake) {
        if (resAccessories?.accessories?.length > 0) {
          navList.push({
            step: language.steps_information[
              "steps_information.name.aftermarket"
            ],
            position: navList.length + 1,
            group: AFTERMARKET_GROUP,
          });
        }
        if (resApparels?.length > 0) {
          navList.push({
            step: language.steps_information["cart_status.apparel.title.label"],
            position: navList.length + 1,
            group: APPARELS_GROUP,
          });
        }
      }
      setters.setHeaderMTO(navList);
      setters.setFetchInit(MTOContext);
      initializedContext.current = true;
    },
    [family, version]
  );

  const initAccessoryContent = (
    resAccessories,
    resAccEnrichment,
    accessoryItemTypeList
  ) => {
    setAccessoryContent(
      resAccessories,
      resAccEnrichment,
      accessoryItemTypeList
    );
  };

  const initApparelContent = (resApparels, apparelSection) => {
    if (resApparels) {
      const outfits = {};
      let allApparels = {};
      let highlighted = [];
      if (resApparels?.length > 0) {
        allApparels = buildApparelsContent(resApparels);
        highlighted = buildHighlightedOutfits(resApparels);
        setApparelsContent({ outfits, allApparels, highlighted });
        if (apparelSection?.apparelItemTypeList?.length > 0) {
          syncCartApparels(apparelSection?.apparelItemTypeList, allApparels);
        }
      }
    }
  };

  const syncCartAmounts = (resInitMTO) => {
    if (
      resInitMTO?.bikeSection &&
      resInitMTO?.accessorySection &&
      resInitMTO?.apparelSection &&
      resInitMTO?.overallSection
    ) {
      setters.updateTotalPrice({
        bikePriceFormatted: resInitMTO.bikeSection.bikePriceFormatted,
        optionPriceFormatted: resInitMTO.bikeSection.optionPriceFormatted,
        accessoryPriceFormatted:
          resInitMTO.accessorySection.accessoryPriceFormatted,
        apparelPriceFormatted: resInitMTO.apparelSection.apparelPriceFormatted,
        subTotalPriceFormatted:
          resInitMTO.overallSection.subTotalPriceFormatted,
      });
      setters.updateQuotes(resInitMTO);
    }
  };

  const initConfig = useCallback(async () => {
    if (family && supermodel && version) {
      const smInfo = getSuperModelInfo(family, supermodel);
      if (smInfo) {
        const { familyCode, matnr } = smInfo;
        const bikeEnrichment = await initBikeEnrichment(matnr);
        Promise.all([
          fetchInitMTO({
            country: language.country.toLowerCase(),
            language: language.language.toLowerCase(),
            productCode: matnr,
            version,
          }).then(
            (response) => {
              return response;
            },
            (error) => {
              error.caller = "useConfiguratorMTO - initConfig - fetchInitMTO";
              throw error;
            }
          ),
          fetchAccessoriesMTO({
            country: language.country.toLowerCase(),
            language: language.language.toLowerCase(),
            productCode: matnr,
            version,
          }).then(
            (response) => {
              return response;
            },
            (error) => {
              echoError({
                error,
                caller: "useConfiguratorMTO - initConfig - fetchAccessoriesMTO",
              });
              return undefined;
            }
          ),
          fetchAccessoriesEnrichmentMTO({
            country: language.country.toLowerCase(),
            language: language.language.toLowerCase(),
            productCode: matnr,
            version,
          }).then(
            (response) => {
              return response;
            },
            (error) => {
              echoError({
                error,
                caller:
                  "useConfiguratorMTO - initConfig - fetchAccessoriesEnrichmentMTO",
              });
              return undefined;
            }
          ),
          fetchApparelsMTO(
            language.country.toLowerCase(),
            language.language.toLowerCase(),
            familyCode
          ).then(
            (response) => {
              return response;
            },
            (error) => {
              echoError({
                error,
                caller: "useConfiguratorMTO - initConfig - fetchApparelsMTO",
              });
              return undefined;
            }
          ),
        ])
          .then((values) => {
            const resInitMTO = values[0];
            const resAccessories = values[1];
            const resAccEnrichment = values[2];
            const resApparels = values[3];
            updateMTOContext({
              backendResponse: resInitMTO,
              familyCode,
              productCode: matnr,
              resAccessories,
              resApparels,
              bikeEnrichment,
            });

            setSearchParams({ cid: resInitMTO.cid });

            /* ACCESSORIES */
            initAccessoryContent(
              resAccessories,
              resAccEnrichment,
              resInitMTO?.accessorySection?.accessoryItemTypeList
            );

            /* APPARELS */
            initApparelContent(resApparels, resInitMTO?.apparelSection);

            syncCartAmounts(resInitMTO);
          })
          .catch((error) => {
            onError({
              httpStatusCode: error.message,
              consoleError: error,
              caller: error.caller,
            });
          });
      }
    }
  }, [family, supermodel, version]);

  const getConfiguration = async (cid) => {
    const resInitMTO = await fetchRetrieveConfigMTO(
      language.country.toLowerCase(),
      language.language.toLowerCase(),
      cid
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        onError({
          httpStatusCode: error.message,
          consoleError: error,
          caller: "getConfiguration - fetchRetrieveConfigMTO",
        });
      });
    return resInitMTO;
  };

  const getAccessories = async (productCode, version) => {
    const resApparels = await fetchAccessoriesMTO({
      country: language.country.toLowerCase(),
      language: language.language.toLowerCase(),
      productCode,
      version,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        echoError({
          error,
          caller: "getAccessories - fetchAccessoriesMTO",
        });
        return undefined;
      });
    return resApparels;
  };

  const getAccEnrichmnet = async (productCode, version) => {
    const resAccEnrichment = await fetchAccessoriesEnrichmentMTO({
      country: language.country.toLowerCase(),
      language: language.language.toLowerCase(),
      productCode,
      version,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        echoError({
          error,
          caller: "getAccEnrichmnet - fetchAccessoriesEnrichmentMTO",
        });
        return undefined;
      });
    return resAccEnrichment;
  };

  const getApparels = async (productLine) => {
    const resApparels = await fetchApparelsMTO(
      language.country.toLowerCase(),
      language.language.toLowerCase(),
      productLine
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        echoError({
          error,
          caller: "getApparels - fetchApparelsMTO",
        });
        return undefined;
      });
    return resApparels;
  };

  
  const isMakeScenario = (backendResponse) => {
    const enableTake = searchParams.get("fromConfiguration") || false;
    const startedFromValue = backendResponse?.marketPlaceInitialSelection || "";
    const enableMake =
      startedFromValue.toLowerCase() !== startedFromType.ORDER &&
      startedFromValue.toLowerCase() !== startedFromType.STOCK &&
      !enableTake;
    setters.setEnableRedirectOnSave(enableMake);
    return enableMake;
  };
  

  const retrieveConfig = useCallback(
    async (cid) => {
      const resInitMTO = await getConfiguration(cid);
      if (resInitMTO?.modelData && resInitMTO?.bikeConfiguration) {
        const familyInfo = getFamilyInfo(family);
        const { rootProduct } = resInitMTO.bikeConfiguration;
        const bikeEnrichment = await initBikeEnrichment(rootProduct);

        let isMake = false;
        let isTake = false;
        
        isMake = isMakeScenario(resInitMTO);
        isTake = !isMake;
        
        const { model } = resInitMTO.modelData;

        let resAccessories;
        let resAccEnrichment;
        let resApparels;
        if (!isMake) {
          resAccessories = await getAccessories(rootProduct, model);
          resAccEnrichment = await getAccEnrichmnet(rootProduct, model);
          resApparels = await getApparels(familyInfo?.familyCode);
        }

        updateMTOContext({
          backendResponse: resInitMTO,
          familyCode: familyInfo?.familyCode,
          productCode: rootProduct,
          isMake,
          isTake,
          resAccessories,
          resApparels,
          bikeEnrichment,
        });

        if (!isMake) {
          initAccessoryContent(
            resAccessories,
            resAccEnrichment,
            resInitMTO?.accessorySection?.accessoryItemTypeList
          );
          initApparelContent(resApparels, resInitMTO?.apparelSection);
        }

        syncCartAmounts(resInitMTO);

        if (saveConfig) {
          fetchSaveOnMyDucatiMTO(
            language.country.toLowerCase(),
            language.language.toLowerCase(),
            cid
          )
            .then(() => {
              setSaveConfig(false);
              showSaveModal();
            })
            .catch((error) => {
              setSaveConfig(false);
              echoError({
                error,
                caller: "retrieveConfig - fetchSaveOnMyDucatiMTO",
              });
              showServiceModal(messageType.changingFail);
            });
        }
      }
    },
    [family]
  );

  const checkMTOConfig = useCallback(() => {
    const cid = searchParams.get("cid") || "";
    if (cid && cid.includes(CID_MTO_PREFIX)) {
      return true;
    }

    const vid = searchParams.get("vid") || "";
    if (vid && vid.includes(CID_MTO_PREFIX)) {
      return true;
    }
    
    return false;
  }, [family, supermodel, version]);

  const cloneConfig = useCallback(
    async (vid) => {
      if (family && supermodel && version) {
        const smInfo = getSuperModelInfo(family, supermodel);
        if (smInfo) {
          const { familyCode, matnr } = smInfo;
          const bikeEnrichment = await initBikeEnrichment(matnr);
          Promise.all([
            fetchInitMTO({
              country: language.country.toLowerCase(),
              language: language.language.toLowerCase(),
              productCode: matnr,
              version,
              vid,
            }).then(
              (response) => {
                return response;
              },
              (error) => {
                error.caller =
                  "useConfiguratorMTO - cloneConfig - fetchInitMTO";
                throw error;
              }
            ),
            fetchAccessoriesMTO({
              country: language.country.toLowerCase(),
              language: language.language.toLowerCase(),
              productCode: matnr,
              version,
            }).then(
              (response) => {
                return response;
              },
              (error) => {
                echoError({
                  error,
                  caller:
                    "useConfiguratorMTO - cloneConfig - fetchAccessoriesMTO",
                });
                return undefined;
              }
            ),
            fetchAccessoriesEnrichmentMTO({
              country: language.country.toLowerCase(),
              language: language.language.toLowerCase(),
              productCode: matnr,
              version,
            }).then(
              (response) => {
                return response;
              },
              (error) => {
                echoError({
                  error,
                  caller:
                    "useConfiguratorMTO - cloneConfig - fetchAccessoriesEnrichmentMTO",
                });
                return undefined;
              }
            ),
            fetchApparelsMTO(
              language.country.toLowerCase(),
              language.language.toLowerCase(),
              familyCode
            ).then(
              (response) => {
                return response;
              },
              (error) => {
                echoError({
                  error,
                  caller: "useConfiguratorMTO - cloneConfig - fetchApparelsMTO",
                });
                return undefined;
              }
            ),
          ])
            .then((values) => {
              const resInitMTO = values[0];
              const resAccessories = values[1];
              const resAccEnrichment = values[2];
              const resApparels = values[3];
              updateMTOContext({
                backendResponse: resInitMTO,
                familyCode,
                productCode: matnr,
                resAccessories,
                resApparels,
                bikeEnrichment,
              });

              /* ACCESSORIES */
              initAccessoryContent(
                resAccessories,
                resAccEnrichment,
                resInitMTO?.accessorySection?.accessoryItemTypeList
              );

              /* APPARELS */
              initApparelContent(resApparels, resInitMTO?.apparelSection);

              syncCartAmounts(resInitMTO);

              const pathname =
                "/bikes/" +
                language.country.toLowerCase() +
                "/" +
                language.language.toLowerCase() +
                "/" +
                family +
                "/" +
                supermodel +
                "/" +
                version +
                "/recap/editorial";

              navigate(pathname.concat("?vid=").concat(resInitMTO.vid));
            })
            .catch((error) => {
              onError({
                httpStatusCode: error.message,
                consoleError: error,
                caller: "useConfiguratorMTO - cloneConfig",
              });
            });
        }
      }
    },
    [family, supermodel, version]
  );

  return {
    searchParams,
    initConfig,
    retrieveConfig,
    checkMTOConfig,
    cloneConfig,
  };
}
