import "reflect-metadata";
import React, { Suspense, lazy, useMemo } from "react";
import { LoadingPageComponent } from "../../pages/loading-page/LoadingPage";
import { useConfiguratorMTO } from "../configuratorMTO/hooks/useConfiguratorMTO";
import { useLanguageContent } from "../../context/LanguageContext";
import { useParams } from "react-router-dom";

let ComponentMTO;
let ComponentMTS;


ComponentMTO = lazy(() => import("../../../b2b/pages/quotation/QuotationMTO"));
ComponentMTS = lazy(() => import("../../../b2b/pages/quotation/Quotation"));


function RecapProxy() {
  const { checkMTOConfig } = useConfiguratorMTO();
  const { language } = useLanguageContent();
  const { family, supermodel, version } = useParams();

  const isMTOCongif = useMemo(() => {
    return checkMTOConfig();
  }, [checkMTOConfig]);

  if (!family && !supermodel && !version) {
    return (
      <LoadingPageComponent
        title={language?.configurator_information["loading_page.title"]}
      />
    );
  }

  return (
    <Suspense
      fallback={
        <LoadingPageComponent
          title={language?.configurator_information["loading_page.title"]}
        />
      }
    >
      {isMTOCongif ? <ComponentMTO /> : <ComponentMTS />}
    </Suspense>
  );
}

export default RecapProxy;
