import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import AppB2B from "./b2b/AppB2B";


import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import importMHPModuls from "./common/components/configuratorMTO/registerSdkModules";

importMHPModuls();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    {
      
      <AppB2B />
      
    }
    {
      
    }
  </BrowserRouter>
);

reportWebVitals();
