import style from "./Footer.module.scss";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Text, useLanguageContent } from "../../context/LanguageContext";
import { endpointResolver } from "../../utils/EndpointResolver";
import { CartStickyContext } from "../../context/CartStickyContext";
import { useMobile } from "../../hooks/useMobile";
import { ErrorContext } from "../../context/ErrorContext";

function Footer() {
  const { language } = useLanguageContent();
  const isMobile = useMobile();
  const { setCartStickyContent } = useContext(CartStickyContext);
  const { loadingError } = useContext(ErrorContext);
  const footerRef = useRef(null);
  const [termsOfUse, setTermsOfUse] = useState("");
  const [policy, setPolicy] = useState("");
  const [cookiePolicy, setCookiePolicy] = useState("");
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const handleOrientationChange = (event) => {
      const isLandscape =
        event.target.screen.orientation.type.includes("landscape");
      setIsLandscape(isLandscape);
    };

    setIsLandscape(window.matchMedia("(orientation: landscape)").matches);

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const checkIfElementInViewport = () => {
    const element = footerRef.current;
    if (element) {
      const { top, bottom } = element.getBoundingClientRect();
      if (top !== 0 && bottom !== 0) {
        const isInViewport = top >= 0 && bottom <= window.innerHeight;
        const height = footerRef.current.clientHeight;
        setCartStickyContent({ isSticky: isInViewport, height });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkIfElementInViewport();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTermsOfUse(
      endpointResolver(
        process.env.REACT_APP_TERMS_OF_USE,
        language.language.toLowerCase()
      )
    );
    setPolicy(
      endpointResolver(
        process.env.REACT_APP_PRIVACY,
        language.country.toLowerCase(),
        language.language.toLowerCase()
      )
    );
    setCookiePolicy(
      endpointResolver(
        process.env.REACT_APP_COOKIE_POLICY,
        language.country.toLowerCase(),
        language.language.toLowerCase()
      )
    );
  }, [language?.country, language?.language]);

  function renderCopyRight(copyrightText) {
    const currentYear = new Date().getFullYear();
    let result = "";
    if (copyrightText) {
      result = copyrightText.replace("%s", currentYear);
      return result;
    }

    return copyrightText;
  }

  if (loadingError) return;
  return (
    <div
      className={` ${isLandscape && isMobile ? "d-none " : ""} `.concat(
        style.footer
      )}
      id="footer"
    >
      <div className="row justify-content-center mb-2">
        <img
          src="https://images.ctfassets.net/x7j9qwvpvr5s/2CsA99p11kL3UvzN4txafd/832dabdc0d00a62a43fe559933a46c35/Ducati-Member-of-the-Audi-Group.svg"
          alt="ducati memeber of the audi group"
          className={style["footer-img"]}
        />
      </div>
      <div
        className={`row justify-content-center ${style["links-container"]}`}
        ref={footerRef}
      >
        <div className="col-12 col-lg-2 d-lg-flex align-items-center justify-content-center">
          <a
            className={style["font-title"]}
            href={termsOfUse}
            target="_blank"
            rel="noreferrer"
          >
            <Text tid="configurator.edit.label_terms" />
          </a>
        </div>
        <div className="col-12 col-lg-2 d-lg-flex align-items-center justify-content-center ">
          <a
            className={style["font-title"]}
            href={policy}
            target="_blank"
            rel="noreferrer"
          >
            <Text tid="configurator.edit.label_privacy" />
          </a>
        </div>
        <div className="col-12 col-lg-2 d-lg-flex align-items-center justify-content-center ">
          <a
            className={style["font-title"]}
            href={cookiePolicy}
            target="_blank"
            rel="noreferrer"
          >
            <Text tid="configurator.edit.label_cookie_policy" />
          </a>
        </div>
      </div>
      <div
        className="row "
        style={{ justifyContent: "center", paddingTop: "20px" }}
      >
        <div className={"col-9 ".concat(style["font-descr"])}>
          <p className={style["font-descr"]}>
            {renderCopyRight(language.labels["footer.copyright_text"])}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
