import { useState, useEffect } from "react";
import { getInfoBikeFromUrl, isScrambler } from "../utils/Utils";

export function useScrambler() {
  const { family } = getInfoBikeFromUrl(window.location.pathname);
  const [scramblerStyle, setScramblerStyle] = useState(false);

  useEffect(() => {
    if (isScrambler(family)) setScramblerStyle(true);
    else setScramblerStyle(false);
  }, [family]);

  // Switchs font theme between scrambler fonts and ducati fonts
  function switchFonts(familyId) {
    const { documentElement } = document;
    if (
      (familyId && isScrambler(familyId.toLowerCase())) ||
      isScrambler(family)
    ) {
      documentElement.style.setProperty("--used-font", "scrambler-font");
      documentElement.style.setProperty("--font-regular", "scrambler-regular");
      documentElement.style.setProperty("--font-bold", "scrambler-bold");
      documentElement.style.setProperty(
        "--font-bold-extra",
        "scrambler-extra-bold"
      );
      documentElement.style.setProperty("--font-extended", "scrambler-ex");
      documentElement.style.setProperty(
        "--font-extended-bold",
        "scrambler-ex-bold"
      );
      documentElement.style.setProperty(
        "--font-condensed",
        "scrambler-condensed"
      );
      documentElement.style.setProperty(
        "--font-condensed-bold",
        "scrambler-condensed-bold"
      );
      documentElement.style.setProperty(
        "--font-condensed-bold-extra",
        "scrambler-condensed-bold"
      );
    } else {
      documentElement.style.setProperty("--used-font", "ducati-font");
      documentElement.style.setProperty("--font-regular", "ducati");
      documentElement.style.setProperty("--font-bold", "ducati-bold");
      documentElement.style.setProperty(
        "--font-bold-extra",
        "ducati-bold-extra"
      );
      documentElement.style.setProperty("--font-extended", "ducati-extended");
      documentElement.style.setProperty(
        "--font-extended-bold",
        "ducati-extended-bold"
      );
      documentElement.style.setProperty("--font-condensed", "ducati-condensed");
      documentElement.style.setProperty(
        "--font-condensed-bold",
        "ducati-condensed-bold"
      );
      documentElement.style.setProperty(
        "--font-condensed-bold-extra",
        "ducati-condensed-bold-extra"
      );
    }
  }

  return { scramblerStyle, switchFonts };
}
