import React, { Suspense, lazy, useMemo } from "react";
import { useConfiguratorMTO } from "../configuratorMTO/hooks/useConfiguratorMTO";
import { LoadingPageComponent } from "../../pages/loading-page/LoadingPage";
import { useLanguageContent } from "../../context/LanguageContext";
import { useParams, useSearchParams } from "react-router-dom";

const ComponentMTO = lazy(() => import("../configuratorMTO/ConfiguratorMTO"));
let ComponentMTS;
let isB2B = false;


const ComponentMTSReadOnly = lazy(() =>
  import("../../../b2b/pages/read-only/ConfigReadOnly")
);
ComponentMTS = lazy(() =>
  import("../../../b2b/pages/configurator/Configurator")
);
isB2B = true;


function ConfiguratorProxy() {
  const { checkMTOConfig } = useConfiguratorMTO();
  const { language } = useLanguageContent();
  const { family, supermodel, version } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const isMTOCongif = useMemo(() => {
    return checkMTOConfig();
  }, [checkMTOConfig]);

  if (!family && !supermodel && !version) {
    return (
      <LoadingPageComponent
        title={language?.configurator_information["loading_page.title"]}
      />
    );
  }

  const renderMTS = () => {
    const vid = searchParams.get("vid") || "";
    
    if (isB2B && vid.length > 0) {
      return <ComponentMTSReadOnly />;
    }
    
    return <ComponentMTS />;
  };

  return (
    <Suspense
      fallback={
        <LoadingPageComponent
          title={language?.configurator_information["loading_page.title"]}
        />
      }
    >
      {isMTOCongif ? <ComponentMTO /> : renderMTS()}
    </Suspense>
  );
}

export default ConfiguratorProxy;
