import React, { useState, createContext, useContext } from "react";

export const SidebarContext = createContext({
  sidebarContent: {},
});

export function SidebarProvider({ children }) {
  const [sidebarContent, setSidebarContent] = useState({ isSidebarOpen: true });

  const provider = {
    sidebarContent,
    setSidebarContent: (selected) => {
      const newSidebarContent = selected;
      setSidebarContent(newSidebarContent);
    },
  };

  return (
    <SidebarContext.Provider value={provider}>
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebarContent() {
  return useContext(SidebarContext);
}
