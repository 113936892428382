import React, { useContext, useEffect, useState } from "react";
import { FinancialContext } from "../../context/FinancialContext";
import { LanguageContext } from "../../context/LanguageContext";
import style from "./DisplayRate.module.scss";

// This component retrieve the rate value of specific motorbike total price or accessoryID from indexedDB and display it.
function DisplayRate({ bsStyle = "" }) {
  const { financialContent } = useContext(FinancialContext);
  const { language } = useContext(LanguageContext);
  const [rateValue, setRateValue] = useState("");

  // useeffect to observe the changes of indexedDB
  useEffect(() => {
    if (
      financialContent?.productId !== "" &&
      financialContent?.financialDetails?.totalMonthlyRate &&
      language?.configurator_information
    ) {
      setRateValue(
        `${financialContent.financialDetails.totalMonthlyRate} ${language.configurator_information["recapbar.messages.rate"]}`
      );
    } else {
      setRateValue("");
    }
  }, [
    financialContent?.financialDetails?.totalMonthlyRate,
    language?.configurator_information,
    financialContent?.productId,
  ]);

  return rateValue !== "" ? (
    <div
      data-component="display-rate-container"
      className={`${bsStyle || ""} ${style.font}`}
    >
      {rateValue}
    </div>
  ) : (
    ""
  );
}

export default DisplayRate;
