import React, { useState, createContext, useContext, useEffect } from "react";

export const ModalContext = createContext({
  modalContent: {},
});

export const DEFAULT_SMART_MODAL_STATE = {
  showSmartModal: false,
  accessory: undefined,
  onConfirm: undefined,
  isAftermarket: false,
  accessoryInfo: undefined,
};

export const DEFAULT_ACCESSORY_MODAL_STATE = {
  accessory: undefined,
  setValue: undefined,
  fatherId: "",
};

export const DEFAULT_RATE_MODAL_STATE = {
  productId: "",
  vehicle: undefined,
};

export const DEFAULT_SERVICE_MODAL_STATE = {
  showSericeModal: false,
  title: "",
  description: "",
};

export const DEFAULT_SAVEMYDUCATI_MODAL_STATE = {
  showSaveModal: false,
  hasError: false,
};

export const DEFAULT_CONFLICTS_MODAL = {
  showModal: false,
  title: "",
  message: "",
  name: "",
  optionList: [],
  conflictItem: undefined,
  isAccessory: false,
  isRemove: false,
};

export const DEFAULT_SAVEQUOTATION_MODAL_STATE = {
  showModal: false,
};

export function ModalProvider({ children }) {
  const [modalContent, setModalContent] = useState();
  const [apparelsToRemove, setApparelsToRemove] = useState([]);
  const [showRemoveApparelModal, setShowRemoveApparelModal] = useState(false);
  const [smartModal, setSmartModal] = useState(DEFAULT_SMART_MODAL_STATE);
  const [accessoryModal, setAccessoryModal] = useState(
    DEFAULT_ACCESSORY_MODAL_STATE
  );
  const [rateModal, setRateModal] = useState(DEFAULT_RATE_MODAL_STATE);
  const [serviceModal, setServiceModal] = useState(DEFAULT_SERVICE_MODAL_STATE);
  const [saveModal, setSaveModal] = useState(DEFAULT_SAVEMYDUCATI_MODAL_STATE);
  const [conflictsModal, setConflictsModal] = useState(DEFAULT_CONFLICTS_MODAL);
  const [saveQuotationModal, setSaveQuotationModal] = useState(
    DEFAULT_SAVEQUOTATION_MODAL_STATE
  );

  function cleanModalContext() {
    setModalContent({});
  }

  const provider = {
    modalContent,
    setModalContent: (selected) => {
      const newModalContent = selected;
      setModalContent(newModalContent);
    },
    apparelsToRemove,
    setApparelsToRemove,
    showRemoveApparelModal,
    setShowRemoveApparelModal,
    smartModal,
    setSmartModal,
    accessoryModal,
    setAccessoryModal,
    cleanModalContext,
    rateModal,
    setRateModal,
    serviceModal,
    setServiceModal,
    saveModal,
    setSaveModal,
    conflictsModal,
    setConflictsModal,
    saveQuotationModal,
    setSaveQuotationModal,
  };

  return (
    <ModalContext.Provider value={provider}>{children}</ModalContext.Provider>
  );
}

export function useModalContent() {
  return useContext(ModalContext);
}
