import "reflect-metadata";
import { Route, Routes } from "react-router-dom";
import React from "react";
import Header from "../../common/layouts/header/Header";
import styles from "./RouteDucati.module.scss";
import Footer from "../../common/layouts/footer/Footer";
import { Search } from "../../common/context/LanguageContext";
import LoadingPage from "../../common/pages/loading-page/LoadingPage";
import ConfiguratorProxy from "../../common/components/ConfiguratorProxy/ConfiguratorProxy";
import RecapProxy from "../../common/components/recap-proxy/RecapProxy";
import { useRouteDucati } from "./hooks/useRouteDucati";
import { useError } from "../../common/context/ErrorContext";
import ServicesPage from "../../common/pages/services-page/ServicesPage";

function RouteDucati() {
  const { isLoading } = useRouteDucati();
  const { errorState } = useError();

  function renderRouter() {
    if (errorState?.hasError) {
      return <ServicesPage />;
    }
    return (
      <div className={styles.initial}>
        <a className={styles["skip-link"]} href="#maincontent">
          <div className={styles["skip-link-font"]}>
            <Search did="general_labels" tid="labels.main_content" />
          </div>
        </a>
        <Header />
        <div className={styles.bodyContent} id="maincontent">
          <Routes>
            <Route
              path="/bikes/:country/:language/:family/:supermodel/:version"
              element={<ConfiguratorProxy />}
            />
            <Route
              path="/bikes/:country/:language/:family/:supermodel/:version/recap"
              element={<RecapProxy />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    );
  }

  function renderContent() {
    return isLoading ? <LoadingPage /> : renderRouter();
  }

  return renderContent();
}

export default RouteDucati;
