export const retrieveBannerResources = (language) => {
  return import(
    /* webpackPreload: true */
    `@public/editorial-contents/${language}/banner-editorial-content.json`
  );
};

export const retrieveAdditionalInfoResources = (language) => {
  return import(
    /* webpackPreload: true */
    `@public/editorial-contents/${language}/additional-information.json`
  );
};

export const retrieveNavItemList = (language) => {
  return import(
    /* webpackPreload: true */
    `@public/editorial-contents/${language}/init-configuration.json`
  );
};

export const retrieveApprovalModalResources = (language) => {
  return import(
    /* webpackPreload: true */
    `@public/editorial-contents/${language}/approval-modal-content.json`
  );
};

// TODO: Detla prices, to be removed when BE is updated
export const retrievePreconfigurationDeltaPrices = (environment) => {
  return import(
    /* webpackPreload: true */
    `@public/delta-price/${environment}/delta-price-content.json`
  );
};
