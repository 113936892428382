import { eventName, pageTags } from "../DataLayerContext";
import {
  checkArgs,
  isLogged,
  resolveFamilyName,
} from "../../../utils/DataLayer";
import { isEmptyObject } from "../../../utils/Utils";

export function buildWBCSaveRecapEvent({
  familyName = "",
  modelName = "",
  equipmentName = "",
  items = [],
  totalConfigurationValue = 0,
  supermodelName = "",
  currency = "",
  
  cid = "",
  
}) {
  let wbcSaveRecapEvent = {};
  const realFamilyName = resolveFamilyName(familyName);
  if (
    checkArgs([
      realFamilyName,
      modelName ,
      cid ,
    ]) &&
    totalConfigurationValue > 0
  ) {
    const login = isLogged();
    const fixedPrice = totalConfigurationValue.toFixed(2);
    wbcSaveRecapEvent = {
      event: eventName.configurationSaved,
      pageTags: pageTags.configurator,
      login,
      eventCategory: "Save/Retrieve",
      eventAction: "Save configuration from recap",
      value: fixedPrice.toString(),
      eventLabel: modelName,
      items,
      DL_familyName: realFamilyName,
      DL_supermodelName: supermodelName,
      DL_modelName: modelName,
      DL_equipmentName: equipmentName,
      currency,
      
      transaction_id: cid,
      
    };
  }
  return wbcSaveRecapEvent;
}


export function buildWBCSaveRecapEventB2B({
  familyName = "",
  modelName = "",
  equipmentName = "",
  items = [],
  testRideVoice = "false",
  barterDataVoice = false,
  totalDiscount = 0,
  finalDiscount = 0,
  motoDiscount = 0,
  apparelDiscount = 0,
  accessoriesDiscount = 0,
  financingVoice = false,
  totalConfigurationValue = 0,
  supermodelName = "",
  cid = "",
  currency = "",
}) {
  const wbcSaveRecapEvent = buildWBCSaveRecapEvent({
    familyName,
    modelName,
    equipmentName,
    items,
    totalConfigurationValue,
    supermodelName,
    cid,
    currency,
  });

  if (!isEmptyObject(wbcSaveRecapEvent)) {
    wbcSaveRecapEvent.testRideVoice = testRideVoice;
    wbcSaveRecapEvent.barterDataVoice = barterDataVoice;
    wbcSaveRecapEvent.total_discount = totalDiscount;
    wbcSaveRecapEvent.moto_discount = motoDiscount;
    wbcSaveRecapEvent.final_discount = finalDiscount;
    wbcSaveRecapEvent.apparel_discount = apparelDiscount;
    wbcSaveRecapEvent.accessories_discount = accessoriesDiscount;
    wbcSaveRecapEvent.financingVoice = financingVoice;
  }

  return wbcSaveRecapEvent;
}
export function buildadditionalVoiceList({ dealerQuotationsVoices = [] }) {
  const additionalVoiceList = [];

  if (dealerQuotationsVoices?.length > 0) {
    for (const voice of dealerQuotationsVoices) {
      additionalVoiceList.push({
        item_id: voice.id || "",
        item_name: voice.type,
        price: voice.value ? voice.value.toString() : "",
        item_category: "additional_voice",
        quantity: "1",
      });
    }
  }
  return additionalVoiceList;
}

