// Simple implementations of the popular cn library that let you use
// Class names dynamically without messing the code
function cn(...items) {
  const className = [];

  for (const item of items) {
    if (typeof item === "string") className.push(item);
    else if (typeof item === "object") className.push(...handleObject(item));
  }

  return className.join(" ");
}

function handleObject(objectItem) {
  const className = [];

  const iteration = Array.isArray(objectItem)
    ? objectItem
    : Object.keys(objectItem);
  for (const _element of iteration) {
    const element = Array.isArray(objectItem) ? _element : objectItem[_element];
    if (typeof element === "string") className.push(element);
    if (typeof element === "boolean" && element) className.push(_element);
    if (typeof element === "object")
      className.push(...handleObject(objectItem));
  }

  return className;
}

export { cn };
