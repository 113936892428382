export const STANDARD_SIZE = 1920;

// Mobile
export const WIDTH_XSMALL_MIN = 320;
export const WIDTH_XSMALL_MEDIUM = 350;
export const WIDTH_XSMALL_MAX = 480;

// Tablet
export const WIDTH_MEDIUM_MIN = WIDTH_XSMALL_MAX + 1;
export const WIDTH_MEDIUM_MAX = 1024;

// Desktop
export const WIDTH_LARGE_MIN = WIDTH_MEDIUM_MAX + 1;
export const WIDTH_LARGE_MAX = 1200;
export const WIDTH_LARGE_MAX_COLORE = 1500;
export const WIDTH_EXTRALARGE_MIN = WIDTH_LARGE_MAX + 1;
export const WIDTH_EXTRALARGE_MIN_COLORE = WIDTH_LARGE_MAX_COLORE + 1;

export const THUMBNAIL_DESKTOP_SIZE = 120;
export const THUMBNAIL_DESKTOP_SIZE_COLORE = 60;
export const THUMBNAIL_MOBILE_SIZE = 96;
export const THUMBNAIL_XXSMALL_SIZE = 80;

export function resizeImage(url, resize) {
  if (url) {
    if (url.search("https://") < 0) {
      url = "https://".concat(url);
    }
    const hasStandardsize = url.search(`resize=${STANDARD_SIZE}`) > 0;
    if (hasStandardsize) {
      return url.replace(`${STANDARD_SIZE}`, `${resize}`);
    }
    return url.concat(`?resize=${resize}:*`);
  }
}

export function isDesktopView() {
  return window.innerWidth >= WIDTH_LARGE_MIN;
}

export const operatorType = {
  plus: "plus",
  minus: "minus",
  times: "times",
  division: "division",
};

export function calcWidth(width, step, operator) {
  if (operator === operatorType.plus) {
    return parseInt(width, 10) + parseInt(step, 10);
  } else if (operator === operatorType.minus) {
    return parseInt(width, 10) - parseInt(step, 10);
  } else if (operator === operatorType.times) {
    return parseInt(width, 10) * parseInt(step, 10);
  } else if (operator === operatorType.division) {
    return parseInt(width, 10) / parseInt(step, 10);
  }
}

export function resizeImageByCalc(url, resize, step, operator) {
  const newSize = calcWidth(resize, step, operator);
  return resizeImage(url, newSize);
}
