import React, { useState, createContext, useEffect, useContext } from "react";
import { discountType } from "../components/discount-section/DiscountSection";

export const INIT_RQ_QUOTATION_BODY = {
  discount_type: "PERCENTAGE",
  discount_value: null,
  dealer_quotations: [],
  dealer_discounts: [],
  returned_bike: null,
  loan: null,
  dealer_products_details: [],
  bike_section_discount_value: null,
  bike_section_discount_type: null,
  accessories_section_discount_value: null,
  accessories_section_discount_type: null,
  apparels_section_discount_value: null,
  apparels_section_discount_type: null,
};

export const INIT_RQ_PRICE_BODY = {
  discount_type: "PERCENTAGE",
  discount_value: null,
  dealer_quotations: [],
  dealer_discounts: [],
  returned_bike: null,
  loan: null,
  dealer_products_details: [],
  bike_discount_value: null,
  bike_discount_type: null,
  accessories_discount_value: null,
  accessories_discount_type: null,
  apparels_discount_value: null,
  apparels_discount_type: null,
};

export const QuotationContext = createContext({
  quotationData: {},
});

const localStateQuotation = JSON.parse(
  localStorage.getItem("quotationcontent")
);

export function QuotationProvider({ children, props }) {
  const [quotationData, setQuotationData] = useState(
    props || localStateQuotation
  );

  useEffect(() => {
    localStorage.setItem("quotationcontent", JSON.stringify(quotationData));
  }, [quotationData]);

  function createBodyQuotation({
    dealerProductsDetails = undefined,
    discountSection = undefined,
    dealerQuotations = undefined,
    returnedBike,
    loan,
    isSave = false,
  }) {
    const newBody = isSave ? INIT_RQ_PRICE_BODY : INIT_RQ_QUOTATION_BODY;

    if (discountSection?.type === discountType.recapSection) {
      newBody.discount_value = discountSection.dsValue;
      newBody.discount_type = discountSection.dsType;
    } else if (
      quotationData?.data?.quotes?.total?.discount_type !== null &&
      quotationData?.data?.quotes?.total?.discount_value !== null
    ) {
      newBody.discount_value = quotationData.data.quotes.total.discount_value;
      newBody.discount_type = quotationData.data.quotes.total.discount_type;
    }

    if (dealerQuotations?.length >= 0) {
      newBody.dealer_quotations = dealerQuotations;
    } else if (quotationData?.data?.dealer_quotations?.length > 0) {
      newBody.dealer_quotations = quotationData.data.dealer_quotations;
    }

    if (returnedBike === null) {
      newBody.returned_bike = null;
    } else if (returnedBike) {
      newBody.returned_bike = returnedBike;
    } else if (quotationData?.data?.returned_bike) {
      newBody.returned_bike = quotationData.data.returned_bike;
    } else {
      newBody.returned_bike = null;
    }

    if (loan === null) {
      newBody.loan = null;
    } else if (loan) {
      newBody.loan = loan;
    } else if (quotationData?.data?.loan) {
      newBody.loan = quotationData.data.loan;
    } else {
      newBody.loan = null;
    }

    if (dealerProductsDetails?.length >= 0) {
      newBody.dealer_products_details = dealerProductsDetails;
    } else if (quotationData?.data?.dealer_products_details) {
      newBody.dealer_products_details =
        quotationData.data.dealer_products_details;
    }

    if (discountSection?.type === discountType.bikeSection) {
      newBody.bike_section_discount_value = discountSection.dsValue;
      newBody.bike_section_discount_type = discountSection.dsType;
    } else if (
      quotationData?.data?.quotes?.base_price?.discount_type !== null &&
      quotationData?.data?.quotes?.base_price?.discount_value !== null
    ) {
      if (isSave) {
        newBody.bike_discount_value =
          quotationData.data.quotes.base_price.discount_value;
        newBody.bike_discount_type =
          quotationData.data.quotes.base_price.discount_type;
      } else {
        newBody.bike_section_discount_value =
          quotationData.data.quotes.base_price.discount_value;
        newBody.bike_section_discount_type =
          quotationData.data.quotes.base_price.discount_type;
      }
    }

    if (discountSection?.type === discountType.accessoriesSection) {
      newBody.accessories_section_discount_value = discountSection.dsValue;
      newBody.accessories_section_discount_type = discountSection.dsType;
    } else if (
      quotationData?.data?.quotes?.accessories_section?.discount_type !==
        null &&
      quotationData?.data?.quotes?.accessories_section?.discount_value !== null
    ) {
      if (isSave) {
        newBody.accessories_discount_value =
          quotationData.data.quotes.accessories_section.discount_value;
        newBody.accessories_discount_type =
          quotationData.data.quotes.accessories_section.discount_type;
      } else {
        newBody.accessories_section_discount_value =
          quotationData.data.quotes.accessories_section.discount_value;
        newBody.accessories_section_discount_type =
          quotationData.data.quotes.accessories_section.discount_type;
      }
    }

    if (discountSection?.type === discountType.apparelsSection) {
      newBody.apparels_section_discount_value = discountSection.dsValue;
      newBody.apparels_section_discount_type = discountSection.dsType;
    } else if (
      quotationData?.data?.quotes?.apparels_section?.discount_type !== null &&
      quotationData?.data?.quotes?.apparels_section?.discount_value !== null
    ) {
      if (isSave) {
        newBody.apparels_discount_value =
          quotationData.data.quotes.apparels_section.discount_value;
        newBody.apparels_discount_type =
          quotationData.data.quotes.apparels_section.discount_type;
      } else {
        newBody.apparels_section_discount_value =
          quotationData.data.quotes.apparels_section.discount_value;
        newBody.apparels_section_discount_type =
          quotationData.data.quotes.apparels_section.discount_type;
      }
    }

    return newBody;
  }

  const provider = {
    quotationData,
    setQuotationData: (selected) => {
      const newQuotationData = selected;
      setQuotationData(newQuotationData);
    },
    createBodyQuotation,
  };

  return (
    <QuotationContext.Provider value={provider}>
      {children}
    </QuotationContext.Provider>
  );
}

export function useQuotationData() {
  return useContext(QuotationContext);
}
