import { steps } from "../context/data-layer/DataLayerContext";
import { hasToken } from "./Auth";
import { isEmptyObject } from "./Utils";
import { APPARELS_GROUP } from "./Apparels";
import { getFamilyList } from "../context/FamilyMTOContext";

export function isLogged() {
  const isAuthorizedKey = hasToken();
  return isAuthorizedKey ? "Yes" : "No";
}

export function buildItem({
  panelVariantElementID = "",
  panelVariantElement = "",
  totalPrice = 0,
  stepName = "",
  subCategoryName = "",
  panelQuantity = "",
}) {
  const fixedPrice =
    totalPrice && totalPrice !== 0
      ? Number.parseFloat(totalPrice).toFixed(2)
      : "0";
  const item = {
    item_id: panelVariantElementID,
    item_name: panelVariantElement,
    price: fixedPrice.toString(),
    item_category: stepName,
    item_category2: "",
  };
  if (subCategoryName !== "") {
    item.item_category2 = subCategoryName;
  }
  if (panelQuantity !== "") {
    item.quantity = panelQuantity?.toString();
  }
  return item;
}

export function buildBikeItems({
  bikeName = "",
  bikeId = "",
  bikePrice = "",
  bikeBrand = "",
  itemCategory = "",
  familyName = "",
  quantity = "",
}) {
  const item = {
    item_name: bikeName,
    item_id: bikeId,
    price: bikePrice,
    item_brand: bikeBrand,
    item_category: itemCategory,
    item_category2: familyName,
    quantity,
  };
  if (bikePrice) {
    item.price = bikePrice?.toString();
  }
  return item;
}

export function buildViewListItems(bikeList, brand, category, quantity) {
  const items = [];
  bikeList.forEach((bike, index) => {
    if (
      checkArgs([
        bike?.model,
        bike?.id,
        bike?.model_data?.bike_price?.unformatted_amount,
        brand,
        category,
        bike.family,
        category,
        quantity,
      ])
    ) {
      const item = {
        item_name: bike.model || "",
        item_id: bike.id || "",
        price: bike.model_data.bike_price.unformatted_amount?.toFixed(2) || "",
        item_brand: brand,
        item_category: category,
        item_category2: resolveFamilyName(bike.family),
        item_list_name: category,
        index,
        quantity,
      };
      items.push(item);
    }
  });

  return items;
}

export function buildColorItems(colorList) {
  const items = [];
  colorList?.forEach((element) => {
    if (element?.color) {
      const item = buildItem({
        panelVariantElementID: element.id,
        panelVariantElement: element.color.name,
        totalPrice: element.color.price || "",
        stepName: steps.color.name,
      });
      items.push(item);
    }
  });
  return items;
}

export function buildAccessoryItems(bundlecontentMap) {
  const items = [];
  for (const accessoryId in bundlecontentMap) {
    if (accessoryId) {
      const accessory = bundlecontentMap[accessoryId];
      if (
        accessory?.id &&
        accessory?.name &&
        (accessory?.father_id === null || accessory?.father_id === undefined)
      ) {
        const item = buildItem({
          panelVariantElementID: accessory.id,
          panelVariantElement: accessory.name,
          totalPrice: accessory?.price?.unformatted_amount || "",
          stepName: steps.accessories.name,
          subCategoryName: accessory.element,
        });
        items.push(item);
      }
    }
  }
  return items;
}

export function buildApparelItems(highlightedList) {
  const items = [];
  highlightedList?.forEach((element) => {
    if (element?.id && element?.name) {
      const item = buildItem({
        panelVariantElementID: element.id,
        panelVariantElement: element.name,
        totalPrice: element?.unformattedPrice || "",
        stepName: steps.apparel.name,
      });
      items.push(item);
    }
  });
  return items;
}

function buildAccessoryItemsFromCart(list) {
  const items = [];
  for (const category in list) {
    if (category) {
      for (const accessoryId in list[category]) {
        if (accessoryId && list[category][accessoryId]) {
          const accessory = list[category][accessoryId];
          const item = buildItem({
            panelVariantElementID: accessory.codice,
            panelVariantElement: accessory.defaultName || accessory.name,
            totalPrice: accessory?.unformattedAmount || "",
            stepName: steps.accessories.name,
            panelQuantity: accessory.quontity || "1",
            subCategoryName: category,
          });
          items.push(item);
        }
      }
    }
  }
  return items;
}

function buildApparelItemsFromCart(list) {
  const items = [];
  for (const category in list) {
    if (category) {
      for (const accessoryId in list[category]) {
        if (accessoryId && list[category][accessoryId]) {
          const accessory = list[category][accessoryId];
          const item = buildItem({
            panelVariantElementID: accessory.id,
            panelVariantElement: accessory.name,
            totalPrice: accessory?.price?.unformatted_amount || "",
            stepName: steps.apparel.name,
            panelQuantity: accessory.quontity || "1",
            subCategoryName: category,
          });
          items.push(item);
        }
      }
    }
  }
  return items;
}

export function buildItemsFromCart(carrelloContent) {
  let items = [];
  if (carrelloContent?.listElementCarrello?.length > 0) {
    carrelloContent.listElementCarrello.forEach((element) => {
      if (element?.gruppo === "Colore") {
        
      } else if (
        element?.gruppo === "Aftermarket" &&
        element?.list &&
        !isEmptyObject(element?.list)
      ) {
        const accessoryList = buildAccessoryItemsFromCart(element.list);
        items = items.concat(accessoryList);
      } else if (
        element?.gruppo === APPARELS_GROUP &&
        element?.list &&
        !isEmptyObject(element?.list)
      ) {
        const apparelList = buildApparelItemsFromCart(element.list);
        items = items.concat(apparelList);
      }
    });
  }
  return items;
}

export function checkArgs(args) {
  let result = false;
  if (args?.length > 0) {
    const filtered = args.filter((attribute) => attribute !== "");
    result = args.length === filtered.length;
  }
  return result;
}

export function resolveFamilyName(idFamilyName) {
  let realName = "";
  const familyList = getFamilyList();
  if (familyList?.length > 0 && idFamilyName !== "") {
    const family = familyList.find((item) => item.id === idFamilyName);
    if (family?.name) {
      realName = family.name.toLowerCase();
    } else {
      realName = idFamilyName;
    }
  } else {
    realName = idFamilyName;
  }
  return realName;
}
