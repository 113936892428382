import { getUserId, hasToken } from "../../../utils/Auth";
import { eventName } from "../DataLayerContext";

export function buildUserInfoEvent(authService) {
  let userInfoEvent = {};

  if (hasToken()) {
    const userId = getUserId(authService);
    let userType = "";
    
    userType = "dealer";
    
    
    userInfoEvent = {
      event: eventName.userInfo,
      user_id: userId,
      user_type: userType,
    };
  } else {
    userInfoEvent = {
      event: eventName.userInfo,
      user_id: undefined,
      user_type: undefined,
    };
  }

  return userInfoEvent;
}
