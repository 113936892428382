import React, { useState, createContext, useEffect } from "react";

export const ChangeConfigContext = createContext({
  changeConfig: {},
});

const localStateChangeConfig = JSON.parse(
  localStorage.getItem("changeconfigcontent")
);

export function ChangeConfigProvider({ children, props }) {
  const [changeConfig, setChangeConfig] = useState(
    props || localStateChangeConfig
  );

  useEffect(() => {
    localStorage.setItem("changeconfigcontent", JSON.stringify(changeConfig));
  }, [changeConfig]);

  const provider = {
    changeConfig,
    setChangeConfig: (selected) => {
      setChangeConfig(selected);
    },
  };

  return (
    <ChangeConfigContext.Provider value={provider}>
      {children}
    </ChangeConfigContext.Provider>
  );
}
