import React from "react";
import style from "./DiscountSection.module.scss";
import DisplayRate from "../../../common/components/financial/DisplayRate";
import { useQuotationStatus } from "../quotation-status/QuotationStatus";
import { Search } from "../../../common/context/LanguageContext";
import { isDesktopView } from "../../../common/utils/Sizing";
import { cn } from "../../../common/utils/cn";
import { DiscountForm } from "./DiscountForm";

export const dsStyleType = {
  transparent: style.transparent,
  gray: style.gray,
  black: style.black,
};

export const discountType = {
  bikeSection: "bikeSection",
  accessoriesSection: "accessoriesSection",
  apparelsSection: "apparelsSection",
  recapSection: "recapSection",
};

export const sectionMap = {
  bikeSection: {
    valueLabel: "bike_section_discount_value",
    typeLabel: "bike_section_discount_type",
    responseLabel: "base_price",
  },
  accessoriesSection: {
    valueLabel: "accessories_section_discount_value",
    typeLabel: "accessories_section_discount_type",
    responseLabel: "accessories_section",
  },
  apparelsSection: {
    valueLabel: "apparels_section_discount_value",
    typeLabel: "apparels_section_discount_type",
    responseLabel: "apparels_section",
  },
  recapSection: {
    valueLabel: "discount_value",
    typeLabel: "discount_type",
    responseLabel: "total",
  },
};

function DiscountAmount({
  displayPrevious = false,
  type = "",
  amount = "",
  discountAmount = "",
  sign = "",
}) {
  return (
    <div
      data-component={`discount-amount-${type}-container`}
      className="mx-0 px-0"
    >
      {displayPrevious ? (
        <div data-component={`amount-${type}-content`} className="mx-0 px-0">
          <p className={style.amount}>
            {discountAmount !== "" ? discountAmount : amount}
          </p>
        </div>
      ) : discountAmount !== "" ? (
        <div
          data-component={`discounted-amount-${type}-content`}
          className="mx-0 px-0"
        >
          <p className={cn("mb-0", style.amount)}>{discountAmount}</p>
          <p className={style.banned}>{amount}</p>
        </div>
      ) : (
        <div data-component={`amount-${type}-content`} className="mx-0 px-0">
          <p className={style.amount}>{`${sign} ${amount}`}</p>
        </div>
      )}
    </div>
  );
}

export function DiscountSection({
  title = "",
  subtitle = "",
  amount = "",
  discountAmount = "",
  type = "",
  dsStyle = dsStyleType.gray,
  isEditable = false,
  displayRate = false,
  displayPrevious = false,
  sign = "",
}) {
  const { bikeSku } = useQuotationStatus();

  function renderPrev() {
    return (
      amount !== discountAmount && (
        <div
          data-component="discount-section-previous"
          className={`w-auto mx-0 px-0 `}
        >
          {isDesktopView() ? (
            <div className="d-flex flex-column">
              <p className={`w-auto mx-0 mt-3 mb-0 px-0 ${style.subtitle2}`}>
                <Search
                  did="b2b_recap_information"
                  tid="recap.quotation_widget.previous_price.label"
                />
              </p>
              <p className={`w-auto mx-0 mb-3 px-0 ${style.subtitle2}`}>
                {` ${amount}`}
              </p>
            </div>
          ) : (
            <p className={`w-auto mx-0 mt-3 mb-3 px-0 ${style.subtitle2}`}>
              <Search
                did="b2b_recap_information"
                tid="recap.quotation_widget.previous_price.label"
              />
              {` ${amount}`}
            </p>
          )}
        </div>
      )
    );
  }

  return (
    amount !== "" && (
      <div
        data-component="discount-section-container"
        className={cn(
          "container-fluid d-flex flex-column",
          ` mx-0 mb-5 pt-3 ${dsStyle}`
        )}
      >
        <div
          data-component="discount-section-info"
          className={`d-flex ${
            isDesktopView()
              ? "justify-content-between"
              : "flex-column justify-content-center px-0"
          }`}
        >
          <div
            data-component="discount-section-left"
            className={`mx-0 px-0 ${
              isDesktopView() ? "d-flex flex-column align-items-start" : ""
            }`}
          >
            <p
              className={`${
                subtitle !== ""
                  ? `mb-0 ${isDesktopView() ? "text-start" : "text-center"}`
                  : ""
              } ${style.title}`}
            >
              {title}
            </p>
            {subtitle !== "" && <p className={style.subtitle}>{subtitle}</p>}
          </div>
          <div data-component="discount-section-right" className="mx-0 px-0">
            <DiscountAmount
              displayPrevious={displayPrevious}
              type={type}
              amount={amount}
              discountAmount={discountAmount}
              sign={sign}
            />
            {displayRate && bikeSku && (
              <DisplayRate bsStyle={`text-center ${style.subtitle2}`} />
            )}
            {displayPrevious && discountAmount !== "" && renderPrev()}
          </div>
        </div>
        {isEditable && (
          <div
            data-component="discount-section-discount"
            className="mx-0 px-0 mb-3"
          >
            <DiscountForm title={title} type={type} />
          </div>
        )}
      </div>
    )
  );
}
