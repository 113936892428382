import {
  endpointResolver,
  retrieveTemplate,
  templateKeyMap,
} from "../utils/EndpointResolver";
import PropTypes from "prop-types";
import { useAuth } from "react-oauth2-pkce";
import { dealerDataFromJWT, userDataFromJWT } from "../utils/Auth";
import {
  contentType,
  accept,
  getBody,
  handleResponse,
  buildHeader,
  addQueryParamsToRequestUrl,
  contentTypeText,
  acceptText,
  fetchAggiungiAftermarket,
  fetchRimuoviAftermarket,
} from "./DucatiService";
import { isEmptyObject } from "../utils/Utils";
import { useSaveRecapLogin } from "../context/SaveRecapLoginContext";
import { useError } from "../context/ErrorContext";

export function useDucatiServiceMTO() {
  const { authService } = useAuth();
  const { saveConfig } = useSaveRecapLogin();
  const { echoError } = useError();

  const fetchInitMTO = ({ country, language, productCode, version, vid }) => {
    const template = retrieveTemplate(templateKeyMap.mtoInit);
    let url = endpointResolver(template, country, language);
    if (vid) {
      const urlParams = `&vid=${vid}`;
      url = url.concat(urlParams);
    }
    const body = {
      productCode,
      model: version.toUpperCase(),
    };
    const userData = userDataFromJWT(authService);
    if (!isEmptyObject(userData)) {
      body.accountId = userData.accountId;
    }
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "POST",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const patchMTO = (
    country,
    language,
    cid,
    configId,
    productCode,
    attributeKey,
    domainValueKey,
    groupId
  ) => {
    const template = retrieveTemplate(templateKeyMap.mtoPatch);
    const body = {
      productCode,
      attributeKey,
      configId,
      domainValueKey,
      groupId,
    };
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchRetrieveConfigMTO = (country, language, cid) => {
    const template = retrieveTemplate(templateKeyMap.retrieveConfig);
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "GET",
      headers: Object.assign({}, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchSaveOnMyDucatiMTO = async (country, language, cid) => {
    if (saveConfig) {
      const userData = userDataFromJWT(authService);
      await fetchSaveAccountMTO(country, language, cid, userData.accountId)
        .then((response) => {
          console.debug("Account Saved", response);
        })
        .catch((error) => {
          echoError({
            error,
            caller: "useDucatiServiceMTO - fetchSaveAccountMTO",
          });
        });
    }
    const template = retrieveTemplate(templateKeyMap.mtoSaveOnMyDucati);
    const data = dealerDataFromJWT(authService);
    const url = endpointResolver(template, country, language, cid);
    const options = {
      method: "POST",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchSendToEmailMTO = (cid, email) => {
    const template = retrieveTemplate(templateKeyMap.mtoSendToEmail);
    const url = endpointResolver(template, cid);
    const body = {
      cid,
      email,
    };

    const options = {
      method: "POST",
      headers: contentType,
      body: JSON.stringify(body),
    };

    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchSaveAccountMTO = (country, language, cid, accountId) => {
    const template = retrieveTemplate(templateKeyMap.mtoSaveAccount);
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign(
        {},
        contentTypeText,
        acceptText,
        buildHeader(data, true)
      ),
      body: accountId,
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchAddApparelMTO = ({ country, language, cid, apparel }) => {
    const template = retrieveTemplate(templateKeyMap.mtoAddApparel);
    const body = { ...apparel };
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };
  fetchAddApparelMTO.propTypes = {
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cid: PropTypes.string.isRequired,
    apparel: PropTypes.shape({
      sku: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
      type: PropTypes.string,
      currency: PropTypes.string,
      formattedAmount: PropTypes.string,
      unformattedAmount: PropTypes.number,
      financeAmount: PropTypes.string,
      financeAmountUnformatted: PropTypes.number,
      image: PropTypes.string,
    }).isRequired,
  };

  const fetchRemoveApparelMTO = (country, language, cid, sku) => {
    const template = retrieveTemplate(templateKeyMap.mtoDeleteApparel);
    const url = endpointResolver(template, country, language, cid, sku);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "DELETE",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchApparelsMTO = (country, language, productLine) => {
    const template = retrieveTemplate(templateKeyMap.mtoApparels);
    const url = endpointResolver(template, country, language, productLine);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "GET",
      headers: Object.assign({}, buildHeader(data)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchApparelDetailsMTO = (
    country,
    language,
    productLine,
    apparelId
  ) => {
    const template = retrieveTemplate(templateKeyMap.mtoApparelDetails);
    const url = endpointResolver(
      template,
      country,
      language,
      productLine,
      apparelId
    );
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "GET",
      headers: Object.assign({}, buildHeader(data)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchHierarchyMTO = () => {
    const url = retrieveTemplate(templateKeyMap.mtoHierarchy);
    const options = {
      method: "GET",
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchEnrichmentMTO = (country, language, productCode) => {
    const template = retrieveTemplate(templateKeyMap.mtoEnrichment);
    const url = endpointResolver(template, country, language, productCode);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "GET",
      headers: Object.assign({}, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchAccessoriesMTO = ({ country, language, productCode, version }) => {
    const template = retrieveTemplate(templateKeyMap.mtoAccessories);
    const model = version.toUpperCase();
    const url = endpointResolver(
      template,
      country,
      language,
      productCode,
      model
    );
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "GET",
      headers: Object.assign({}, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchAccessoriesEnrichmentMTO = ({
    country,
    language,
    productCode,
    version,
  }) => {
    const template = retrieveTemplate(templateKeyMap.mtoAccessoriesEnrichment);
    const queryParam = {
      model: version.toUpperCase(),
      productCode,
    };
    const url =
      endpointResolver(template, country, language) +
      addQueryParamsToRequestUrl(queryParam);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "GET",
      headers: Object.assign({}, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchUpdateAccessoryMTO = ({
    country,
    language,
    cid,
    productCode,
    version,
    zcol,
    zcolRuote,
    accessoryItem,
    isRemove = false,
    addToCartOnly = false,
  }) => {
    const template = retrieveTemplate(templateKeyMap.mtoUpdateAccessory);
    const url = endpointResolver(template, country, language, cid);
    const body = {
      productCode,
      model: version.toUpperCase(),
      zcol,
      zcolRuote,
      country,
      accessoryItem,
      addToCartOnly,
    };
    // if (addToCartOnly) {
    //   body.addToCartOnly = addToCartOnly;
    // }
    accessoryItem.retractBlocked = false;
    const data = dealerDataFromJWT(authService);
    const options = {
      method: isRemove ? "DELETE" : "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };
  fetchUpdateAccessoryMTO.propTypes = {
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cid: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    zcol: PropTypes.string,
    zcolRuote: PropTypes.string,
    accessoryItem: PropTypes.shape({
      sku: PropTypes.string,
      cha: PropTypes.string,
      value: PropTypes.string,
      quantity: PropTypes.number,
      type: PropTypes.string,
      currency: PropTypes.string,
      formattedPrice: PropTypes.string,
      price: PropTypes.number,
    }).isRequired,
    isRemove: PropTypes.bool.isRequired,
  };

  const fetchSectionMTO = ({ country, language, cid, section }) => {
    const template = retrieveTemplate(templateKeyMap.mtoSection);
    const body = { ...section };
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };
  fetchSectionMTO.propTypes = {
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cid: PropTypes.string.isRequired,
    section: PropTypes.shape({
      sectionType: PropTypes.string,
      discountType: PropTypes.string,
      discountValue: PropTypes.number,
      discountPriceValue: PropTypes.number,
    }).isRequired,
  };

  const fetchAddAdditionalEntryMTO = ({
    country,
    language,
    cid,
    additionalEntry,
  }) => {
    const template = retrieveTemplate(templateKeyMap.mtoAddAdditionalEntry);
    const body = { ...additionalEntry };
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchDelAdditionalEntryMTO = ({
    country,
    language,
    cid,
    removeEntry,
  }) => {
    const template = retrieveTemplate(templateKeyMap.mtoDelAdditionalEntry);
    let params = "";
    if (removeEntry.type === "OTHER") {
      const queryParam = {
        name: removeEntry.name,
      };
      params = addQueryParamsToRequestUrl(queryParam);
    }
    const url =
      endpointResolver(template, country, language, cid, removeEntry.type) +
      params;
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "DELETE",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };
  fetchDelAdditionalEntryMTO.propTypes = {
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cid: PropTypes.string.isRequired,
    removeEntry: PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  };

  const fetchQuotationMTO = ({
    country,
    language,
    cid,
    opportunityInput = undefined,
  }) => {
    const template = retrieveTemplate(templateKeyMap.mtoQuotation);
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const body = {};
    if (opportunityInput?.isToPrint) {
      body.testRideDone = opportunityInput?.testRideDone || false;
      body.testRidePlanned = opportunityInput?.testRidePlanned || false;
      body.testRideDate = opportunityInput?.testRideDate || "";
    }
    body.fromConfiguration = true;
    if (opportunityInput?.description?.length > 0) {
      body.description = opportunityInput?.description;
    }
    body.isToPrint = opportunityInput?.isToPrint;
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchAddTradeInMTO = ({ country, language, cid, tradeInBike }) => {
    const template = retrieveTemplate(templateKeyMap.mtoTradeIn);
    const url = endpointResolver(template, country, language, cid);
    const body = { ...tradeInBike };
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };
  fetchAddTradeInMTO.propTypes = {
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cid: PropTypes.string.isRequired,
    tradeInBike: PropTypes.shape({
      notes: PropTypes.string,
      matriculationDate: PropTypes.string,
      value: PropTypes.number,
      unitOfMeasure: PropTypes.string,
      mileage: PropTypes.number,
      licensePlate: PropTypes.string,
      modelYear: PropTypes.number,
      productId: PropTypes.string,
      vin: PropTypes.string,
    }),
  };

  const fetchRemoveTradeInMTO = ({ country, language, cid }) => {
    const template = retrieveTemplate(templateKeyMap.mtoTradeIn);
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "DELETE",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const fetchAddFinanceMTO = ({ country, language, cid, finance }) => {
    const template = retrieveTemplate(templateKeyMap.mtoFinance);
    const url = endpointResolver(template, country, language, cid);
    const body = { ...finance };
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "PUT",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
      body: getBody(body),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };
  fetchAddFinanceMTO.propTypes = {
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cid: PropTypes.string.isRequired,
    finance: PropTypes.shape({
      period: PropTypes.number,
      company: PropTypes.string,
      solution: PropTypes.string,
      effectiveAPR: PropTypes.number,
      apr: PropTypes.number,
      deposit: PropTypes.number,
      monthlyPayment: PropTypes.number,
      guaranteedFutureValue: PropTypes.number,
      amount: PropTypes.number,
      interest: PropTypes.number,
      preliminaryCost: PropTypes.number,
      installmentCollectionCost: PropTypes.number,
      substitutiveTax: PropTypes.number,
      annualCommunicationFee: PropTypes.number,
      totalAmountRequested: PropTypes.number,
      notes: PropTypes.string,
    }),
  };

  const fetchRemoveFinanceMTO = ({ country, language, cid }) => {
    const template = retrieveTemplate(templateKeyMap.mtoFinance);
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "DELETE",
      headers: Object.assign({}, contentType, accept, buildHeader(data, true)),
    };
    return fetch(url, options).then((response) => handleResponse(response));
  };

  const contactDealerMTO = ({
    country,
    language,
    cid,
    productLine,
    superModelCode,
    modelCode,
    urlB2B,
  }) => {
    const template = retrieveTemplate(templateKeyMap.mtoContactDealer);
    const baseUrl = endpointResolver(template, country, language);
    const queryParam = {
      model: `${productLine}_${superModelCode}_${modelCode}`,
      cid,
      cfgurl: urlB2B,
    };
    const queryParams = addQueryParamsToRequestUrl(queryParam);
    const url = baseUrl.concat(queryParams);
    console.debug("Send configuration to dealer success.", url);
    window.open(url, "_blank", "noreferrer");
  };

  const fetchPrintB2BMTO = (country, language, cid) => {
    const template = retrieveTemplate(templateKeyMap.mtoPrintB2B);
    const url = endpointResolver(template, country, language, cid);
    const data = dealerDataFromJWT(authService);
    const options = {
      method: "POST",
      headers: Object.assign(
        {},
        contentType,
        {
          Accept: "application/octet-stream",
        },
        buildHeader(data, true)
      ),
    };
    return fetch(url, options).then((response) => response.blob());
  };

  return {
    fetchInitMTO,
    patchMTO,
    fetchRetrieveConfigMTO,
    fetchSaveOnMyDucatiMTO,
    fetchAddApparelMTO,
    fetchRemoveApparelMTO,
    fetchApparelsMTO,
    fetchApparelDetailsMTO,
    fetchHierarchyMTO,
    fetchEnrichmentMTO,
    fetchAccessoriesMTO,
    fetchAccessoriesEnrichmentMTO,
    fetchUpdateAccessoryMTO,
    fetchSectionMTO,
    fetchAddAdditionalEntryMTO,
    fetchDelAdditionalEntryMTO,
    fetchQuotationMTO,
    fetchAddTradeInMTO,
    fetchRemoveTradeInMTO,
    fetchAddFinanceMTO,
    fetchRemoveFinanceMTO,
    fetchSaveAccountMTO,
    fetchSendToEmailMTO,
    contactDealerMTO,
    fetchPrintB2BMTO,
  };
}
