import { ProductConfigurationModule } from "@mhp-immersive-exp/sdk/configuration";
import { ProductConfigurationSessionModule } from "@mhp-immersive-exp/sdk/configuration/session";
import { EngineControlModule } from "@mhp-immersive-exp/sdk/engine-control";
import { VisualizationModule } from "@mhp-immersive-exp/sdk/visualization";

import { ConfiguratorLib } from "@mhp-immersive-exp/sdk";
import { EngineConnectionModule } from "@mhp-immersive-exp/sdk/engine-connection";
import { ImageModule } from "@mhp-immersive-exp/sdk/iod";
import { ProductDataModule } from "@mhp-immersive-exp/sdk/product-data";
import { ThumbnailModule } from "@mhp-immersive-exp/sdk/thumbnail";
import { StreamingModule } from "@mhp-immersive-exp/sdk/streaming";

/**
 * Declares the modules to be used in the configurator.
 * The order of modules is arbitrary.
 * The configurator will initialize the modules in the order they are declared.
 *
 * It is necessary to import this file in the top-most file of the application.
 */
export default function importMHPModuls() {
  ConfiguratorLib.use(ProductConfigurationSessionModule)
    .use(ProductConfigurationModule)
    .use(ProductDataModule)
    .use(VisualizationModule)
    .use(ThumbnailModule)
    .use(EngineConnectionModule)
    .use(EngineControlModule)
    .use(ImageModule)
    .use(StreamingModule);
}
