// StickyContext.js
import React, { createContext, useState } from "react";

export const CartStickyContext = createContext();

export function CartStickyProvider({ children }) {
  const [cartStickyContent, setCartStickyContent] = useState({});

  return (
    <CartStickyContext.Provider
      value={{ cartStickyContent, setCartStickyContent }}
    >
      {children}
    </CartStickyContext.Provider>
  );
}
