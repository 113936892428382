/* eslint-disable max-depth */
/* eslint-disable guard-for-in */
import { isDesktopView } from "./Sizing";
import { isEmptyObject } from "./Utils";

export const APPARELS_GROUP = "Apparels";
export const APPARELS_MACRO = "PERFORMANCE WEAR";
export const HIGHLIGHTED_MODAL_ID = "highlightedConfigurationModal";
export const REMOVE_HIGHLIGHTED_MODAL_ID =
  "removeHighlightedConfigurationModal";
export const HIGHLIGHTED_PREFIX_BTN_ID = "check-button-apparel-checked-";

export function getAllApparels(apparelsContent) {
  let list = [];
  for (const subgroup in apparelsContent.allApparels) {
    if (apparelsContent.allApparels?.[subgroup]) {
      for (const apparelsGender in apparelsContent.allApparels[subgroup]
        .apparels) {
        if (
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender] &&
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender]
            .length > 0
        ) {
          list = list.concat(
            apparelsContent.allApparels[subgroup].apparels[apparelsGender]
          );
        }
      }
    }
  }
  return list;
}

export function getApparelsByGender(apparelsContent, gender) {
  let list = [];
  for (const subgroup in apparelsContent.allApparels) {
    if (apparelsContent.allApparels?.[subgroup]) {
      for (const apparelsGender in apparelsContent.allApparels[subgroup]
        .apparels) {
        if (
          apparelsGender &&
          apparelsGender === gender &&
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender] &&
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender]
            .length > 0
        ) {
          list = list.concat(
            apparelsContent.allApparels[subgroup].apparels[apparelsGender]
          );
        }
      }
    }
  }
  return list;
}

export function getAllFilteredApparels(apparelsContent, filterList) {
  let list = [];
  for (const subgroup in apparelsContent.allApparels) {
    if (
      subgroup &&
      containsFilter(filterList, subgroup) &&
      apparelsContent.allApparels?.[subgroup]
    ) {
      for (const apparelsGender in apparelsContent.allApparels[subgroup]
        .apparels) {
        if (
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender] &&
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender]
            .length > 0
        ) {
          list = list.concat(
            apparelsContent.allApparels[subgroup].apparels[apparelsGender]
          );
        }
      }
    }
  }
  return list;
}

export function getApparelsByGenderAndFilters(
  apparelsContent,
  gender,
  filterList
) {
  let list = [];
  for (const subgroup in apparelsContent.allApparels) {
    if (
      subgroup &&
      containsFilter(filterList, subgroup) &&
      apparelsContent.allApparels?.[subgroup]
    ) {
      for (const apparelsGender in apparelsContent.allApparels[subgroup]
        .apparels) {
        if (
          apparelsGender &&
          apparelsGender === gender &&
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender] &&
          apparelsContent.allApparels[subgroup].apparels?.[apparelsGender]
            .length > 0
        ) {
          list = list.concat(
            apparelsContent.allApparels[subgroup].apparels[apparelsGender]
          );
        }
      }
    }
  }
  return list;
}

export function containsFilter(filterList, subgroup) {
  return (
    filterList && filterList.findIndex((item) => item.itemKey === subgroup) >= 0
  );
}

export function removeFilter(apparelsContent, setApparelsContent, filterKey) {
  let list = [...apparelsContent.filterList];
  list = list.filter((item) => item.itemKey !== filterKey);
  const newApparelsContent = { ...apparelsContent };
  newApparelsContent.filterList = list;
  setApparelsContent(newApparelsContent);
}

export function addFilter(apparelsContent, setApparelsContent, filter) {
  const { filterList } = apparelsContent;
  const newApparelsContent = { ...apparelsContent };
  if (
    checkFilterList(filterList) &&
    !containsFilter(filterList, filter.itemKey)
  ) {
    const list = [...filterList];
    list.splice(0, 1);
    list.push(filter);
    newApparelsContent.filterList = list;
  } else {
    newApparelsContent.filterList = [filter];
  }
  if (!isDesktopView()) {
    newApparelsContent.showLeftbar = false;
  }
  setApparelsContent(newApparelsContent);
}

export function checkFilterList(filterList) {
  return filterList && filterList.length > 0;
}

export function findApparelByChildId(allApparels, childId) {
  // eslint-disable-next-line guard-for-in
  for (const subgroup in allApparels) {
    for (const gender in allApparels[subgroup].apparels) {
      if (gender && allApparels[subgroup].apparels?.[gender]) {
        const find = findChildInGender(
          allApparels[subgroup].apparels,
          gender,
          childId
        );
        if (find) {
          return find;
        }
      }
    }
  }
  return undefined;
}

function findChildInGender(apparels, gender, childId) {
  const list = apparels[gender];
  for (let index = 0; index < list.length; index++) {
    const apparel = list[index];
    const { children } = apparel;
    if (apparel.children && apparel.children.length > 0) {
      for (let chidlIndex = 0; chidlIndex < children.length; chidlIndex++) {
        if (children[chidlIndex].id === childId) {
          return children[chidlIndex];
        }
      }
    } else if (apparel.id === childId) {
      return apparel;
    }
  }
  return undefined;
}

export function buildCartApparels(allApparels, initSessionContent) {
  const cartApparels = {};
  if (
    allApparels &&
    initSessionContent?.configuration?.cart &&
    initSessionContent.configuration.cart.length > 0
  ) {
    initSessionContent.configuration.cart.forEach((element) => {
      const child = findApparelByChildId(allApparels, element.id);
      if (child) {
        child.quontity = element.qty;
        if (cartApparels[child.subgroup_name]) {
          cartApparels[child.subgroup_name][child.id] = {
            ...child,
            price: {
              currency: element?.price?.currency,
              formatted_amount: element?.price?.formatted_amount,
              unformatted_amount: element?.price?.unformatted_amount,
            },
            quontity: child.quontity,
            modifica: false,
            rimuovi: true,
          };
        } else {
          const typology = child.subgroup_name;
          const item = {};
          item[child.id] = {
            ...child,
            defaultName: child.default_name,
            price: {
              currency: element?.price?.currency,
              formatted_amount: element?.price?.formatted_amount,
              unformatted_amount: element?.price?.unformatted_amount,
            },
            quontity: child.quontity,
            modifica: false,
            rimuovi: true,
          };
          delete item[child.id].default_name;
          cartApparels[typology] = item;
        }
      }
    });
  }
  return cartApparels;
}

export function buildApparelsGroup(
  apparels,
  initSessionContent,
  isStatusCarrelloRecap
) {
  const apparelsGroup = {
    gruppo: APPARELS_GROUP,
    list: {},
    modifica: false,
    rimuovi: true,
  };

  const content = isStatusCarrelloRecap ? apparels : apparels?.allApparels;

  if (content) {
    let cartApparels = {};
    cartApparels = buildCartApparels(content, initSessionContent);
    apparelsGroup.list = cartApparels;
  }

  return apparelsGroup;
}

export function buildApparelsContent(groupApparels) {
  const allApparelsMap = new Map();
  const allApparels = {};

  for (const group of groupApparels) {
    for (const apparel of group.apparels) {
      if (allApparelsMap.has(apparel.subgroup_name)) {
        addGenderChild(allApparelsMap, apparel);
      } else {
        const key = apparel.subgroup_name;
        const value = {};
        const gender = retrieveGenderToContext(apparel, "nogender");
        const lowerGender = gender.toLowerCase();
        value[lowerGender] = [
          { id: apparel.id, father: apparel, children: [] },
        ];
        key && allApparelsMap.set(key, value);
      }
    }
  }
  // Convert map to json object
  allApparelsMap.forEach((value, key) => {
    let stringKey = key.toLowerCase();
    if (stringKey.includes("/")) {
      stringKey = stringKey.replaceAll("/", "_");
    }
    if (stringKey.includes(" ")) {
      stringKey = stringKey.replaceAll(" ", "_");
    }
    allApparels[stringKey] = { apparels: value, subgroup: key };
  });

  return allApparels;
}

export function retrieveGenderToContext(apparel, defaultGender) {
  if (apparel?.meta?.tags?.length === 1) {
    return apparel.meta.tags[0];
  } else if (apparel?.meta?.tags?.length > 1 && apparel?.gender) {
    return apparel.gender;
  }
  return defaultGender;
}

function addGenderChild(allApparelsMap, apparel) {
  const obj = allApparelsMap.get(apparel.subgroup_name);
  const gender = retrieveGenderToContext(apparel, "nogender");
  const lowerGender = gender.toLowerCase();
  if (
    obj[lowerGender] !== null &&
    obj[lowerGender] !== undefined &&
    !isEmptyObject(obj[lowerGender])
  ) {
    if (
      apparel.father_id !== null &&
      isChild(obj[lowerGender], apparel.father_id)
    ) {
      const index = getIndex(obj[lowerGender], apparel.father_id);
      obj[lowerGender][index].children = [
        ...obj[lowerGender][index].children,
        apparel,
      ];
      allApparelsMap.set(apparel.subgroup_name, obj);
    } else {
      obj[lowerGender].push({ id: apparel.id, father: apparel, children: [] });
      allApparelsMap.set(apparel.subgroup_name, obj);
    }
  } else {
    obj[lowerGender] = [{ id: apparel.id, father: apparel, children: [] }];
    allApparelsMap.set(apparel.subgroup_name, obj);
  }
}

function isChild(list, fatherId) {
  for (const item of list) {
    if (item.id === fatherId) {
      return true;
    }
  }
  return false;
}

function getIndex(list, fatherId) {
  for (let index = 0; index < list.length; index++) {
    if (list[index].id === fatherId) {
      return index;
    }
  }
  return null;
}

function partition(items, left, right) {
  // rem that left and right are pointers.

  const pivot = items[Math.floor((right + left) / 2)];
  let i = left; // left pointer
  let j = right; // right pointer

  while (i <= j) {
    // increment left pointer if the value is less than the pivot
    while (items[i].name < pivot.name) {
      i++;
    }

    // decrement right pointer if the value is more than the pivot
    while (items[j].name > pivot.name) {
      j--;
    }

    // else we swap.
    if (i <= j) {
      [items[i], items[j]] = [items[j], items[i]];
      i++;
      j--;
    }
  }

  // return the left pointer
  return i;
}

export function quickSort(items, left, right) {
  let index;

  if (items.length > 1) {
    index = partition(items, left, right); // get the left pointer returned

    if (left < index - 1) {
      // more elements on the left side
      quickSort(items, left, index - 1);
    }

    if (index < right) {
      // more elements on the right side
      quickSort(items, index, right);
    }
  }

  return items; // return the sorted array
}

export function checkColor(color) {
  return color?.name !== null && color?.asset_small !== null;
}

export function epurateColorsMap(map) {
  const epuratedMap = new Map();
  map.forEach((value, key) => {
    let newList = [];
    if (value.length > 1) {
      for (const item of value) {
        if (checkColor(item.color)) {
          newList.push(item);
        }
      }
      if (newList.length > 0) {
        newList.push(value[0]);
      }
    } else {
      newList = value;
    }
    epuratedMap.set(key, newList);
  });
  return epuratedMap;
}

export function updateApparelCartContent(
  allApparels,
  initSessionContent,
  carrelloContent,
  setCarrelloContent
) {
  if (
    carrelloContent?.listElementCarrello &&
    carrelloContent.listElementCarrello.length > 0
  ) {
    const newCarrelloContent = { ...carrelloContent };
    const group = newCarrelloContent.listElementCarrello.find(
      (element) => element.gruppo === APPARELS_GROUP
    );
    const groupIndex = newCarrelloContent.listElementCarrello.findIndex(
      (element) => element.gruppo === APPARELS_GROUP
    );
    if (group?.list && isEmptyObject(group.list) && groupIndex) {
      let cartApparels = {};
      cartApparels = buildCartApparels(allApparels, initSessionContent);
      group.list = { ...cartApparels };
      newCarrelloContent.listElementCarrello.splice(groupIndex, 1, group);
      setCarrelloContent(newCarrelloContent);
    }
  }
}

function checkHighlighted(apparel, highlightedList) {
  const hasHighlighted = highlightedList.findIndex(
    (item) => item.name === apparel.name
  );
  return apparel.father_id === null && hasHighlighted < 0;
}

export function buildHighlightedOutfits(apparelsResponse) {
  const highlightedList = [];
  if (apparelsResponse?.length > 0) {
    const macroGroup = apparelsResponse.find(
      (item) => item.group_name === APPARELS_MACRO
    );
    const { apparels } = macroGroup;
    let counter = 0;
    for (const apparel of apparels) {
      if (counter === process.env.REACT_APP_HIGHLIGHTED_APPARELS) {
        break;
      }
      if (
        checkHighlighted(apparel, highlightedList) &&
        counter < process.env.REACT_APP_HIGHLIGHTED_APPARELS
      ) {
        const item = {
          id: apparel.id,
          name: apparel.name,
          fullName: `${apparel.name} - ${apparel.description}`,
          typology: apparel.description,
          formattedPrice: apparel.price?.formatted_amount || "",
          unformattedPrice: apparel.price?.unformatted_amount || "",
          gender: retrieveGenderToContext(apparel, ""),
          preview_image: apparel.preview_image,
          isHighligh: true,
          subgroup_name: apparel.subgroup_name,
        };
        highlightedList.push(item);
        counter++;
      }
    }
  }
  return highlightedList;
}

export function findApparelByFatherId(allApparels, fatherId) {
  // eslint-disable-next-line guard-for-in
  for (const subgroup in allApparels) {
    for (const gender in allApparels[subgroup].apparels) {
      if (gender && allApparels[subgroup].apparels?.[gender]) {
        const find = findFatherInGender(
          allApparels[subgroup].apparels[gender],
          fatherId
        );
        if (find) {
          return find;
        }
      }
    }
  }
  return undefined;
}

function findFatherInGender(list, fatherId) {
  for (const apparel of list) {
    if (apparel && apparel?.id === fatherId) {
      return apparel;
    }
  }
  return undefined;
}

export function hasColor(list, color) {
  if (list.find((item) => item.name === color.name)) {
    return true;
  }
  return false;
}

export function hasSize(list, size) {
  if (list.find((item) => item === size)) {
    return true;
  }
  return false;
}

export function findFatherIdIntoCart(cartContent, fatherId) {
  if (cartContent?.listElementCarrello?.length > 0) {
    const group = cartContent.listElementCarrello.find(
      (element) => element.gruppo === APPARELS_GROUP
    );
    if (group?.list) {
      for (const subgroup in group.list) {
        if (subgroup && findIntoListCart(group.list, subgroup, fatherId)) {
          return true;
        }
      }
    }
  }
  return false;
}

function findIntoListCart(list, subgroup, fatherId) {
  for (const apparelId in list[subgroup]) {
    if (
      apparelId &&
      list[subgroup]?.[apparelId] &&
      list[subgroup][apparelId]?.father_id === fatherId
    ) {
      return true;
    }
  }
  return false;
}

export function getChildrenFromCartByFatherId(cartContent, fatherId) {
  const childList = [];
  if (cartContent) {
    const group = cartContent.listElementCarrello.find(
      (element) => element.gruppo === APPARELS_GROUP
    );
    if (group?.list) {
      for (const subgroup in group.list) {
        for (const apparelId in group.list[subgroup]) {
          if (
            apparelId &&
            group.list[subgroup]?.[apparelId] &&
            group.list[subgroup][apparelId]?.father_id === fatherId
          ) {
            childList.push(group.list[subgroup][apparelId]);
          }
        }
      }
    }
  }
  return childList;
}

export function getChildrenFromCart(cartContent, id) {
  if (cartContent) {
    const group = cartContent.listElementCarrello.find(
      (element) => element.gruppo === APPARELS_GROUP
    );
    if (group?.list) {
      for (const subgroup in group.list) {
        for (const apparelId in group.list[subgroup]) {
          if (
            apparelId &&
            group.list[subgroup]?.[apparelId] &&
            group.list[subgroup][apparelId]?.id === id
          ) {
            return group.list[subgroup][apparelId];
          }
        }
      }
    }
  }
  return undefined;
}

export function buildNavList(languageContent) {
  const list = [];
  list.push({
    i18nKey: "configuration.apparel_gender.all",
    step: languageContent.labels["configuration.apparel_gender.all"],
    position: 1,
  });
  list.push({
    i18nKey: "configuration.apparel_gender.man",
    step: languageContent.labels["configuration.apparel_gender.man"],
    position: 2,
  });
  list.push({
    i18nKey: "configuration.apparel_gender.woman",
    step: languageContent.labels["configuration.apparel_gender.woman"],
    position: 3,
  });
  list.push({
    i18nKey: "apparel.gallery.header.navbar.label.child",
    step: languageContent.steps_information[
      "apparel.gallery.header.navbar.label.child"
    ],
    position: 4,
  });
  return list;
}

export function getGender(apparel, gender) {
  if (apparel?.isHighligh) {
    return apparel?.gender;
  } else if (apparel?.meta?.tags?.length > 0 && gender) {
    for (const tag of apparel.meta.tags) {
      if (tag.toLowerCase() === gender.toLowerCase()) {
        return tag;
      }
    }
  }
  return "";
}

export function buildSlicedApparelList(apparelList, size) {
  const apparelListSliced = apparelList.slice(0, size);
  const apparelListDetails = [];
  apparelListSliced.forEach((apparel) => {
    const apparelDetails = {
      id: apparel?.father?.id,
      name: apparel?.father?.name,
      fullName: `${apparel?.father?.name} - ${apparel?.father?.description}`,
      typology: apparel?.father?.description,
      formattedPrice: apparel?.father?.price?.formatted_amount || "",
      unformattedPrice: apparel?.father?.price?.unformatted_amount || "",
      gender: retrieveGenderToContext(apparel?.father, ""),
      preview_image: apparel?.father?.preview_image,
      isHighligh: true,
      subgroup_name: apparel?.father?.subgroup_name,
    };
    apparelListDetails.push(apparelDetails);
  });
  return apparelListDetails;
}
