import { pageTags } from "../DataLayerContext";
import {
  checkArgs,
  isLogged,
  resolveFamilyName,
} from "../../../utils/DataLayer";

export function buildWBCStepEvent({
  eventName = "",
  familyName = "",
  modelName = "",
  equipmentName = "",
  stepName = "",
  stepOrder = "",
  items,
  supermodelName = "",
  currency = "",
}) {
  let wbcListStepEvent = {};
  const realFamilyName = resolveFamilyName(familyName);
  if (
    checkArgs([eventName, realFamilyName, modelName, stepName, stepOrder]) &&
    items?.length > 0
  ) {
    const login = isLogged();
    wbcListStepEvent = {
      event: eventName,
      pageTags: pageTags.configurator,
      login,
      stepName,
      stepOrder,
      items,
      DL_familyName: realFamilyName,
      DL_supermodelName: supermodelName,
      DL_modelName: modelName,
      DL_equipmentName: equipmentName,
    };

    if (currency) wbcListStepEvent.currency = currency;
  }
  return wbcListStepEvent;
}
