import { useCallback } from "react";
import { useConfiguratorLib } from "../../../../configuratorMTO/hooks/useConfiguratorLib";
import { useMTOContext } from "../../../../../context/MTOContext";
import { APPARELS_GROUP } from "../../../../../utils/Apparels";
import { AFTERMARKET_GROUP } from "../../../../../hooks/useAftermarketMTO";
import { useBundleContent } from "../../../../../context/BundleAccessoriesContext";

export function useUpdateSession() {
  const configLib = useConfiguratorLib();
  const { state, enableRedirectOnSave } = useMTOContext();
  const { bundleContent } = useBundleContent();

  const optionFormatter = (value) => {
    let optionFormatted = "";
    if (value?.attributeName?.toLowerCase().includes("bundle")) {
      optionFormatted = `${value.domainValueKey}_TRUE`;
    } else {
      optionFormatted = value.domainValueKey
        .replaceAll(/\s/gi, "_")
        .replaceAll(/&/gi, "AND");
    }
    return optionFormatted;
  };

  const validateState = () => {
    if (enableRedirectOnSave) {
      return state?.cart?.groupedItemMap?.size > 0;
    }
    return (
      state?.cart?.groupedItemMap?.size > 0 &&
      bundleContent?.accessoryEnrichment
    );
  };

  const buildConfigOptions = () => {
    if (validateState()) {
      const configOptions = [];
      const map = state.cart.groupedItemMap;
      for (const groupKey of new Set(map.keys())) {
        const valueMap = map.get(groupKey);
        if (valueMap && groupKey !== APPARELS_GROUP) {
          if (groupKey === AFTERMARKET_GROUP) {
            valueMap.forEach((value) => {
              const currentEnrich =
                bundleContent?.accessoryEnrichment[value.domainValueKey];
              let packageSku = "";
              let packageList = [];
              if (currentEnrich?.testoSingoli?.acckit?.length > 0) {
                packageSku = value.domainValueKey;
                packageList = currentEnrich.testoSingoli.acckit;
              }
              if (value.domainValueKey === packageSku) {
                packageList.forEach((sku) => {
                  configOptions.push(sku);
                });
              } else {
                const optionFormatted = optionFormatter(value);
                configOptions.push(optionFormatted);
              }
            });
          } else {
            valueMap.forEach((value) => {
              const optionFormatted = optionFormatter(value);
              configOptions.push(optionFormatted);
            });
          }
        }
      }
      return configOptions;
    }
    return [];
  };

  const updateSession = useCallback(() => {
    if (
      state?.cart?.groupedItemMap?.size <= 0 &&
      !configLib.productConfigurationSessionService
    ) {
      return;
    }
    const configOptions = buildConfigOptions();
    if (configOptions?.length > 0) {
      configLib.productConfigurationSessionService.setConfigOptions(
        configOptions
      );
    }
  }, [
    state?.cart?.groupedItemMap,
    configLib?.productConfigurationSessionService,
  ]);

  return { buildConfigOptions, updateSession };
}
