import React, { useState, createContext, useContext } from "react";

export const BundleAccessoriesContext = createContext({
  bundleContent: {},
});

export function BundleProvider({ children, props }) {
  const [bundleContent, setBundleContent] = useState(
    props || JSON.parse(sessionStorage.getItem("bundlecontent"))
  );

  const provider = {
    bundleContent,
    setBundleContent: (selected) => {
      const newBundleContent = selected;
      sessionStorage.removeItem("bundlecontent");
      sessionStorage.setItem("bundlecontent", JSON.stringify(newBundleContent));
      setBundleContent(newBundleContent);
    },
  };

  return (
    <BundleAccessoriesContext.Provider value={provider}>
      {children}
    </BundleAccessoriesContext.Provider>
  );
}

export function useBundleContent() {
  return useContext(BundleAccessoriesContext);
}
