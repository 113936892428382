import { func } from "prop-types";
import React, { useState, useEffect, createContext, useContext } from "react";

export const LanguageContext = createContext({
  language: {},
});

const localStateLanguage = JSON.parse(localStorage.getItem("languagecontent"));

export function LanguageProvider({ children, props }) {
  const [language, setLanguage] = useState(props || localStateLanguage);

  useEffect(() => {
    localStorage.setItem("languagecontent", JSON.stringify(language));
  }, [language]);

  const provider = {
    language,
    setLanguage: (selected) => {
      const newLanguage = selected;
      setLanguage(newLanguage);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);

  return languageContext.language.labels[tid] || tid;
}

export function Search({ did, tid }) {
  const languageContext = useContext(LanguageContext);
  let translation = tid;
  const dictionary = languageContext.language[did];
  if (dictionary) {
    translation = dictionary[tid] || tid;
    if (translation.includes("\n")) {
      return translation.split("\n").map((str, i) => (
        <p key={"str".concat(i + 1)} className="m-0">
          {str}
        </p>
      ));
    }
  }
  return translation;
}

export function useLanguageContent() {
  return useContext(LanguageContext);
}

export function useTaxNotes() {
  const { language, setLanguage } = useLanguageContent();

  function insetTaxNotes(notes) {
    if (notes && language) {
      const newLanguageData = {
        ...language,
        labels: { ...language.labels },
      };
      newLanguageData.labels["price.modal.tax_description"] = notes;
      setLanguage(newLanguageData);
    }
  }

  return { insetTaxNotes };
}

export function TaxNotes() {
  const { language } = useLanguageContent();

  if (language.labels["price.modal.tax_description"]) {
    return <Text tid="price.modal.tax_description" />;
  }

  return <Search did="price_information" tid="price.modal.description" />;
}
