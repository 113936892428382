import React, { useState, createContext, useEffect, useContext } from "react";

export const EnabledCountriesContext = createContext({
  enabledCountriesContent: {},
});

const localStateEnabledCountries = JSON.parse(
  localStorage.getItem("enabledcountriescontent")
);

export function EnabledCountriesProvider({ children, props }) {
  const [enabledCountriesContent, setEnabledCountriesContent] = useState(
    props || localStateEnabledCountries
  );

  useEffect(() => {
    localStorage.setItem(
      "enabledcountriescontent",
      JSON.stringify(enabledCountriesContent)
    );
  }, [enabledCountriesContent]);

  const provider = {
    enabledCountriesContent,
    setEnabledCountriesContent: (selected) => {
      const newEnabledCountriesContent = selected;
      setEnabledCountriesContent(newEnabledCountriesContent);
    },
  };

  return (
    <EnabledCountriesContext.Provider value={provider}>
      {children}
    </EnabledCountriesContext.Provider>
  );
}

export function useEnabledCountriesContent() {
  return useContext(EnabledCountriesContext);
}
