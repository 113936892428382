import React from "react";
import logoDucati from "../../assets/images/logo.svg";
import styles from "./ServicesPage.module.scss";

function CustomServicesPage({
  customTitle = "",
  customDescription = "",
  customBtnLabel = "",
  customHandler,
}) {
  return (
    <div
      data-component="service-page-container"
      className={`text-center bg-white ${styles.service_page_container}`}
    >
      <div className={"row align-items-center pb-5 ".concat(styles["row-div"])}>
        <div className="col pb-5">
          <img
            src={logoDucati}
            alt="Ducati"
            className={"img-fluid mb-5 ".concat(styles["img-style"])}
          />
          <h2 className={"mt-5 ".concat(styles.title)}>{customTitle}</h2>
          <p className={"mt-4 ".concat(styles["page-description"])}>
            {customDescription}
          </p>
          <button
            type="button"
            className={"mt-4 btn btn-outline-secondary ".concat(
              styles["btn-style"]
            )}
            onClick={() => {
              if (customHandler) {
                customHandler();
              }
            }}
          >
            {customBtnLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomServicesPage;
