export const dbName = {
  configurator: "configurator",
};

export const objectStoreName = {
  configuratorData: "configuratorData",
};

export const keyName = {
  initSessionContent: "initSessionContent",
  apparelsContent: "apparelsContent",
  financialContent: "financialContent",
};

export function buildListFromInit(composedAssets) {
  const list = [];
  const positions = [];
  const orderdList = [];
  const assets = { ...composedAssets };
  for (const asset in assets) {
    if (asset && assets[asset].length > 0) {
      list.push({ position: asset, url: assets[asset][0].url });
      positions.push(asset);
    }
  }
  positions.sort();
  for (const pos of positions) {
    const item = list.find((elem) => elem.position === pos);
    orderdList.push(item);
  }
  return orderdList;
}

export function createConfiguratorIndexedDB() {
  const request = indexedDB.open(dbName.configurator, 1);

  request.onerror = (event) => {
    console.log(
      "createConfiguratorIndexedDB - Database error: ",
      event.target.error
    );
  };

  request.onupgradeneeded = (event) => {
    console.log("createConfiguratorIndexedDB configuratorData.onupgradeneeded");
    const db = event.target.result;
    db.onerror = (event) => {
      console.log(
        "createConfiguratorIndexedDB Database error: ",
        event.target.error
      );
    };

    db.createObjectStore(objectStoreName.configuratorData, {
      keyPath: "id",
    });
    console.log(
      `createConfiguratorIndexedDB Object: ${objectStoreName.configuratorData} is created.`
    );
  };
}

export function createInitSessionContent(initSessionContent) {
  const request = indexedDB.open(dbName.configurator, 1);

  request.onerror = (event) => {
    console.log(
      "createInitSessionContent - Database error: ",
      event.target.error
    );
  };

  request.onsuccess = (event) => {
    const db = event.target.result;

    const objectStore = db
      .transaction([objectStoreName.configuratorData], "readwrite")
      .objectStore(objectStoreName.configuratorData);

    const cursorRequest = objectStore.openCursor(keyName.initSessionContent);

    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const initSessionContentRequest = objectStore.get(
          keyName.initSessionContent
        );
        initSessionContentRequest.onsuccess = (event) => {
          const data = event.target.result;
          data.initSessionContent = initSessionContent;
          const requestUpdate = objectStore.put(data);
          requestUpdate.onerror = (event) => {
            console.log(
              `createInitSessionContent Get ${keyName.initSessionContent} from ${objectStoreName.configuratorData} error on update : ${event.target.error}`
            );
          };
          requestUpdate.onsuccess = () => {
            console.log(
              `createInitSessionContent Updated ${keyName.initSessionContent} from ${objectStoreName.configuratorData}`
            );
          };
        };
      } else {
        objectStore.transaction.oncomplete = (event) => {
          const customerObjectStore = db
            .transaction(objectStoreName.configuratorData, "readwrite")
            .objectStore(objectStoreName.configuratorData);
          const addReq = customerObjectStore.add({
            id: keyName.initSessionContent,
            initSessionContent,
          });
          addReq.onsuccess = () => {
            console.log(
              `createInitSessionContent Created ${keyName.initSessionContent} entry.`
            );
          };
        };
      }
    };
  };
}

export function updateCarouselConfiguratorImages(
  initSessionContent,
  composedAssets
) {
  const newInitSessionContent = { ...initSessionContent };
  newInitSessionContent.configuration.composed_assets = composedAssets;
  return newInitSessionContent;
}

export function updateMHPCarouselImages(initSessionContent, assets) {
  const cloned = { ...initSessionContent };
  cloned.configuration.mhp_composed_assets = assets.map((asset) => ({
    views: asset.views,
    code: asset.environment,
  }));
  return cloned;
}

export function updateApparelsContent(apparelsContent) {
  const request = indexedDB.open(dbName.configurator, 1);

  request.onerror = (event) => {
    console.log(
      "updateApparelsContent createApparelsContent - Database error: ",
      event.target.error
    );
  };

  request.onsuccess = (event) => {
    const db = event.target.result;

    const objectStore = db
      .transaction([objectStoreName.configuratorData], "readwrite")
      .objectStore(objectStoreName.configuratorData);

    const cursorRequest = objectStore.openCursor(keyName.apparelsContent);

    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const apparelsContentRequest = objectStore.get(keyName.apparelsContent);
        apparelsContentRequest.onsuccess = (event) => {
          const data = event.target.result;
          data.apparelsContent = apparelsContent;
          const requestUpdate = objectStore.put(data);
          requestUpdate.onerror = (event) => {
            console.log(
              `updateApparelsContent Get ${keyName.apparelsContent} from ${objectStoreName.configuratorData} error on update : ${event.target.error}`
            );
          };
          requestUpdate.onsuccess = (event) => {
            console.log(
              `updateApparelsContent Updated ${keyName.apparelsContent} from ${objectStoreName.configuratorData}`
            );
          };
        };
      } else {
        objectStore.transaction.oncomplete = () => {
          const customerObjectStore = db
            .transaction(objectStoreName.configuratorData, "readwrite")
            .objectStore(objectStoreName.configuratorData);
          const addReq = customerObjectStore.add({
            id: keyName.apparelsContent,
            apparelsContent,
          });
          addReq.onsuccess = () => {
            console.log(
              `updateApparelsContent Created ${keyName.apparelsContent} entry.`
            );
          };
        };
      }
    };
  };
}

export function updateFinancialContent(financialContent) {
  const request = indexedDB.open(dbName.configurator, 1);

  request.onerror = (event) => {
    console.log(
      "updateFinancialContent - Database error: ",
      event.target.error
    );
  };

  request.onsuccess = (event) => {
    const db = event.target.result;

    const objectStore = db
      .transaction([objectStoreName.configuratorData], "readwrite")
      .objectStore(objectStoreName.configuratorData);

    const cursorRequest = objectStore.openCursor(keyName.financialContent);

    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const financialContentRequest = objectStore.get(
          keyName.financialContent
        );
        financialContentRequest.onsuccess = (event) => {
          const data = event.target.result;
          data.financialContent = financialContent;
          const requestUpdate = objectStore.put(data);
          requestUpdate.onerror = (event) => {
            console.log(
              `updateFinancialContent Get ${keyName.financialContent} from ${objectStoreName.configuratorData} error on update : ${event.target.error}`
            );
          };
          requestUpdate.onsuccess = (event) => {
            console.log(
              `updateFinancialContent Updated ${keyName.financialContent} from ${objectStoreName.configuratorData}`
            );
          };
        };
      } else {
        objectStore.transaction.oncomplete = () => {
          const customerObjectStore = db
            .transaction(objectStoreName.configuratorData, "readwrite")
            .objectStore(objectStoreName.configuratorData);
          const addReq = customerObjectStore.add({
            id: keyName.financialContent,
            financialContent,
          });
          addReq.onsuccess = () => {
            console.log(
              `updateFinancialContent Created ${keyName.financialContent} entry.`
            );
          };
        };
      }
    };
  };
}

export function retrieveFinancialContent(setFinancialContent) {
  const request = indexedDB.open(dbName.configurator, 1);
  request.onerror = (event) => {
    console.log(
      "retrieveFinancialContent - Database error: ",
      event.target.error
    );
  };
  request.onsuccess = (event) => {
    const db = event.target.result;
    if (existObjectStore(db)) {
      const objectStore = db
        .transaction([objectStoreName.configuratorData], "readonly")
        .objectStore(objectStoreName.configuratorData);
      const cursorRequest = objectStore.openCursor(keyName.financialContent);
      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const financialContentRequest = objectStore.get(
            keyName.financialContent
          );
          financialContentRequest.onsuccess = (event) => {
            const data = event.target.result;
            setFinancialContent(data.financialContent);
          };
        }
      };
    } else {
      console.log(
        `retrieveFinancialContent ObjectStore called ${objectStoreName.configuratorData} not exist`
      );
    }
  };
}

export function existObjectStore(db) {
  const objStoreName = db.objectStoreNames;
  return objStoreName.contains(objectStoreName.configuratorData);
}

function buildFamilyModelObj(data) {
  const newData = {
    familyModel: {},
  };
  data.forEach((family) => {
    newData.familyModel[family.id] = {};
    newData.familyModel[family.id].name = family.prodLineLabel;
    newData.familyModel[family.id].list = [];
    family.superModelList.forEach((superModel) => {
      superModel.versionList.forEach((version) => {
        const value = version.prodgrp1Label.includes(family.prodLineLabel)
          ? `${version.prodgrp1Label}`
          : `${family.prodLineLabel} ${version.prodgrp1Label}`;
        if (version.base.size > 0) {
          const baseList = version.base.get(version.prodgrp1);
          if (baseList?.length > 0) {
            const base = baseList[0];
            const newModel = {
              key: base.prodgrp1,
              modelName: superModel.id,
              value,
              depo: 0,
            };
            newData.familyModel[family.id].list.push(newModel);
          }
        }

        if (version.restricted.size > 0) {
          const restrictedList = version.restricted.get(version.prodgrp1);
          if (restrictedList?.length > 0) {
            const restricted = restrictedList[0];
            const newModel = {
              key: `${restricted.prodgrp1}35KW`,
              modelName: superModel.id,
              value: `${value} - 35KW`,
              depo: 1,
            };
            newData.familyModel[family.id].list.push(newModel);
          }
        }
      });
      if (newData.familyModel[family.id].list.length > 0) {
        newData.familyModel[family.id].list.sort((a, b) =>
          a.value < b.value ? 1 : -1
        );
      }
      if (newData.familyModel[family.id].list.length > 0) {
        newData.familyModel[family.id].list.sort((a, b) =>
          a.depo > b.depo ? 1 : -1
        );
      }
    });
  });
  return newData;
}

export function createFamilyModel(data) {
  const customerData = buildFamilyModelObj(data);

  const request = indexedDB.open("header", 1);

  request.onupgradeneeded = function (evt) {
    console.log("openDb.onupgradeneeded !!!!");
    const store = evt.currentTarget.result.createObjectStore("header", {
      keyPath: "id",
    });

    store.createIndex("familyModel", "familyModelId", { unique: true });
  };

  request.onsuccess = function (event) {
    const db = event.target.result;
    const store = db.transaction("header", "readwrite").objectStore("header");

    const reqDelete = store.delete(1);

    reqDelete.onsuccess = function (event) {
      console.log("Delete DB successful ");
    };
    reqDelete.onerror = function (er) {
      console.error("delete error", er);
    };

    const req = store.add({ id: 1, value: customerData });

    req.onsuccess = function (event) {
      console.log("Insertion in DB successful");
    };
    req.onerror = function (er) {
      console.error("addPublication error", er);
    };
  };
}
