import { BCP47LanguageCodeFromISO681LanguageCode } from "./Utils";

export const DOLLAR_SYMBOL_UNICODE = "\u0024";
export const EUR_SYMBOL_UNICODE = "\u20AC";
export const YEN_BASE_SYMBOL_UNICODE = "\u00A5";
export const YEN_SYMBOL_UNICODE = "\uFFE5";
export const POUND_SYMBOL_UNICODE = "\u00A3";
export const KRONE_SYMBOL = "kr";

export const BRL_SYMBOL = "R\u0024";
export const CAD_SYMBOL = "CAD \u0024";
export const SGD_SYMBOL = "S\u0024";

export const AED_SYMBOL = "AED";
export const INR_SYMBOL = "INR";
export const MYR_SYMBOL = "RM";
export const MXN_SYMBOL = "MXN";
export const PHP_SYMBOL = "Php";
export const CHF_SYMBOL = "SFr.";

// Available ISO Code 4217
export const AUSTRALIAN_CURRENCY = "AUD";
export const UNITED_STATES_CURRENCY = "USD";
export const BRAZILIAN_CURRENCY = "BRL";
export const EUROPE_CURRENCY = "EUR";
export const CANADIAN_CURRENCY = "CAD";
export const UNITED_ARAB_EMIRATES_CURRENCY = "AED";
export const INDIAN_CURRENCY = "INR";
export const JAPANESE_CURRENCY = "JPY";
export const MALAYSIAN_CURRENCY = "MYR";
export const MEXICAN_CURRENCY = "MXN";
export const NORWEGIAN_CURRENCY = "NOK";
export const SWEDISH_CURRENCY = "SEK";
export const PHILIPPINE_CURRENCY = "PHP";
export const SWISS_CURRENCY = "CHF";
export const SINGAPORE_CURRENCY = "SGD";
export const UNITED_KINGDOM_CURRENCY = "GBP";

function symbolIsStart(motorBikeformattedPrice, symbol) {
  if (motorBikeformattedPrice.startsWith(symbol)) {
    return true;
  } else if (motorBikeformattedPrice.endsWith(symbol)) {
    return false;
  }
  return false;
}

/**
 * This method build an object that has currency symbol and boolean flag "isStart" to specify if symbol
 * is place at the beginning or at the end of amount.
 * @param {*} backendCurrency Currency returned from backend in ISO Code 4217 format
 * @param {*} motorBikeformattedPrice the formatted amount of motorbike to understand where is placed the symbol
 * @returns
 */
export function getPriceCurrency(backendCurrency, motorBikeformattedPrice) {
  let currency;
  if (
    backendCurrency !== null &&
    backendCurrency !== undefined &&
    backendCurrency !== "" &&
    motorBikeformattedPrice !== null &&
    motorBikeformattedPrice !== undefined &&
    motorBikeformattedPrice !== ""
  ) {
    switch (backendCurrency) {
      case AUSTRALIAN_CURRENCY:
      case UNITED_STATES_CURRENCY:
        {
          const isStart = symbolIsStart(
            motorBikeformattedPrice,
            DOLLAR_SYMBOL_UNICODE
          );
          currency = { symbol: DOLLAR_SYMBOL_UNICODE, isStart };
        }
        break;
      case BRAZILIAN_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, BRL_SYMBOL);
          currency = { symbol: BRL_SYMBOL, isStart };
        }
        break;
      case EUROPE_CURRENCY:
        {
          const isStart = symbolIsStart(
            motorBikeformattedPrice,
            EUR_SYMBOL_UNICODE
          );
          currency = {
            symbol: isStart
              ? `${EUR_SYMBOL_UNICODE} `
              : ` ${EUR_SYMBOL_UNICODE}`,
            isStart,
          };
        }
        break;
      case CANADIAN_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, CAD_SYMBOL);
          currency = { symbol: CAD_SYMBOL, isStart };
        }
        break;
      case UNITED_ARAB_EMIRATES_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, AED_SYMBOL);
          currency = { symbol: AED_SYMBOL, isStart };
        }
        break;
      case INDIAN_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, INR_SYMBOL);
          currency = { symbol: INR_SYMBOL, isStart };
        }
        break;
      case JAPANESE_CURRENCY:
        {
          let isStart = symbolIsStart(
            motorBikeformattedPrice,
            YEN_BASE_SYMBOL_UNICODE
          );
          if (!isStart) {
            isStart = symbolIsStart(
              motorBikeformattedPrice,
              YEN_SYMBOL_UNICODE
            );
          }
          currency = { symbol: YEN_SYMBOL_UNICODE, isStart };
        }
        break;
      case MALAYSIAN_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, MYR_SYMBOL);
          currency = { symbol: MYR_SYMBOL, isStart };
        }
        break;
      case MEXICAN_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, MXN_SYMBOL);
          currency = { symbol: MXN_SYMBOL, isStart };
        }
        break;
      case NORWEGIAN_CURRENCY:
      case SWEDISH_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, KRONE_SYMBOL);
          currency = { symbol: KRONE_SYMBOL, isStart };
        }
        break;
      case PHILIPPINE_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, PHP_SYMBOL);
          currency = { symbol: PHP_SYMBOL, isStart };
        }
        break;
      case SWISS_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, CHF_SYMBOL);
          currency = { symbol: CHF_SYMBOL, isStart };
        }
        break;
      case SINGAPORE_CURRENCY:
        {
          const isStart = symbolIsStart(motorBikeformattedPrice, SGD_SYMBOL);
          currency = { symbol: SGD_SYMBOL, isStart };
        }
        break;
      case UNITED_KINGDOM_CURRENCY:
        {
          const isStart = symbolIsStart(
            motorBikeformattedPrice,
            POUND_SYMBOL_UNICODE
          );
          currency = { symbol: POUND_SYMBOL_UNICODE, isStart };
        }
        break;
    }
  }
  return currency;
}

export function getSymbolByISO(currencyIsoCode) {
  let symbol;
  if (
    currencyIsoCode !== null &&
    currencyIsoCode !== undefined &&
    currencyIsoCode !== ""
  ) {
    switch (currencyIsoCode) {
      case AUSTRALIAN_CURRENCY:
      case UNITED_STATES_CURRENCY:
        symbol = DOLLAR_SYMBOL_UNICODE;
        break;
      case BRAZILIAN_CURRENCY:
        symbol = BRL_SYMBOL;
        break;
      case EUROPE_CURRENCY:
        symbol = EUR_SYMBOL_UNICODE;
        break;
      case CANADIAN_CURRENCY:
        symbol = CAD_SYMBOL;
        break;
      case UNITED_ARAB_EMIRATES_CURRENCY:
        symbol = AED_SYMBOL;
        break;
      case INDIAN_CURRENCY:
        symbol = INR_SYMBOL;
        break;
      case JAPANESE_CURRENCY:
        symbol = YEN_SYMBOL_UNICODE;
        break;
      case MALAYSIAN_CURRENCY:
        symbol = MYR_SYMBOL;
        break;
      case MEXICAN_CURRENCY:
        symbol = MXN_SYMBOL;
        break;
      case NORWEGIAN_CURRENCY:
      case SWEDISH_CURRENCY:
        symbol = KRONE_SYMBOL;
        break;
      case PHILIPPINE_CURRENCY:
        symbol = PHP_SYMBOL;
        break;
      case SWISS_CURRENCY:
        symbol = CHF_SYMBOL;
        break;
      case SINGAPORE_CURRENCY:
        symbol = SGD_SYMBOL;
        break;
      case UNITED_KINGDOM_CURRENCY:
        symbol = POUND_SYMBOL_UNICODE;
        break;
    }
  }
  return symbol;
}

export function getSymbolByLanguage(language = "", currency = "") {
  if (language !== "" && currency !== "") {
    const locale = BCP47LanguageCodeFromISO681LanguageCode(language);
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }
  return EUR_SYMBOL_UNICODE;
}
