import { useLanguageContent } from "../../../context/LanguageContext";
import { useMTO } from "../../../hooks/useMTO";
import { useProgressContent } from "../../../context/ProgressContext";
import {
  DEFAULT_CONFLICTS_MODAL,
  useModalContent,
} from "../../../context/ModalContext";
import defaultImage from "../../../assets/images/DefaultImage.png";
import { useAftermarketMTO } from "../../../hooks/useAftermarketMTO";
import { replacer } from "../../../utils/Utils";

export const conflictTypes = {
  conflict: "CONFLICT",
};

export function isThereConflict(response, isAccessory = false) {
  if (isAccessory) {
    const consistent = response?.accessoryConfiguration?.consistent;
    return !consistent;
  } else if (response?.bikeConfiguration) {
    const result = response?.bikeConfiguration?.consistent;
    return !result;
  }
  return response?.consistent ? !response.consistent : false;
}

export function useConflictData() {
  const { language } = useLanguageContent();
  const { setConflictsModal } = useModalContent();

  const getConflictSubGroup = (bikeConfiguration) => {
    if (bikeConfiguration?.groups?.length > 0) {
      const { groups } = bikeConfiguration;
      const firstGroup = groups[0];
      if (firstGroup?.subGroups?.length > 0) {
        const { subGroups } = firstGroup;
        const conflictSubGroup = subGroups.find((elem) =>
          elem.groupType.includes(conflictTypes.conflict)
        );
        if (conflictSubGroup) {
          return conflictSubGroup;
        }
      }
    }
    return undefined;
  };

  const checkBundle = (key) => {
    return key?.toLowerCase() === "true" || key?.toLowerCase() === "false";
  };

  const buildOptionList = ({
    conflictSubGroup,
    accessory,
    isAccessory = false,
    accessoryEnrichment,
    getSKUByCha,
    isRemove = false,
  }) => {
    const list = [];
    if (isAccessory && conflictSubGroup?.cstics?.length > 0) {
      // SCENARIO DI CONFLITTO IN AGGIUNTA ACCESSORI
      conflictSubGroup.cstics.forEach((item) => {
        if (item.lastValidValue !== accessory.value) {
          const domainValue = item.domainvalues.find(
            (elem) => elem.key === item.lastValidValue
          );
          if (domainValue) {
            const sku = getSKUByCha("", "", domainValue.key);
            const accEnrich =
              sku?.length > 0 ? accessoryEnrichment[sku] : undefined;
            if (accEnrich) {
              list.push({
                attributeKey: domainValue.key,
                groupId: domainValue.key.replace(`@${domainValue.name}`, ""),
                domainValueKey: sku?.length > 0 ? sku : domainValue.key,
                name:
                  accEnrich?.linguaImgSingoli?.productName || domainValue.name,
                value: domainValue.key,
                price: accEnrich?.price?.formattedValue,
                sourceImg:
                  accEnrich?.linguaImgSingoli?.mainImage || defaultImage,
              });
            }
          }
        }
      });
    } else if (conflictSubGroup?.cstics?.length > 0) {
      conflictSubGroup.cstics.forEach((element) => {
        const filteredElement = element.domainvalues.filter(
          (domainValue) => domainValue.key === element.value
        )[0];
        list.push({
          attributeKey: element.key,
          attributeName: element.langdepname,
          groupId: element.key.replace(`@${element.name}`, ""),
          domainValueKey: checkBundle(filteredElement.key)
            ? element.name
            : filteredElement.key,
          name: checkBundle(filteredElement.key)
            ? element.langdepname
            : filteredElement.langdepname,
          value: checkBundle(filteredElement.key)
            ? element.name
            : filteredElement.name,
          price:
            filteredElement.price.value !== 0
              ? filteredElement.price.formattedValue
              : "",
          // TODO IMMAGINI
          sourceImg: filteredElement.images
            ? filteredElement.images[0]
            : defaultImage,
        });
      });
    }
    return list;
  };

  const conflictCardItem = (optionList, name) => {
    let conflictItem;
    if (optionList?.length > 0) {
      conflictItem = optionList.find((item) => item.attributeName !== name);
    }
    return conflictItem;
  };

  const showConflictsModal = (bikeConfiguration) => {
    const conflictSubGroup = getConflictSubGroup(bikeConfiguration);
    const optionList = buildOptionList({ conflictSubGroup });
    const conflictItem = conflictCardItem(optionList, conflictSubGroup.name);
    setConflictsModal({
      showModal: true,
      title: language?.labels["popup.accessory_interaction.title"] || "",
      message:
        language?.configurator_information["modal.conflicts.message"] || "",
      name: conflictSubGroup.name || "",
      optionList,
      conflictItem,
      isRemove: true,
    });
  };

  const hideConflictsModal = () => {
    setConflictsModal(DEFAULT_CONFLICTS_MODAL);
  };

  const showAccessoryConflictModal = ({
    accessory,
    accEnrich,
    accessoryEnrichment,
    getSKUByCha,
    accessoryConfiguration,
    isRemove = false,
  }) => {
    const conflictSubGroup = getConflictSubGroup(accessoryConfiguration);
    const optionList = buildOptionList({
      conflictSubGroup,
      accessory,
      isAccessory: true,
      accessoryEnrichment,
      getSKUByCha,
      isRemove,
    });
    const conflictItem = {
      attributeKey: accessory.attributeKey,
      groupId: accessory.groupId,
      domainValueKey: accessory.domainValueKey,
      name: accEnrich?.linguaImgSingoli?.productName || "",
      value: accessory.value,
      price: accessory?.price?.formattedValue || "",
      sourceImg: replacer(accEnrich.linguaImgSingoli.mainImage, "\\", "/"),
    };
    setConflictsModal({
      showModal: true,
      title: language?.labels["popup.accessory_interaction.title"] || "",
      message:
        language?.configurator_information["modal.conflicts.message"] || "",
      name: accessory.langDepName || "",
      optionList,
      conflictItem,
      isAccessory: true,
      isRemove,
      isConflict: true,
    });
  };

  const showMandatoryAccessoryModal = ({
    accessory,
    accEnrich,
    mandatoryList,
    isRemove = false,
  }) => {
    const conflictItem = {
      attributeKey: accessory.attributeKey,
      groupId: accessory.groupId,
      domainValueKey: accessory.domainValueKey,
      name: accEnrich?.linguaImgSingoli?.productName || "",
      value: accessory.value,
      price: accessory?.price?.formattedValue || "",
      sourceImg: replacer(accEnrich.linguaImgSingoli.mainImage, "\\", "/"),
    };
    const optionList = [];
    mandatoryList.forEach((mandatory) => {
      optionList.push({
        attributeKey: `${mandatory.chr}-${mandatory.chrValue}`,
        groupId: mandatory.categoryDesc,
        domainValueKey: mandatory.sku,
        name: mandatory.linguaImgSingoli.productName,
        value: mandatory.chrValue,
        price: mandatory.price.formattedValue,
        sourceImg: replacer(mandatory.linguaImgSingoli.mainImage, "\\", "/"),
      });
    });
    setConflictsModal({
      showModal: true,
      title: language?.labels["popup.accessory_interaction.title"] || "",
      message: isRemove
        ? language?.labels["popup.accessory_interaction.generic_remove"]
        : language?.labels["popup.accessory_interaction.generic_add"],
      name: accessory.langDepName || "",
      optionList,
      conflictItem,
      isAccessory: true,
      isRemove,
      isConflict: false,
    });
  };

  return {
    showConflictsModal,
    showAccessoryConflictModal,
    showMandatoryAccessoryModal,
    hideConflictsModal,
  };
}

export function useUpdateConflicts() {
  const { hideConflictsModal } = useConflictData();
  const {
    accessoryEnrichment,
    getSonByFatherId,
    updateAccessory,
    updateMandatoryAccessory,
  } = useAftermarketMTO();

  const { patching } = useMTO();
  const { progressContent, setProgressContent } = useProgressContent();

  const onCancel = async ({
    conflictItem,
    optionList = [],
    name = "",
    isAccessory = false,
  }) => {
    if (isAccessory) {
      const enrich = accessoryEnrichment[conflictItem.domainValueKey];
      const accessory = getSonByFatherId(conflictItem.domainValueKey, enrich);
      await updateMandatoryAccessory({
        accessory,
        caller: "useUpdateConflicts - onCancel",
        forceRemove: true,
      });
      hideConflictsModal();
    } else {
      const incompatibleItem = optionList.find(
        (item) => item.attributeName !== name
      );
      if (incompatibleItem) {
        setProgressContent({ isLoading: true });
        await patching({
          domainValueKey: null,
          attributeKey: incompatibleItem.attributeKey,
          groupId: incompatibleItem.groupId,
          caller: "ConflictContainer - onCancel",
          selected: true,
        });
        hideConflictsModal();
      }
    }
  };

  const onConfirm = async ({
    optionList = [],
    name = "",
    isAccessory = false,
  }) => {
    if (isAccessory) {
      for (let i = 0; i < optionList.length; i++) {
        const enrich = accessoryEnrichment[optionList[i].domainValueKey];
        const incompatibleItem = getSonByFatherId(
          optionList[i].domainValueKey,
          enrich
        );
        // eslint-disable-next-line no-await-in-loop
        await updateAccessory({
          accessory: incompatibleItem,
          caller: "useUpdateConflicts - onConfirm",
        });
      }
      hideConflictsModal();
    } else {
      setProgressContent({ isLoading: true });
      const lastItemSelected = optionList.find(
        (item) => item.attributeName === name
      );
      if (lastItemSelected) {
        await patching({
          domainValueKey: lastItemSelected.domainValueKey,
          attributeKey: lastItemSelected.attributeKey,
          groupId: lastItemSelected.groupId,
          caller: "ConflictContainer - onConfirm",
          selected: true,
        });
      }
      const incompatibleItem = optionList.find(
        (item) => item.attributeName !== name
      );
      if (incompatibleItem) {
        await patching({
          domainValueKey: incompatibleItem.domainValueKey,
          attributeKey: incompatibleItem.attributeKey,
          groupId: incompatibleItem.groupId,
          caller: "ConflictContainer - onConfirm",
          selected: false,
        });
      }
      hideConflictsModal();
    }
  };

  return {
    onCancel,
    onConfirm,
    isLoading: progressContent?.isLoading || false,
  };
}
