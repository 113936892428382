import React, { useState, createContext, useEffect, useContext } from "react";

export const SaveRecapLoginContext = createContext({
  saveConfig: {},
});

const localStateSaveConfig = JSON.parse(
  localStorage.getItem("saveconfigcontent")
);

export function SaveRecapLoginProvider({ children, props }) {
  const [saveConfig, setSaveConfig] = useState(props || localStateSaveConfig);

  useEffect(() => {
    localStorage.setItem("saveconfigcontent", JSON.stringify(saveConfig));
  }, [saveConfig]);

  const provider = {
    saveConfig,
    setSaveConfig: (selected) => {
      setSaveConfig(selected);
    },
  };

  return (
    <SaveRecapLoginContext.Provider value={provider}>
      {children}
    </SaveRecapLoginContext.Provider>
  );
}

export function useSaveRecapLogin() {
  return useContext(SaveRecapLoginContext);
}
