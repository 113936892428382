// hook used to generate dynamic url based on the opts
export function createImageURL(image, opts) {
  if (!image) return "";
  if (!image.includes("http")) return image;
  const url = new URL(image);

  if (opts.isNightMode) url.searchParams.set("nightmode", true);
  if (opts.resize) url.searchParams.set("resize", opts.resize);
  if (opts.cameradegree)
    url.searchParams.set("cameradegree", opts.cameradegree);
  return url;
}
