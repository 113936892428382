// IconContext.js
import React, { createContext, useState } from "react";

export const IconContext = createContext();

export function IconProvider({ children }) {
  const [iconContent, setIconContent] = useState("");

  return (
    <IconContext.Provider value={{ iconContent, setIconContent }}>
      {children}
    </IconContext.Provider>
  );
}
