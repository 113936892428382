import { useCallback, useEffect, useState } from "react";
import { useInit } from "../../../common/context/InitContext";
import { useQuotationData } from "../../context/QuotationContext";

export function useQuotationStatus() {
  const { quotationData } = useQuotationData();
  const { initSessionContent } = useInit();
  const [bikeSku, setBikeSku] = useState("");
  const [configurationSubTotal, setConfigurationSubTotal] = useState("");
  const [discountConfigurationSubTotal, setDiscountConfigurationSubTotal] =
    useState("");
  const [basePrice, setBasePrice] = useState("");
  const [discountedBasePrice, setDiscountedBasePrice] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [totalDiscountedValue, setTotalDiscountedValue] = useState("");
  const [totalUnformattedDiscountedValue, setTotalUnformattedDiscountedValue] =
    useState("");
  const [additionalChargesSubtotal, setAdditionalChargesSubtotal] =
    useState("");
  const [additionalChargesValue, setAdditionalChargesValue] = useState("");
  const [exchangeBikeSubtotal, setExchangeBikeSubtotal] = useState("");
  const [exchangeBikeValue, setExchangeBikeValue] = useState("");
  const [accessoriesAmount, setAccessoriesAmount] = useState("");
  const [discountedAccessoriesAmount, setDiscountedAccessoriesAmount] =
    useState("");
  const [apparelsAmount, setApparelsAmount] = useState("");
  const [discountedApparelsAmount, setDiscountedApparelsAmount] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [totalDiscountUnformatted, setTotalDiscountUnformatted] = useState(0);

  const updateConfigurationSubTotal = useCallback((quotes) => {
    if (quotes?.formatted_configuration_subtotal) {
      setConfigurationSubTotal(quotes.formatted_configuration_subtotal);
    } else {
      setConfigurationSubTotal("");
    }
    if (quotes?.formatted_configuration_discounted_subtotal) {
      setDiscountConfigurationSubTotal(
        quotes.formatted_configuration_discounted_subtotal
      );
    } else {
      setDiscountConfigurationSubTotal("");
    }
  }, []);

  const updateBasePrice = useCallback((quotes) => {
    if (quotes?.base_price?.formatted_value) {
      setBasePrice(quotes.base_price.formatted_value);
    } else {
      setBasePrice("");
    }
    if (
      quotes?.base_price?.formatted_discounted_value &&
      quotes?.base_price?.discount_value !== null
    ) {
      setDiscountedBasePrice(quotes.base_price.formatted_discounted_value);
    } else {
      setDiscountedBasePrice("");
    }
  }, []);

  const updateTotalValue = useCallback((quotes) => {
    if (quotes?.total?.formatted_value) {
      setTotalValue(quotes.total.formatted_value);
    } else {
      setTotalValue("");
    }
    if (quotes?.total?.formatted_discounted_value) {
      setTotalDiscountedValue(quotes.total.formatted_discounted_value);
    } else {
      setTotalDiscountedValue("");
    }

    if (quotes?.total?.discounted_value) {
      setTotalUnformattedDiscountedValue(quotes.total.discounted_value);
    } else {
      setTotalUnformattedDiscountedValue("");
    }
    if (quotes?.total_discount) {
      setTotalDiscountUnformatted(quotes.total_discount);
    } else {
      setTotalDiscountUnformatted("");
    }
  }, []);

  const updateAdditionalCharges = useCallback((quotes) => {
    if (quotes?.formatted_additional_charges_subtotal) {
      setAdditionalChargesSubtotal(
        quotes.formatted_additional_charges_subtotal
      );
    } else {
      setAdditionalChargesSubtotal("");
    }
    if (quotes?.additional_charges_section?.value > 0) {
      setAdditionalChargesValue(
        quotes.additional_charges_section.formatted_value
      );
    } else {
      setAdditionalChargesValue("");
    }
  }, []);

  const updateExchangeBike = useCallback((quotes) => {
    if (quotes?.formatted_exchange_bike_subtotal) {
      setExchangeBikeSubtotal(quotes.formatted_exchange_bike_subtotal);
    } else {
      setExchangeBikeSubtotal("");
    }
    if (quotes?.exchange_bike_section?.value > 0) {
      setExchangeBikeValue(quotes.exchange_bike_section.formatted_value);
    } else {
      setExchangeBikeValue("");
    }
  }, []);

  const updateAccessoriesAmount = useCallback((quotes) => {
    if (quotes?.accessories_section?.formatted_value) {
      setAccessoriesAmount(quotes.accessories_section.formatted_value);
    } else {
      setAccessoriesAmount("");
    }
    if (
      quotes?.accessories_section?.formatted_discounted_value &&
      quotes?.accessories_section?.discount_value !== null
    ) {
      setDiscountedAccessoriesAmount(
        quotes.accessories_section.formatted_discounted_value
      );
    } else {
      setDiscountedAccessoriesAmount("");
    }
  }, []);

  const updateApparelsAmount = useCallback((quotes) => {
    if (quotes?.apparels_section?.formatted_value) {
      setApparelsAmount(quotes.apparels_section.formatted_value);
    } else {
      setApparelsAmount("");
    }
    if (
      quotes?.apparels_section?.formatted_discounted_value &&
      quotes?.apparels_section?.discount_value !== null
    ) {
      setDiscountedApparelsAmount(
        quotes.apparels_section.formatted_discounted_value
      );
    } else {
      setDiscountedApparelsAmount("");
    }
  }, []);

  const updateTotalDiscount = useCallback((quotes) => {
    if (quotes?.total_discount > 0) {
      setTotalDiscount(quotes.formatted_total_discount);
    } else {
      setTotalDiscount("");
    }
  }, []);

  useEffect(() => {
    if (
      initSessionContent?.configuration?.preconfiguration?.model_data?.bike_sku
    ) {
      setBikeSku(
        initSessionContent?.configuration?.preconfiguration?.model_data
          ?.bike_sku
      );
    }

    if (quotationData) {
      const { data } = quotationData;
      const { quotes } = data;
      updateConfigurationSubTotal(quotes);
      updateBasePrice(quotes);
      updateTotalValue(quotes);
      updateAdditionalCharges(quotes);
      updateExchangeBike(quotes);
      updateAccessoriesAmount(quotes);
      updateApparelsAmount(quotes);
      updateTotalDiscount(quotes);
    }
  }, [
    quotationData,
    initSessionContent?.configuration?.preconfiguration?.model_data?.bike_sku,
    updateConfigurationSubTotal,
    updateBasePrice,
    updateTotalValue,
    updateAdditionalCharges,
    updateExchangeBike,
    updateAccessoriesAmount,
    updateApparelsAmount,
    updateTotalDiscount,
  ]);

  function retrieveNotesFromInit() {
    if (initSessionContent?.configuration?.dealer_prices.length > 0) {
      return initSessionContent.configuration.dealer_prices[0].notes;
    }
    return "";
  }

  return {
    bikeSku,
    preconfigurationName:
      initSessionContent?.configuration?.preconfiguration?.preconfiguration ||
      "",
    configurationSubTotal,
    discountConfigurationSubTotal,
    basePrice,
    discountedBasePrice,
    totalValue,
    totalDiscountedValue,
    totalUnformattedDiscountedValue,
    additionalChargesSubtotal,
    additionalChargesValue,
    exchangeBikeSubtotal,
    exchangeBikeValue,
    accessoriesAmount,
    discountedAccessoriesAmount,
    apparelsAmount,
    discountedApparelsAmount,
    totalDiscount,
    retrieveNotesFromInit,
    totalDiscountUnformatted,
  };
}
