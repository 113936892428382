import React, { useEffect, useState } from "react";
import style from "./SelectorBar.module.scss";
import { useScrambler } from "../../../hooks/useScrambler";

const PROGRESSBAR_SLIDER_DELAY = 1000;

function ProgressBarSlider({ customStyle, progress, isDelay = false }) {
  const [show, setShow] = useState("");
  const { scramblerStyle } = useScrambler();

  useEffect(() => {
    if (isDelay) {
      setShow("d-none");
      const timer = setTimeout(() => {
        setShow("d-block");
      }, PROGRESSBAR_SLIDER_DELAY);

      return () => clearTimeout(timer);
    }
  }, [isDelay]);

  const progressBarStyle = {
    width: progress !== undefined ? `${progress}%` : "unset",
  };

  return (
    <div className={`${show} my-2 ${style.loading_bar} ${customStyle}`}>
      <div
        className={`${
          scramblerStyle
            ? style["loading_bar_progress--scrambler"]
            : style.loading_bar_progress
        }`}
        style={progressBarStyle}
      />
    </div>
  );
}

export default ProgressBarSlider;
