import React, { createContext, useState } from "react";

export const ImageSelectedZoomContext = createContext();

export function ImageSelectedZoomProvider({ children }) {
  const [imageSelectedZoom, setImageSelectedZoom] = useState({ position: 0 });

  return (
    <ImageSelectedZoomContext.Provider
      value={{ imageSelectedZoom, setImageSelectedZoom }}
    >
      {children}
    </ImageSelectedZoomContext.Provider>
  );
}
