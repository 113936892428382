import React, { useState, createContext, useContext } from "react";

export const NavbarStickyContext = createContext({
  navbarstickyContent: {},
});

export function NavbarStickyProvider({ children }) {
  const [navbarStickyContent, setNavbarStickyContent] = useState({
    isSticky: false,
  });

  const provider = {
    navbarStickyContent,
    setNavbarStickyContent: (selected) => {
      const newNavbarContent = selected;
      setNavbarStickyContent(newNavbarContent);
    },
  };

  return (
    <NavbarStickyContext.Provider value={provider}>
      {children}
    </NavbarStickyContext.Provider>
  );
}

export function useNavbarStickyContent() {
  return useContext(NavbarStickyContext);
}
