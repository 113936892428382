import { eventName } from "../DataLayerContext";
import {
  checkArgs,
  isLogged,
  resolveFamilyName,
} from "../../../utils/DataLayer";

function resolveVirtualPageUrl(externalUrl, virtualPageSuffix) {
  let url = "";
  if (externalUrl !== "") {
    url = externalUrl;
  } else {
    url = `${window.location.pathname}${
      virtualPageSuffix !== "" ? `/${virtualPageSuffix}` : ""
    }`;
  }
  return url;
}

export function builPageviewEvent({
  language = "",
  country = "",
  pageTags = "",
  pageType = "",
  pageTitle = "",
  familyName = "",
  modelName = "",
  superModel = "",
  equipmentName = "",
  virtualPageSuffix = "",
  externalUrl = "",
}) {
  let pageviewEvent = {};
  if (checkArgs([language, country, pageTags, pageType, pageTitle])) {
    const login = isLogged();
    const virtualPageUrl = resolveVirtualPageUrl(
      externalUrl,
      virtualPageSuffix
    );
    const realFamilyName = resolveFamilyName(familyName);
    pageviewEvent = {
      event: eventName.pageview,
      pageTags,
      login,
      pageLanguage: language?.toLowerCase(),
      pageCountry: country?.toLowerCase(),
      pageType,
      pageTitle,
      virtualPageUrl,
      pageMotoFamily: realFamilyName,
      pageMotoModel: modelName,
      pageMotoSuperModel: superModel,
      pageMotoEquipment: equipmentName,
    };
  }
  return pageviewEvent;
}
