import React, { useCallback, useState } from "react";
import { cn } from "../../../common/utils/cn";
import style from "./DiscountSection.module.scss";
import { useQuotationData } from "../../context/QuotationContext";
import IconEdit from "../../../common/assets/images/discount/Edit.svg";
import IconRemove from "../../../common/assets/images/discount/Remove.svg";
import {
  Search,
  Text,
  useLanguageContent,
} from "../../../common/context/LanguageContext";
import { isDesktopView } from "../../../common/utils/Sizing";
import { useCart } from "../../../common/context/CarrelloContext";
import { EUR_SYMBOL_UNICODE } from "../../../common/utils/Currency";

import { sectionMap } from "./DiscountSection";
import { fetchQuotation } from "../../../common/services/DucatiService";
import { dealerDataFromJWT } from "../../../common/utils/Auth";
import { useAuth } from "react-oauth2-pkce";
import { useInit } from "../../../common/context/InitContext";
import { isEmptyObject } from "../../../common/utils/Utils";
import { useProgressContent } from "../../../common/context/ProgressContext";
import Write from "./Write";
import { useError } from "../../../common/context/ErrorContext";
import {
  messageType,
  useServiceModal,
} from "../../../common/components/modal/service-modal/ServiceModal";

export const dsStatusType = {
  insert: "insert",
  write: "write",
  read: "read",
};

export function useDiscountForm() {
  const { language } = useLanguageContent();
  const { carrelloContent } = useCart();
  const { initSessionContent } = useInit();
  const { authService } = useAuth();
  const { quotationData, setQuotationData, createBodyQuotation } =
    useQuotationData();
  const { setProgressContent } = useProgressContent();
  const { echoError } = useError();
  const { showServiceModal } = useServiceModal();

  function confirmDiscount(discountSection, updateState, setIsLoading) {
    const rqBody = createBodyQuotation({ discountSection });
    if (!isEmptyObject(rqBody)) {
      fetchQuotation(
        language.country.toLowerCase(),
        language.language.toLowerCase(),
        initSessionContent.session.cid,
        rqBody,
        dealerDataFromJWT(authService)
      )
        .then((dataQuotation) => {
          const newDataQuotation = { ...quotationData };
          newDataQuotation.data = dataQuotation;
          newDataQuotation.body = rqBody;
          setQuotationData(newDataQuotation);

          setProgressContent({ isLoading: false });
          updateState(dsStatusType.read);
          setIsLoading(false);
        })
        .catch((error) => {
          setProgressContent({ isLoading: false });
          setIsLoading(false);
          echoError({
            error,
            caller: "DiscountForm - confirmDiscount - fetchQuotation",
          });
          showServiceModal(messageType.changingFail);
        });
    } else {
      setProgressContent({ isLoading: false });
      setIsLoading(false);
    }
  }

  function removeDiscount(discountSection, updateState) {
    const rqBody = createBodyQuotation({ discountSection });
    if (!isEmptyObject(rqBody)) {
      fetchQuotation(
        language.country.toLowerCase(),
        language.language.toLowerCase(),
        initSessionContent.session.cid,
        rqBody,
        dealerDataFromJWT(authService)
      )
        .then((dataQuotation) => {
          const newDataQuotation = { ...quotationData };
          newDataQuotation.data = dataQuotation;
          newDataQuotation.body = rqBody;
          setQuotationData(newDataQuotation);

          updateState(dsStatusType.insert);
        })
        .catch((error) => {
          echoError(error, "DiscountForm - removeDiscount - fetchQuotation");
        });
    }
  }

  const initStatus = useCallback(
    (type) => {
      let result = dsStatusType.insert;
      if (quotationData?.data?.quotes) {
        const section = sectionMap[type];
        const discount = quotationData?.data?.quotes[section.responseLabel];
        result = discount?.discount_value
          ? dsStatusType.read
          : dsStatusType.insert;
      }
      return result;
    },
    [quotationData?.data?.quotes]
  );

  const readDiscountValue = useCallback(
    (type) => {
      let result = "";
      if (quotationData?.data?.quotes) {
        const section = sectionMap[type];
        const discount = quotationData?.data?.quotes[section.responseLabel];
        result = discount?.discount_value || "";
      }
      return result;
    },
    [quotationData?.data?.quotes]
  );

  const readDiscountType = useCallback(
    (type) => {
      let result = "PERCENTAGE";
      if (quotationData?.data?.quotes) {
        const section = sectionMap[type];
        const discount = quotationData?.data?.quotes[section.responseLabel];
        result = discount?.discount_type || "PERCENTAGE";
      }
      return result;
    },
    [quotationData?.data?.quotes]
  );

  const readDiscount = useCallback(
    (type) => {
      let result = "";
      if (quotationData?.data?.quotes) {
        const section = sectionMap[type];
        const discount = quotationData?.data?.quotes[section.responseLabel];
        const symbol =
          discount?.discount_type === "PERCENTAGE"
            ? "%"
            : carrelloContent?.dettagli?.currency?.symbol;
        result = `-${discount?.discount_value}${symbol}`;
      }
      return result;
    },
    [quotationData?.data?.quotes, carrelloContent?.dettagli?.currency?.symbol]
  );

  return {
    language,
    currencySymbol:
      carrelloContent?.dettagli?.currency?.symbol || EUR_SYMBOL_UNICODE,
    confirmDiscount,
    readDiscount,
    initStatus,
    readDiscountValue,
    readDiscountType,
    removeDiscount,
    setProgressContent,
  };
}

function Insert({ updateState }) {
  const { setProgressContent } = useDiscountForm();
  return (
    <button
      type="button"
      className={cn("btn mx-0 border-0 px-0 py-3", style["ds-form-btn-link"])}
      onClick={() => {
        updateState(dsStatusType.write);
        setProgressContent({ isLoading: true });
      }}
    >
      <Search
        did="b2b_recap_information"
        tid="voices_content.discount.btn_label.add"
      />
    </button>
  );
}

function Read({ type = "", updateState }) {
  const { readDiscount, removeDiscount, setProgressContent } =
    useDiscountForm();
  return (
    <div
      data-component="read"
      className={`row ${
        isDesktopView() ? "" : "w-100"
      } d-flex justify-content-center align-items-center py-2`}
    >
      <div className="col mx-0 px-0 text-end">
        <p
          className={`w-auto m-0 ${isDesktopView() ? "pe-4" : "text-center"} ${
            style["read-discount-value"]
          }`}
        >
          {readDiscount(type)}
        </p>
      </div>
      <div className="col mx-0 px-0 d-flex">
        <button
          type="button"
          className={cn(
            `btn w-auto mx-0 border-0 ${isDesktopView() ? "px-3" : "p-0"}`,
            style["ds-form-btn-link"]
          )}
          onClick={() => {
            updateState(dsStatusType.write);
            setProgressContent({ isLoading: true });
          }}
        >
          {isDesktopView() ? (
            <Text tid="recap.btn_edit" />
          ) : (
            <img src={IconEdit} alt="edit" className="m-0 p-0" />
          )}
        </button>
        <button
          type="button"
          className={cn(
            `btn w-auto mx-0 border-0 ${isDesktopView() ? "px-0" : "p-0"}`,
            style["ds-form-btn-link"]
          )}
          onClick={() => {
            removeDiscount({ type, dsValue: null, dsType: null }, updateState);
          }}
        >
          {isDesktopView() ? (
            <Search
              did="b2b_recap_information"
              tid="voices_content.form_card.btn_label.remove"
            />
          ) : (
            <img src={IconRemove} alt="remove" className="m-0 p-0" />
          )}
        </button>
      </div>
    </div>
  );
}

export function DiscountForm({ title = "", type = "" }) {
  const { initStatus } = useDiscountForm();
  const [dsStatus, setDsStatus] = useState(initStatus(type));

  const buildContent = useCallback(
    (type) => {
      if (dsStatus === dsStatusType.write) {
        return <Write title={title} type={type} updateState={setDsStatus} />;
      }
      if (dsStatus === dsStatusType.read) {
        return <Read type={type} updateState={setDsStatus} />;
      }
      return <Insert updateState={setDsStatus} />;
    },
    [dsStatus]
  );

  return (
    <div
      data-component="ds-form-container"
      className={cn(
        "container-fluid d-flex justify-content-center mx-0 px-0",
        style["ds-form-container"],
        `${
          isDesktopView() && dsStatus === dsStatusType.write
            ? style["ds-form-container-edit"]
            : ""
        }`
      )}
    >
      {buildContent(type)}
    </div>
  );
}
