import React, { useState, createContext, useContext } from "react";

export const ProgressContext = createContext({
  progressContent: { isLoading: false },
});

export function ProgressProvider({ children }) {
  const [progressContent, setProgressContent] = useState();

  const provider = {
    progressContent,
    setProgressContent: (selected) => {
      const newProgressContent = selected;
      setProgressContent(newProgressContent);
    },
  };

  return (
    <ProgressContext.Provider value={provider}>
      {children}
    </ProgressContext.Provider>
  );
}

export function useProgressContent() {
  return useContext(ProgressContext);
}
