// HeaderB2bContext.js
import React, { createContext, useState } from "react";

export const HeaderB2bContext = createContext();

export function HeaderB2bProvider({ children }) {
  const [headerb2bContent, setHeaderb2bContent] = useState({ isOpen: false });

  return (
    <HeaderB2bContext.Provider
      value={{ headerb2bContent, setHeaderb2bContent }}
    >
      {children}
    </HeaderB2bContext.Provider>
  );
}
