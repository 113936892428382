import { endpointResolver, endpointResolverAuth } from "./EndpointResolver";
import { AuthService } from "react-oauth2-pkce";
import jwtDecode from "jwt-decode";
import { isEmptyObject, sessionStorageKey } from "./Utils";
import React from "react";

export function buildAuthService(country, language) {
  let client = null;
  const localCountry = country || "it";
  const localLanguage = language || "it";

  
  const redirectB2B = endpointResolver(
    process.env.REACT_APP_B2B_REDIRECT_URI,
    localCountry.toLowerCase(),
    localLanguage.toLowerCase()
  );

  client = new AuthService({
    clientId: process.env.REACT_APP_B2B_CLIENT_ID,
    clientSecret: process.env.REACT_APP_B2B_SECRET,
    authorizeEndpoint: process.env.REACT_APP_B2B_AUTHORIZATION_URI,
    tokenEndpoint: process.env.REACT_APP_B2B_ACCESS_TOKEN_URI,
    redirectUri: redirectB2B,
    scopes: ["openid"],
    logoutEndpoint: process.env.REACT_APP_B2B_LOGOUT,
  });
  

  

  return client;
}

export function retrieveJWTDecoded(authService) {
  let jwtPayload = {};
  if (authService !== null && !isEmptyObject(authService)) {
    const token = authService.getAuthTokens();
    if (token !== null && !isEmptyObject(token)) {
      jwtPayload = jwtDecode(token.id_token);
    }
  }
  return jwtPayload;
}

export function userDataFromJWT(authService) {
  let userData = {};
  const jwt = retrieveJWTDecoded(authService);
  if (!isEmptyObject(jwt)) {
    userData = {
      picture: jwt?.picture || "",
      name: jwt?.name || "",
      email: jwt?.email || "",
      accountId: jwt?.custom_attributes?.personAccountId || "",
    };
  }
  return userData;
}

export function dealerDataFromJWT(authService) {
  let dealerData = {};
  if (authService !== null && !isEmptyObject(authService)) {
    const token = authService.getAuthTokens();
    if (token !== null && !isEmptyObject(token)) {
      dealerData = {
        id_token: token.id_token,
      };
    }
  }
  return dealerData;
}

export function hasToken() {
  const auth = localStorage.getItem(sessionStorageKey.oauth2pkceAuthKey);
  if (auth) {
    const authService = JSON.parse(auth);
    if (authService) {
      return !!authService?.id_token;
    }
  }
  return false;
}

export function getUserId(authService) {
  let userId = "";
  const jwt = retrieveJWTDecoded(authService);
  if (!isEmptyObject(jwt) && jwt?.custom_attributes?.personAccountId) {
    userId = jwt.custom_attributes.personAccountId;
  } else if (!isEmptyObject(jwt) && jwt?.sub) {
    userId = jwt.sub;
  }
  return userId;
}
