import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoDucati from "../../assets/images/logo.svg";
import styles from "./ServicesPage.module.scss";
import { useLanguageContent } from "../../context/LanguageContext";
import {
  INTERNAL_SERVER_ERROR_CODE,
  NOT_FOUND_CODE,
  useError,
} from "../../context/ErrorContext";

function useServicesPage() {
  const { language } = useLanguageContent();
  const { errorState } = useError();
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    code: "",
    title: "",
    description: "",
    btnLabel: "",
  });
  const [hideBtn, setHideBtn] = useState(false);

  useEffect(() => {
    if (errorState?.hasError) {
      let stateError;
      switch (errorState.code) {
        case NOT_FOUND_CODE:
          stateError = {
            code: errorState.code,
            title: language.error_information.not_found_title,
            description: language.error_information.not_found_description,
            btnLabel: language.error_information.not_found_btn_label,
          };
          break;
        case INTERNAL_SERVER_ERROR_CODE:
          stateError = {
            code: errorState.code,
            title: language.error_information.internal_server_title,
            description: language.error_information.internal_server_description,
            btnLabel: language.error_information.internal_server_btn_label,
          };
          break;
        default:
          stateError = {
            code: INTERNAL_SERVER_ERROR_CODE,
            title: language.error_information.internal_server_title,
            description: language.error_information.internal_server_description,
            btnLabel: language.error_information.internal_server_btn_label,
          };
          break;
      }
      setPageState(stateError);
      
      setHideBtn(errorState.code === NOT_FOUND_CODE);
      
    }
  }, [errorState, language]);

  function handleClick() {
    if (pageState.code === NOT_FOUND_CODE) {
      let tempCountry = process.env.REACT_APP_DAFUALT_COUNTRY;
      let tempLanguage = process.env.REACT_APP_DAFUALT_LANGUAGE;
      if (language?.country && language?.language) {
        tempCountry = language.country.toLowerCase();
        tempLanguage = language.language.toLowerCase();
      }
      const path = "/bikes/" + tempCountry + "/" + tempLanguage;
      navigate(path);
    }
    window.location.reload();
  }

  return {
    title: pageState.title,
    description: pageState.description,
    btnLabel: pageState.btnLabel,
    hideBtn,
    handleClick,
  };
}

function ServicesPage({
  customTitle = "",
  customDescription = "",
  customBtnLabel = "",
}) {
  const { title, description, btnLabel, hideBtn, handleClick } =
    useServicesPage();

  return (
    <div
      data-component="service-page-container"
      className={`text-center bg-white ${styles.service_page_container}`}
    >
      <div className={"row align-items-center pb-5 ".concat(styles["row-div"])}>
        <div className="col pb-5">
          <img
            src={logoDucati}
            alt="Ducati"
            className={"img-fluid mb-5 ".concat(styles["img-style"])}
          />
          <h2 className={"mt-5 ".concat(styles.title)}>
            {customTitle || title}
          </h2>
          <p className={"mt-4 ".concat(styles["page-description"])}>
            {customDescription || description}
          </p>
          {hideBtn ? (
            ""
          ) : (
            <button
              type="button"
              className={"mt-4 btn btn-outline-secondary ".concat(
                styles["btn-style"]
              )}
              onClick={() => {
                handleClick();
              }}
            >
              {customBtnLabel || btnLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServicesPage;
